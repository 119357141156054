import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  goodsInPNBEList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
};

export const GoodsInPNBESlice = createSlice({
  name: "goodsInPNBESlice",
  initialState: initialState,
  reducers: {
    getAllGoodsInPNBESlice: (state, action) => {
      state.goodsInPNBEList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    gooodsInPNBECSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    goodsInPNBECSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    goodsInPNBECSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
  getAllGoodsInPNBESlice,
  gooodsInPNBECSVUploadInitiate,
  goodsInPNBECSVUploadComplete,
  goodsInPNBECSVUploadError
} = GoodsInPNBESlice.actions;

export default GoodsInPNBESlice.reducer;
