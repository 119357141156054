import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  featureList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
  addEditTableColumnFeature:null
};

export const FeatureSlice = createSlice({
  name: "feature",
  initialState: initialState,
  reducers: {
    getAllFeatureSlice: (state, action) => {
      state.featureList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    getEditAllFeatureSlice: (state, action) => {
      state.addEditTableColumnFeature = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    postAddAllFeatureSlice: (state, action) => {
      state.addEditTableColumnFeature = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    featureCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    featureCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.csvUploadResponseData = action.payload;
      state.loader = false
      return state;
    },
    featureCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
  getAllFeatureSlice,
  featureCSVUploadInitiate,
  featureCSVUploadComplete,
  featureCSVUploadError,
  getEditAllFeatureSlice,
  postAddAllFeatureSlice
} = FeatureSlice.actions;

export default FeatureSlice.reducer;
