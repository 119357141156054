import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  locations: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
  addEditTableColumnLocation:null

};

export const LocationSlice = createSlice({
  name: "location",
  initialState: initialState,
  reducers: {
    getAllLocationSlice: (state, action) => {
      state.locations = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    getEditAllLocationSlice: (state, action) => {
      state.addEditTableColumnLocation = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    postAddAllLocationSlice: (state, action) => {
      state.addEditTableColumnLocation = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    locationCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    locationCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    locationCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
  getAllLocationSlice,
  locationCSVUploadError,
  locationCSVUploadComplete,
  locationCSVUploadInitiate,
  getEditAllLocationSlice,
  postAddAllLocationSlice
} = LocationSlice.actions;

export default LocationSlice.reducer;
