import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  rowmaterialList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
};

export const RowMaterialSlice = createSlice({
  name: "RowMaterialSlice",
  initialState: initialState,
  reducers: {
    getAllRowMaterialSlice: (state, action) => {
      state.rowmaterialList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    rowMaterialCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    RowMaterialCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    RowMaterialCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
  getAllRowMaterialSlice,
  rowMaterialCSVUploadInitiate,
  RowMaterialCSVUploadComplete,
  RowMaterialCSVUploadError
} = RowMaterialSlice.actions;

export default RowMaterialSlice.reducer;
