import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  syncstatus: []
};

export const SyncstatusSlice = createSlice({
  name: "syncstatus",
  initialState: initialState,
  reducers: {
    getAllSyncstatusSlice: (state, action) => {
      state.syncstatus = action.payload;
      state.loader = false;
      return state;
    }
  },
});

export const {
    getAllSyncstatusSlice
} = SyncstatusSlice.actions;

export default SyncstatusSlice.reducer;
