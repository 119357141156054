import React from "react";
import SyncStatusTable from "./SyncStatusTable";


const Sync_Status = () => {
  return (
    <>
      <SyncStatusTable /> 
    </>
  );
};
export default Sync_Status;