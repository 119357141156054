import { put, takeEvery } from "redux-saga/effects";
import { GET_ACTUAL_SALES_HISTORY, UPLOAD_ACTUAL_SALES_HISTORY_CSV_DATA } from "../../../types";
import { getAllActualSalesHistoryAPI, uploadActualSalesHistoryCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";
import {
  getAllSalesHistorySlice,
  salesHistoryCSVUploadComplete,
  salesHistoryCSVUploadError
} from "../../../slices/transactional/actualSalesHistory";

export function* getAllSalesHistorySaga(action) {
  try {
    yield put(getAllSalesHistorySlice({
      data: {},
      loader: true
    }))
    const res = yield getAllActualSalesHistoryAPI(action.payload);
    yield put(getAllSalesHistorySlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllSalesHistorySlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadActualSalesHistoryCSVDataAPI(action.payload);
    yield put(salesHistoryCSVUploadComplete(res?.data.responseData));
  } catch (error) {
    yield put(salesHistoryCSVUploadError(error.response?.data?.responseData));
  }
}
export function* watchSalesHistorySaga() {
  yield takeEvery(GET_ACTUAL_SALES_HISTORY, getAllSalesHistorySaga);
  yield takeEvery(UPLOAD_ACTUAL_SALES_HISTORY_CSV_DATA, uploadCSVSaga);
}
