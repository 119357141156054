import React, { useState, useEffect } from "react";
import "../../../../style/sass/main.scss";
import TableGrid from "../../../../components/tableGrid";
import TableToolbar from "../../../../components/tableGrid/toolbar";
import "@progress/kendo-theme-default/dist/all.css";
import { get } from "lodash";
import {filesize} from 'filesize'

import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_BUDGET,
  UPLOAD_BUDGET_CSV_DATA,
} from "../../../../store/types";

import { useTranslation } from "react-i18next";
import { SHOW_ROWS } from "../../../../constant/constant";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import Breadcrumb from "../../../../components/breadcrumb";
import UploadDialog from "../../../../components/uploadDialog";
import { downloadCsvApi } from "../../../../apis";
import { csvFileReader } from "../../../../utils/csvFileReader";
import { entitiesName, permission } from "../../../../utils/Constants";
import { useNumericFormat } from "react-number-format";
import moment from "moment";
import ProcessingLoader from "../../../../components/processingLoader/";
import { use } from "i18next";

const List = (props) => {
  const { t } = useTranslation();
  const EDM_API_ENDPOINT = process.env.REACT_APP_EDM_API_ENDPOINT;
  const SCAI_API_BASEURL = process.env.REACT_APP_SCAI_API_BASEURL_BUDGET;
  const localeConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).locale
    : {};
  const locationConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .locationHierarchy
    : {};
  const productConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .productHierarchy
    : {};
  const user = JSON.parse(localStorage.getItem("user"));
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const permissions = get(user, "permissions", []);
  const addEditPermission = permissions.includes(
    permission.salesHistoryAddEdit
  );

  const dispatch = useDispatch();
  const [rowLength, setRowLength] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [errorCSV, setErrorCSV] = useState("");
  const [allCheckboxId, setAllCheckboxId] = useState([]);
  const [childData, setChildData] = useState([]);
  const [hideShowList, setHideShowList] = useState([]);
  const [searchColumns, setSearchColumns] = useState("");
  const [updateflag, setUpdateflag] = useState(false);
  const [newallCheckboxId,setNewallCheckboxId] = useState([]);
  const notRequiredColumns = JSON.parse(sessionStorage.getItem("budget"));
  let strNRC;
  if (notRequiredColumns != null) {
    strNRC = notRequiredColumns.join(",");
  }

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };
  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      const error = await csvFileReader(file);
      if (!error) {
        setMyFiles([file]);
        setErrorCSV("");
      } else {
        setErrorCSV(error);
        setMyFiles([]);
      }
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const files = myFiles.map((file) => (
    <li key={file.path} className="csv-upload-success-li">
      {file.path} - {file.size} bytes
    </li>
  ));
  useEffect(() => {
    if (visible === false) {
      setMyFiles([]);
      setErrorCSV("");
    }
  }, [visible]);

  const showMoreOptions = SHOW_ROWS.map((item) => ({
    label: `${t("Show")} ${item} ${t("Rows")}`,
    value: item,
  }));

  

  const body = {
    pageIndex: pageIndex,
    pageSize: rowLength,
    sortFieldStringWithASCOrDESC: sortValue,
    searchValue: searchField,
    tenantId: tenantId,
  };

  useEffect(() => {
    if (!refresh) {
      dispatch({ type: GET_ALL_BUDGET, payload: body });
    }
  }, [dispatch, pageIndex, sortValue, rowLength]);

  const handleSearchSubmit = () => {
    setPageIndex(1);
    dispatch({
      type: GET_ALL_BUDGET,
      payload: { ...body, pageIndex: 1 },
    });
  };
  const handleAdvanceSearch = (data) => {
    body.advanceSearchValue = data;
    body.buttonStatus = true;
    setPageIndex(1);
    dispatch({ type: GET_ALL_BUDGET, payload: { ...body, pageIndex: 1 } });
  };

  const clearAdvanceSearch = () => {
    body.buttonStatus = false;
    setPageIndex(1);
    dispatch({ type: GET_ALL_BUDGET, payload: { ...body, pageIndex: 1 } });
  };
  const refreshHandler = () => {
    setRefresh(true);
    setPageIndex(1);
    setSearchField("");
    setSortValue("");
    setRowLength(10);
    const refreshBody = {
      ...body,
      searchValue: "",
      pageIndex: 1,
      pageSize: 10,
      sortFieldStringWithASCOrDESC: "",
    };
    setTimeout(() => {
      dispatch({ type: GET_ALL_BUDGET, payload: refreshBody });
      setRefresh(false);
    }, 300);
  };

  const {
    budgetList,
    csvUploadResponseData,
    csvUploadError,
    csvUpload,
    loader,
  } = useSelector((state) => state.BudgetReducer);
  const budgetConfiguration = JSON.parse(localStorage.getItem("configuration"))
  ? JSON.parse(localStorage.getItem("configuration"))?.entities
      ?.budget
  : {};
  const locationCustomHeaderConfiguration = JSON.parse(localStorage.getItem("configuration"))
  ? JSON.parse(localStorage.getItem("configuration"))?.entities
      ?.locationCustomHeader
  : {};
  const productCustomHeaderConfiguration = JSON.parse(localStorage.getItem("configuration"))
  ? JSON.parse(localStorage.getItem("configuration"))?.entities
      ?.productCustomHeader
  : {};

  const locationLevelConfiguration = JSON.parse(localStorage.getItem("configuration"))
  ? JSON.parse(localStorage.getItem("configuration"))?.entities?.budget
      ?.locationLevel
  : {};
  const productLevelConfiguration = JSON.parse(localStorage.getItem("configuration"))
  ? JSON.parse(localStorage.getItem("configuration"))?.entities?.budget
      ?.productLevel
  : {};

  let BudgetEntities = get(budgetList, "entities", []);

console.log("productLevelConfiguration",productLevelConfiguration)
console.log("locationLevelConfiguration",locationLevelConfiguration)
console.log("locationLevelConfigurationtypeof ",typeof locationLevelConfiguration, typeof productLevelConfiguration)


  
// console.log("budgetcon",budgetConfiguration.locationCustomHeader)
  BudgetEntities =
  BudgetEntities &&
  BudgetEntities.map((item) => {
      const items = { ...item };
      // items.selected=false
      
      if(typeof locationLevelConfiguration==="number" &&  Number(locationLevelConfiguration)>0){

      }else if(typeof locationLevelConfiguration==="number" &&  Number(locationLevelConfiguration)===0){
        console.log("hiiamthere")
        items.node_code=item?.node?.node_code
        delete items[`node_hierarchy`]
      }
      if( typeof productLevelConfiguration=="number" &&  Number(productLevelConfiguration)>0){

      }else if(typeof productLevelConfiguration=="number" &&  Number(productLevelConfiguration)===0){
        items.sku_code=item?.sku?.sku_code
        delete items[`sku_hierarchy`]
      }
      
      items.node=item?.node?.node
      if(budgetConfiguration && budgetConfiguration?.isChannelRequired){
        items.channel_name=item?.channel?.channel_name


      }
        if(Object.keys(items).includes("node_custom_columns") && budgetConfiguration && budgetConfiguration?.locationCustomHeader.length){
        for (
          let index = 0;
          index < budgetConfiguration?.locationCustomHeader.length;
          index++
        ) {
         console.log("headerrrrrr",index)
          items[locationCustomHeaderConfiguration[budgetConfiguration?.locationCustomHeader[index]]] = item["node_custom_columns"][`header${index+1}`]
          // delete items[`header${index}`]
        }
        delete items[`node_custom_columns`]
      }

      if(Object.keys(items).includes("product_custom_columns") && budgetConfiguration && budgetConfiguration?.productCustomHeader.length){
        for (
          let index = 0;
          index < budgetConfiguration?.productCustomHeader.length;
          index++
        ) {
         console.log("headerrrrrr",index)
          items[productCustomHeaderConfiguration[budgetConfiguration?.productCustomHeader[index]]] = item["product_custom_columns"][`header${index+1}`]
          // delete items[`header${index}`]
        }
        delete items[`product_custom_columns`]
      }
      // if (
      //   locationCustomHeader &&
      //   Number(locationCustomHeader?.locationCustomHeaderCount) &&
      //   Number(locationCustomHeader?.locationCustomHeaderCount) > 0
      // ) {
      //   console.log("iaminsideif");
      //   for (
      //     let index = 1;
      //     index <= locationCustomHeader?.locationCustomHeaderCount;
      //     index++
      //   ) {
      //    console.log("headerrrrrr",index)
      //     items[locationCustomHeader[`header${index}`]] = item[`header${index}`]
      //     delete items[`header${index}`]
      //   }
      // }
    


      return items;
    });
  const totalElement = get(budgetList, "totalElements", "");
  const pageSize = get(budgetList, "pageSize", "");
  const { format } = useNumericFormat({
    decimalSeparator: localeConfiguration["decimalSeparator"],
    thousandsGroupStyle: localeConfiguration["thousandsGroupStyle"],
    thousandSeparator: localeConfiguration["thousandSeparator"],
    decimalScale: 2,
  });

  const BudgetDetails = BudgetEntities;

  const removeColumn = [
    "id",
 
    "channel",
    "sku",
  ];
  let dataColumn =
    BudgetDetails &&
    BudgetDetails.length > 0 &&
    Object.keys(BudgetDetails[0]).filter((item) => {
      return !removeColumn.includes(item);
    });

  let dataColumnDetails =
    BudgetDetails &&
    BudgetDetails.length > 0 &&
    Object.keys(BudgetDetails[0]).filter((item) => {
      return !removeColumn.includes(item);
    });

    let addRemoveTableDataColumn = Array.isArray(dataColumnDetails)
    ? dataColumnDetails.map(item =>      
          item.toUpperCase() == "SKU_CODE" &&
          productConfiguration
        ? productConfiguration["base"] +
          " " +
          t("labelCode")
        : item.toUpperCase() == "SKU_NAME" &&
          productConfiguration
        ? productConfiguration["base"] + " " + t("labelName")
        : item.toUpperCase() == "NODE_CODE" &&
          locationConfiguration
        ? locationConfiguration["base"] + " " + t("labelCode")
        : item.toUpperCase() == "NODE" &&
          locationConfiguration
        ? locationConfiguration["base"] 
        : item
    )
    : [];

  useEffect(() => {
    const storedCheckboxId = sessionStorage.getItem("budget");
    if (storedCheckboxId) {
      const parsedCheckboxId = JSON.parse(storedCheckboxId);
      setAllCheckboxId(parsedCheckboxId);
    }
  }, []);


  const handleCheckboxChange = (e) => {
    const checkboxId = e.target.id;
    const index = addRemoveTableDataColumn.indexOf(checkboxId);
    setAllCheckboxId(allCheckboxId => {
      if (!e.target.checked) {
        return [...allCheckboxId, dataColumnDetails[index]];
      }else{
        if(allCheckboxId.length==1){
          setAllCheckboxId([]);
          sessionStorage.setItem('budget', JSON.stringify([]));
        }
        return allCheckboxId.filter(ID => ID !== dataColumnDetails[index]);
      }
      
    });
  };

  const handleDefault = () => {
    setAllCheckboxId([]);
    sessionStorage.setItem('budget', JSON.stringify([]));
  };
  const unCheckDefault = () => {
    setAllCheckboxId(Object.values(dataColumnDetails));
    sessionStorage.setItem('budget', JSON.stringify(Object.values(dataColumnDetails)));
  }
  if(!updateflag){
    const localdata = JSON.parse(localStorage.getItem('budgetallcheckboxId'));
    // console.log("localdata",localdata);
   if(localdata!=null){
      dataColumn = Object.values(dataColumn).filter(
        (element) => !localdata.includes(element)
      );
    }
  }
  function handleHideandShow() {
    setNewallCheckboxId(allCheckboxId);
    localStorage.setItem('budgetallcheckboxId',JSON.stringify(allCheckboxId));
    if (dataColumn.length) {
      setUpdateflag(true);
      if (childData.length) {
        dataColumn = childData;
      }
      dataColumn = dataColumn.filter(
        (element) => !allCheckboxId.includes(element)
      );
      // console.log("handleHideandShow", dataColumn);
    }
  }

  if (entitiesName.includes("budget") && allCheckboxId.length) {
    sessionStorage.setItem("budget", JSON.stringify(allCheckboxId));
  }

  const handleHideShowList = (array) => {
    setHideShowList(array);
  }
  if(hideShowList.length){
    addRemoveTableDataColumn = hideShowList;
  }

  const handleChildData = (array) => {
    setChildData(array);
  };
  if (childData.length) {
    dataColumnDetails = childData;
  }
  const handleSearchItem = (data) => {
    const searchTerm = data.replace(/ /g, "_").toLowerCase();
    const matchFound = addRemoveTableDataColumn.some((item) =>
      item.includes(searchTerm)
    );
    if (matchFound) {
      setSearchColumns(data);
    }
  };

  if (searchColumns.length) {
    addRemoveTableDataColumn = addRemoveTableDataColumn.filter((item) =>
      item.includes(searchColumns.replace(/ /g, "_").toLowerCase())
    );
  }
  // if (dataColumn.length) {
  //   if (childData.length) {
  //     dataColumn = childData;
  //   }
  //   dataColumn = dataColumn.filter(
  //     (element) => !allCheckboxId.includes(element)
  //   );
  // }

  const customColumnHeaders = () => {
    if (BudgetDetails && BudgetDetails.length > 0) {
      return [
        {
          replaceWith: "sku_code",
          newLabel: productConfiguration
            ? productConfiguration["base"] + " " + t("labelCode")
            : t("sku_code"),
        },
        {
          replaceWith: "sku_name",
          newLabel: productConfiguration
            ? productConfiguration["base"]
            : t("sku_name"),
        },
        {
          replaceWith: "node_code",
          newLabel: locationConfiguration
            ? locationConfiguration["base"] + " " + t("labelCode")
            : t("node_code"),
        },
        {
          replaceWith: "node",
          newLabel: locationConfiguration
            ? locationConfiguration["base"]
            : t("sku_code"),
        },
      ];
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (csvUploadError) {
      setVisible(false);
      toast.error(
        t(
          `${
            csvUploadError?.responseMessage ??
            csvUploadError.message ??
            csvUploadError
          }`
        ),
        {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
        }
      );
    }
  }, [csvUploadError]);
  useEffect(() => {
    if (csvUpload) {
      setVisible(false);
      if (csvUploadResponseData.responseCode === "FAILED_HEADER_VALIDATION") {
        toast.error(`${csvUploadResponseData.responseMessage}`,
        {
          autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_ROW_FAILURE"
      ) {
        toast.warning(
          `${t(
            `${
              csvUploadResponseData.responseCode +
              "\n"
            }`
          )}`,

          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
        toast.warning(
          <a href={`${csvUploadResponseData.errorFileName}`} target="_blank">
            {" "}
            Download Error File{" "}
          </a>,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_OUT_ROW_FAILURE"
      ) {
        toast.success(
          `${
            t(`${csvUploadResponseData.responseCode}`) +
            " " +
            csvUploadResponseData.failedData.toString()
          }`,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else {
        toast.success(t(`${csvUploadResponseData.responseCode}`),{
          autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      }
      refreshHandler();
    }
  }, [csvUpload]);
  const toggleDialog = () => {
    dispatch({
      type: "budget/budgetCSVUploadInitiate",
      payload: { csvUpload: false, loader: false },
    });
    setVisible(!visible);
  };
  const handleCSVUpload = () => {
    const fileSizeInMB = myFiles[0].size / (1024 * 1024);
    if(fileSizeInMB>40){
      return toast.warning("File size should be less than or equal to 40 MB",
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      })
    }
  //  if(myFiles[0].type!="text/csv" && myFiles[0].type!="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
  //   return toast.warning("Only CSV and EXCEL is supported")

  //  }
  if(myFiles[0].type!="text/csv" ){
    setVisible(!visible)

      return toast.error("Only CSV file is supported",
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      })

   }
    const uploadBody = {
      csvFile: myFiles[0],
      tenantId: tenantId,
    };
    dispatch({
      type: UPLOAD_BUDGET_CSV_DATA,
      payload: uploadBody,
    });
    dispatch({
      type: "budget/budgetCSVUploadInitiate",
      payload: { csvUpload: false, loader: true },
    });
  };

  if (childData.length) {
    const localdata = JSON.parse(localStorage.getItem('budgetallcheckboxId'));
    if(localdata!=null){
      dataColumn = Object.values(childData).filter(
      (element)=> !localdata.includes(element)
      );
    } 
  }
  const storedFeatureHeaderString = localStorage.getItem('featureHeader');
  let storedFeatureHeader;
  if(storedFeatureHeaderString !== 'undefined'){
      storedFeatureHeader = JSON.parse(storedFeatureHeaderString);
  }
  return (
    <>
      {loader && <ProcessingLoader />}
      <div>
        <section className="client-listing">
          <h3 className="section-heading">{t("Budget")}</h3>

          <Breadcrumb
            list={[
              t("Entities Data"),
              t("Budget"),
            ]}
          />
          <div className="client-listing-content">
            <div className="client-listing-toolbar">
              <TableToolbar
                showMoreOptions={showMoreOptions}
                setRowLength={setRowLength}
                setPageIndex={setPageIndex}
                searchField={searchField}
                handleChange={handleChange}
                toggleDialog={toggleDialog}
                handleSearchSubmit={handleSearchSubmit}
                refreshHandler={refreshHandler}
                setSearchField={setSearchField}

                downloadUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/csv?tenant_id=${tenantId}&sortFieldStringWithASCOrDESC=${sortValue}&bu_id=${buId}&file_type=csv&searchValue=${searchField}&notRequiredColumns=${strNRC}`}
                dataList={BudgetDetails}
                downloadCsvApi={downloadCsvApi}
                downloadName={"budget.csv"}
                componentNameAddEdit={"Budget"}

                rowLength={rowLength}
                csvUploadPermission={addEditPermission}
                addRemoveTableDataColumn={addRemoveTableDataColumn ?? []}
                onCheckboxChange={handleCheckboxChange}
                sendDataToParent={handleChildData}
                allCheckboxId={allCheckboxId}
                setAllCheckboxId={setAllCheckboxId}
                handleSearchItem={handleSearchItem}
                ondefaultbutton={handleDefault}
                handleAdvanceSearch={handleAdvanceSearch}
                clearAdvanceSearch={clearAdvanceSearch}
                handleHideandShow={handleHideandShow}
                dataColumnDetails = {dataColumnDetails}
                dataForHideShowList = {handleHideShowList}
                unCheckDefault={unCheckDefault}
                storedFeatureHeader={storedFeatureHeader}
                dataRows={BudgetDetails}

              />
            </div>
            <div className="client-listing-grid">
              {" "}
              <TableGrid
                dataRows={BudgetDetails ?? []}
                dataColumn={
                  updateflag
                    ? (Array.isArray(dataColumn) ? dataColumn.filter((element) => !newallCheckboxId.includes(element)) : [])
                    : (Array.isArray(dataColumn) ? dataColumn : [])
                }
                refresh={refresh}
                pageSize={pageSize}
                totalElement={totalElement}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setSortValue={setSortValue}
                width={"auto"}
                isLoading={loader}
                replaceColumnLevels={customColumnHeaders()}
                componentName={"budget"}
              />
            </div>
          </div>
        </section>

        {visible && (
          <UploadDialog
            templateUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/template?tenant_id=${tenantId}&bu_id=${buId}`}
            toggleDialog={toggleDialog}
            handleCSVUpload={handleCSVUpload}
            myFiles={myFiles}
            listing={files}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            downloadCsvApi={downloadCsvApi}
            errorCSV={errorCSV}
            templateName={"budget-template.csv"}
            dateFormat={localeConfiguration["dateFormat"]}
          />
        )}
      </div>
    </>
  );
};

export default List;
