import React from "react";
import List from "./list";

const Product = () => {
  return (
    <>
      <List />
    </>
  );
};
// export default Product;
export default React.memo(Product)
