import React from "react";
import List from "./list";

const Currency = () => {
  return (
    <>
      <List />
    </>
  );
};
export default Currency;
