import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_BILL_OF_MATERIALS, UPLOAD_BILL_OF_MATERIALS_CSV_DATA } from "../../../types";
import { getAllBillOfMaterialsAPI, uploadBillOfMaterialsCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";

import { getAllBillOfMaterialsSlice,billOfMaterialsCSVUploadComplete,billOfMaterialsCSVUploadError } from "../../../slices/transactional/billOfMaterials";

export function* getAllBillOfMaterialsSaga(action) {

 
  try {
    yield put(getAllBillOfMaterialsSlice({
      data: {},
      loader: true
    }))
    const res = yield getAllBillOfMaterialsAPI(action.payload);
    yield put(getAllBillOfMaterialsSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllBillOfMaterialsSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadBillOfMaterialsCSVDataAPI(action.payload);
    yield put(billOfMaterialsCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        billOfMaterialsCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchBillOfMaterialsSaga() {
  yield takeEvery(GET_ALL_BILL_OF_MATERIALS, getAllBillOfMaterialsSaga);
  yield takeEvery(UPLOAD_BILL_OF_MATERIALS_CSV_DATA,uploadCSVSaga);
}
