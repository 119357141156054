//Product Types
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const UPLOAD_PRODUCT_CSV_DATA = "UPLOAD_PRODUCT_CSV_DATA";
export const UPLOAD_PRODUCT_CSV_DATA_INITIATE = "UPLOAD_PRODUCT_CSV_DATA_INITIATE";
export const UPLOAD_PRODUCT_CSV_DATA_COMPLETE = "UPLOAD_PRODUCT_CSV_DATA_COMPLETE";
export const GET_ALL_EDIT_PRODUCT = "GET_ALL_EDIT_PRODUCT";
export const POST_ALL_ADD_PRODUCT = "POST_ALL_ADD_PRODUCT";

//Pesonal Type
export const GET_ALL_PERSONNEL = "GET_ALL_PERSONNEL";
export const UPLOAD_PERSONNEL_CSV_DATA = "UPLOAD_PERSONNEL_CSV_DATA";
export const UPLOAD_PERSONNEL_CSV_DATA_INITIATE = "UPLOAD_PERSONNEL_CSV_DATA_INITIATE";
export const UPLOAD_PERSONNEL_CSV_DATA_COMPLETE = "UPLOAD_PERSONNEL_CSV_DATA_COMPLETE";

// Mapping types
export const GET_ALL_MAPPINGS = "GET_ALL_MAPPINGS"
export const UPLOAD_MAPPING_CSV_DATA = "UPLOAD_MAPPING_CSV_DATA"

// Split Master types
export const GET_ALL_SPLITMASTER     = "GET_ALL_SPLITMASTER "
export const UPLOAD_SPLITMASTER_CSV_DATA = "UPLOAD_SPLITMASTER_CSV_DATA"

// Network
export const GET_ALL_NETWORKS = "GET_ALL_NETWORKS"
export const UPLOAD_NETWORK_CSV_DATA = "UPLOAD_NETWORK_CSV_DATA"

// Budget
export const GET_ALL_BUDGET = "GET_ALL_BUDGET"
export const UPLOAD_BUDGET_CSV_DATA = "UPLOAD_BUDGET_CSV_DATA"
export const GET_ALL_EDIT_BUDGET = "GET_ALL_EDIT_BUDGET";
export const POST_ALL_ADD_BUDGET = "POST_ALL_ADD_BUDGET";
// Location types
export const GET_ALL_LOCATION = 'GET_ALL_LOCATION'
export const UPLOAD_LOCATION_CSV_DATA = "UPLOAD_LOCATION_CSV_DATA";
export const GET_ALL_EDIT_LOCATION = "GET_ALL_EDIT_LOCATION";
export const POST_ALL_ADD_LOCATION = "POST_ALL_ADD_LOCATION";
//feature
export const GET_ALL_FEATURES = "GET_ALL_FEATURES";
export const UPLOAD_FEATURES_CSV_DATA = "UPLOAD_FEATURES_CSV_DATA";
export const GET_ALL_EDIT_FEATURES = "GET_ALL_EDIT_FEATURES";
export const POST_ALL_ADD_FEATURES = "POST_ALL_ADD_FEATURES";



// Actaul Sales History
export const GET_ACTUAL_SALES_HISTORY = "GET_ACTUAL_SALES_HISTORY"
export const UPLOAD_ACTUAL_SALES_HISTORY_CSV_DATA = "UPLOAD_ACTUAL_SALES_HISTORY_CSV_DATA"

// Sales Order
export const GET_SALES_ORDERS = "GET_SALES_ORDERS"
export const UPLOAD_SALES_ORDERS_CSV_DATA = "UPLOAD_SALES_ORDERS_CSV_DATA"

// Transaction Data Fotrecast types
export const GET_ALL_FORECAST = 'GET_ALL_FORECAST'
export const UPLOAD_FORECAST_CSV_DATA = "UPLOAD_FORECAST_CSV_DATA";

// Goods In Transit

export const GET_ALL_GOODS_IN_TRANSIT="GET_ALL_GOODS_IN_TRANSIT"
export const UPLOAD_GOODS_IN_TRANSIT_CSV_DATA="UPLOAD_GOODS_IN_TRANSIT_CSV_DATA"

// Goods In Hand

export const GET_ALL_GOODS_IN_HAND="GET_ALL_GOODS_IN_HAND"
export const UPLOAD_GOODS_IN_HAND_CSV_DATA="UPLOAD_GOODS_IN_HAND_CSV_DATA"


// Goods In pnbs

export const GET_ALL_GOODS_IN_PNBS="GET_ALL_GOODS_IN_PNBS"
export const UPLOAD_GOODS_IN_PNBS_CSV_DATA="UPLOAD_GOODS_IN_PNBS_CSV_DATA"
// Production work order
export const GET_ALL_PRODUCTION_WORK_ORDER="GET_ALL_PRODUCTION_WORK_ORDER"
export const UPLOAD_PRODUCTION_WORK_ORDER_CSV_DATA="UPLOAD_PRODUCTION_WORK_ORDER_CSV_DATA"

// PO Events
export const GET_ALL_PO_EVENTS="GET_ALL_PO_EVENTS"
export const UPLOAD_PO_EVENTS_CSV_DATA="UPLOAD_PO_EVENTS_CSV_DATA"

// STO Events
export const GET_ALL_STO_EVENTS="GET_ALL_STO_EVENTS"
export const UPLOAD_STO_EVENTS_CSV_DATA="UPLOAD_STO_EVENTS_CSV_DATA"
//purchase order
export const GET_ALL_PURCHASE_ORDER = "GET_ALL_PURCHASE_ORDER"
export const UPLOAD_PURCHASE_ORDER_CSV_DATA = "UPLOAD_PURCHASE_ORDER_CSV_DATA"

// Row Material
export const GET_ALL_ROW_MATERIAL="GET_ALL_ROW_MATERIAL"
export const UPLOAD_ROW_MATERIAL_CSV_DATA="UPLOAD_ROW_MATERIAL_CSV_DATA"

// Bill Of Materials

export const GET_ALL_BILL_OF_MATERIALS="GET_ALL_BILL_OF_MATERIALS"
export const UPLOAD_BILL_OF_MATERIALS_CSV_DATA="UPLOAD_BILL_OF_MATERIALS_CSV_DATA"

// Stock Transfer Order
export const GET_ALL_STOCK_TRANSFER_ORDER="GET_ALL_STOCK_TRANSFER_ORDER"
export const UPLOAD_STOCK_TRANSFER_ORDER_CSV_DATA="UPLOAD_STOCK_TRANSFER_ORDER_CSV_DATA"

// MARKETING EVENTS
export const GET_ALL_MARKETING_EVENTS="GET_ALL_MARKETING_EVENTS"
export const UPLOAD_MARKETING_EVENTS_CSV_DATA="UPLOAD_MARKETING_EVENTS_CSV_DATA"

// PROMOTION TYPE
export const GET_ALL_PROMOTION_TYPE="GET_ALL_PROMOTION_TYPE"
export const UPLOAD_PROMOTION_TYPE_CSV_DATA="UPLOAD_PROMOTION_TYPE_CSV_DATA"

// RM Node Mapping
export const GET_ALL_RM_NODE_MAPPING="GET_ALL_RM_NODE_MAPPING"
export const UPLOAD_RM_NODE_MAPPING_CSV_DATA="UPLOAD_RM_NODE_MAPPING_CSV_DATA"

// Shipment
export const GET_ALL_SHIPMENT="GET_ALL_SHIPMENT"
export const UPLOAD_SHIPMENT_CSV_DATA="UPLOAD_SHIPMENT_CSV_DATA"

// Promo
export const GET_ALL_PROMO="GET_ALL_PROMO"
export const UPLOAD_PROMO_CSV_DATA="UPLOAD_PROMO_CSV_DATA"
export const GET_ALL_EDIT_PROMO_TRANSACTION = "GET_ALL_EDIT_PROMO_TRANSACTION";
export const POST_ALL_PROMO_TRANSACTION = "POST_ALL_PROMO_TRANSACTION";

// Promo Master
export const GET_ALL_PROMO_MASTER="GET_ALL_PROMO_MASTER"
export const UPLOAD_PROMO_MASTER_CSV_DATA="UPLOAD_PROMO_MASTER_CSV_DATA"
export const GET_ALL_EDIT_PROMO_MASTER = "GET_ALL_EDIT_PROMO_MASTER";
export const POST_ALL_PROMO_MASTER = "POST_ALL_PROMO_MASTER";


// Inventory Events
export const GET_ALL_INVENTORY_EVENTS="GET_ALL_INVENTORY_EVENTS"
export const UPLOAD_INVENTORY_EVENTS_CSV_DATA="UPLOAD_INVENTORY_EVENTS_CSV_DATA"

// Inventory Events
export const GET_ALL_RMPO="GET_ALL_RMPO"
export const UPLOAD_RMPO_CSV_DATA="UPLOAD_RMPO_CSV_DATA"


// Snop List
export const GET_ALL_SNOPS = 'GET_ALL_SNOPS';

//Currency Type
export const GET_ALL_CURRENCY = "GET_ALL_CURRENCY";
export const UPLOAD_CURRENCY_CSV_DATA = "UPLOAD_CURRENCY_CSV_DATA";

//syncstatus
export const GET_ALL_SYNCSTATUS = "GET_ALL_SYNCSTATUS";

// selected row data
export const SET_SELECTED_ROW_DATA = "SET_SELECTED_ROW_DATA";
