import React from "react";
import List from './list'

const Mapping = () => {
  return (
    <>
      <List />
    </>
  );
};
export default Mapping;
