import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  marketingEventsList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
};

export const marketingeventsSlice = createSlice({
  name: "marketingeventsSlice",
  initialState: initialState,
  reducers: {
    getAllmarketingeventsSlice: (state, action) => {
      state.marketingEventsList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    marketingeventsCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    marketingeventsCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    marketingeventsCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
  getAllmarketingeventsSlice,
  marketingeventsCSVUploadInitiate,
  marketingeventsCSVUploadComplete,
  marketingeventsCSVUploadError
} = marketingeventsSlice.actions;

export default marketingeventsSlice.reducer;
