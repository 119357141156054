import React, { useState, useEffect } from "react";
import "../../../style/sass/main.scss";
import SyncstatusGrid from "./SyncstatusGrid";
import SyncStatusbar from "../../../components/tableGrid/syncstatusbar";
import Select from "react-select";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { TextField, InputAdornment } from "@mui/material";
import "@progress/kendo-theme-default/dist/all.css";
import { isEmpty, get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { GET_ALL_SYNCSTATUS } from "../../../store/types";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { SHOW_ROWS } from "../../../constant/constant";
import { ROUTE_TYPE } from "../../../constant/constant";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { downloadCsvApi } from "../../../apis";
import { csvFileReader } from "../../../utils/csvFileReader";
import { Error } from "@progress/kendo-react-labels";
import { permission } from "../../../utils/Constants";
import ProcessingLoader from "../../../components/processingLoader/";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Breadcrumb from "../../../components/breadcrumb";
import TableToolbar from "../../../components/tableGrid/toolbar";

const SyncStatusTable = (props) => {
  const { t } = useTranslation();
  const EDM_API_ENDPOINT = process.env.REACT_APP_EDM_API_ENDPOINT;
  const SCAI_API_BASEURL_PRODUCT =
    process.env.REACT_APP_SCAI_API_BASEURL_PRODUCT;

  const user = JSON.parse(localStorage.getItem("user"));
  const localeConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).locale
    : {};
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const permissions = get(user, "permissions", []);
  const addEditPermission = permissions.includes(permission.productAddEdit);
  const dispatch = useDispatch();
  const [rowLength, setRowLength] = useState(10);
  const [routeType, setRouteType] = useState("All");
  const [pageIndex, setPageIndex] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [dateData, setDateData] = useState([]);

  const isLoading = useSelector((state) => state.ProductReducer.loader);

  const showMoreOptions = SHOW_ROWS.map((item) => ({
    label: `${t("Show")} ${item} ${t("Rows")}`,
    value: item,
  }));

  const routeTypes = ROUTE_TYPE.map((items) => ({
    label: `${t(items)}`,
    value: items,
  }));

  let startDate;
  let endDate;
  if (dateData && dateData.length) {   
     let startDateString = dateData[0].$d;
     let endDateString = dateData[1].$d;
     const sDate = new Date(startDateString);
     const eDate = new Date(endDateString);
     startDate = sDate.toISOString().slice(0, 10);
     endDate = eDate.toISOString().slice(0, 10);
     }

     
  const body = {
    pageIndex: pageIndex,
    pageSize: rowLength,
    Route_type: routeType,
    sortFieldStringWithASCOrDESC: sortValue,
    searchValue: searchField,
    productId: "0",
    tenantId: tenantId,
    Start_Date: startDate,
    End_Date: endDate,
  };

  useEffect(() => {
    if (!refresh) {
      dispatch({ type: GET_ALL_SYNCSTATUS, payload: body });
    }
  }, [
    dispatch,
    pageIndex,
    sortValue,
    rowLength,
    routeType,
    startDate,
    endDate,
  ]);

  const refreshHandler = () => {
    setRefresh(true);
    setPageIndex(1);
    setSearchField("");
    setSortValue("");
    setRowLength(10);
    setRouteType("All");

    const refreshBody = {
      ...body,
      searchValue: "",
      pageIndex: 1,
      pageSize: 10,
      sortFieldStringWithASCOrDESC: "",
      routeType: "All",
      startDate: "",
      endDate: "",
    };
    setTimeout(() => {
      dispatch({ type: GET_ALL_SYNCSTATUS, payload: refreshBody });
      setRefresh(false);
    }, 300);
  };

  const products = useSelector((state) => state.SyncstatusReducer.syncstatus);
  const productDetails = get(products, "entities", "");
  const totalElement = get(products, "totalElements", "");
  const pageSize = get(products, "pageSize", "");

  return (
    <>
      {isLoading && <ProcessingLoader />}
      <div>
        <section className="client-listing">
          <h3 className="section-heading">{t("Transaction Log")}</h3>
          <Breadcrumb list={[t("Transaction Log")]} />

          <div className="client-listing-content">
            <div className="client-listing-toolbar">
              <SyncStatusbar
                showMoreOptions={showMoreOptions}
                routeTypes={routeTypes}
                setRowLength={setRowLength}
                setPageIndex={setPageIndex}
                rowLength={rowLength}
                refreshHandler={refreshHandler}
                setRouteType={setRouteType}
                routeType={routeType}
                setDateData={setDateData}
              />
            </div>

            <div className="client-listing-grid">
              {" "}
              <SyncstatusGrid
                productData={productDetails}
                refresh={refresh}
                pageSize={pageSize}
                totalElement={totalElement}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setSortValue={setSortValue}
                isLoading={isLoading}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SyncStatusTable;
