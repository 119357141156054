import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  currencies: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
  csvUploadResponseCode: null,
};

export const CurrencySlice = createSlice({
  name: "currency",
  initialState: initialState,
  reducers: {
    getAllCurrenciesSlice: (state, action) => {
      state.currencies = action.payload;
      state.loader = false;
      return state;
    },
    currencyCSVUploadInitiate: (state) => {
      state.csvUpload = false;
      return state;
    },
    currencyCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.csvUploadResponseData = action.payload;
      return state;
    },
    currencyCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      return state;
    },
  },
});

export const {
  getAllCurrenciesSlice,
  currencyCSVUploadInitiate,
  currencyCSVUploadComplete,
  currencyCSVUploadError,
} = CurrencySlice.actions;

export default CurrencySlice.reducer;
