import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  errorMsg: "",
  ErrorCode: "",
  products: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
  csvUploadResponseCode: null,
  addEditTableColumnProduct:null
};

export const ProductSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {
    getAllProductsSlice: (state, action) => {
      state.products = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },

    getEditAllProductSlice: (state, action) => {
      console.log("productEdit",action)
      state.addEditTableColumnProduct = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    postAddAllProductSlice: (state, action) => {
      state.addEditTableColumnProduct = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    productCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    productDownloadTempleteError:(state)=>{
      state.csvUpload = false
      return state;
    },
    productCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    productCSVUploadError: (state, action) => {
      state.loader = false
      state.csvUploadError = action.payload;
      return state;
    },
  },
});

export const {
  getAllProductsSlice,
  productCSVUploadInitiate,
  productCSVUploadComplete,
  productCSVUploadError,
  postAddAllProductSlice,
  getEditAllProductSlice
} = ProductSlice.actions;

export default ProductSlice.reducer;
