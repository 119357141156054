import React from "react";
import { useTranslation } from "react-i18next";
const Breadcrumb = React.memo(({ list, active }) => {
  const { t } = useTranslation();
  return (
    <>
      {list.length > 0 ? (
        <div className="section-breadcrumbs">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              {list &&
                list.map((item, idx) => (
                  <li
                    key={idx}
                    className={`breadcrumb-item ${
                      list.length === idx + 1 ? "active" : ""
                    }`}
                    aria-current="page"
                  >
                    <span>{t(item)}</span>
                    
                  </li>
                ))}
            </ol>
          </nav>
        </div>
      ) : (
        ""
      )}
    </>
  );
});

export default Breadcrumb;
