import { put, takeEvery } from "redux-saga/effects";
import {
    getAllCurrencyAPI,
    uploadCSVDataCurrencyAPI,
} from "../../../../apis";
import {
    getAllCurrenciesSlice,
    currencyCSVUploadComplete,
    currencyCSVUploadError,
} from "../../../slices/entities/currency";
import { GET_ALL_CURRENCY, UPLOAD_CURRENCY_CSV_DATA } from "../../../types";
import { toast } from "react-toastify";

export function* getAllCurrencySaga(action) {
    try {
        const currencies = yield getAllCurrencyAPI(action.payload);
        yield put(getAllCurrenciesSlice(currencies.data.responseData));
    } catch (error) {
        toast.error(error.message,
            {
                autoClose: false,
                closeOnClick: false,
                draggable: false,
            });
    }
}

export function* uploadCurrencyCSVSaga(action) {
    try {
        const res = yield uploadCSVDataCurrencyAPI(action.payload);
        yield put(currencyCSVUploadComplete(res.data.responseData));
    } catch (error) {
        yield put(currencyCSVUploadError(error.response.data.responseData));
    }
}

export function* watchCurrencySaga() {
    yield takeEvery(GET_ALL_CURRENCY, getAllCurrencySaga);
    yield takeEvery(UPLOAD_CURRENCY_CSV_DATA, uploadCurrencyCSVSaga);
}
