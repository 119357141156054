import { get } from "lodash";

const user = JSON.parse(localStorage.getItem("user"));
const permissions = get(user, "permissions", []);

const constKeys = {
  api: "",
  API_METHOD_POST: "POST",
  API_METHOD_GET: "GET",
  API_METHOD_PUT: "PUT",
  API_METHOD_PATCH: "PATCH",
  API_METHOD_DELETE: "DELETE",
  API_METHOD_HEAD: "HEAD",
  FORM_VALIDATION_EXAMPLE: "Form Inputs Validation Example",
  ERROR_BOUNDARY_EXAMPLE: "Error Boundary Example",
  API_RESPONSE_ERROR: "Something went wrong with API",
  GLOBAL_ERROR: "Something went wrong",
};
export const FILE_TYPE = ["CSV Download", "Excel Download"];
export const permissionEntity = [
  "PRODUCT_VIEW",
  "LOCATION_VIEW",
  "currency",
  "MAPPING_VIEW",
  "NETWORK_VIEW",
  "PERSONNEL_VIEW",
  "RAW_MATERIAL",
  "RM_NODE_MAPPING",
  "BUDGET_VIEW",
  "PROMOTION_TYPES_VIEW",
  "MARKETING_EVENTS_VIEW",
  "SALESHISTORY_VIEW",
  "FEATURES_VIEW",
  "FORECAST_VIEW",
  "GOODS_IN_TRANSIT",
  "GOODS_IN_HAND",
  "GOODS_IN_PBNS",
  "PRODUCTION_WORK_ORDER",
  "PO_EVENTS",
  "BILL_OF_MATERIALS",
  "STOCK_TRANSFER_ORDER",
  "PURCHASE_ORDER",
  "ROW_MATERIAL_PURCHASE_ORDER",
  "SHIPMENT",
];

export const ENTITY_NAME = [
  "product",
  "location",
  "mapping",
  "network",
  "sales-history",
  "personnel",
  "currency",
  "feature",
  "goods-in-hand",
  "goods-in-pbns",
  "goods-in-transit",
  "poevents",
  "forecast",
  "purchaseorder",
  "bill-of-materials",
  "production-work-order",
  "stock-transfer-order",
  "shipment",
  "rmpo",
  "sto-events",
  "splitmaster",
];

export const operatorsDataType = [
  {
    boolean: {
      operatorList: ["Is null", "Is not null", "Equals"],
      inputFieldType: "text",
    },
  },
  {
    float: {operatorList:[
      "Is null",
      "Is not null",
      "Equals",
      "Greater than",
      "Less than",
    ],
    inputFieldType: "number",
  },
  },

  {
    date: {operatorList:[
      "Is null",
      "Is not null",
      "Equals",
      "Greater than",
      "Less than",
      "Date Range",
    ],
    inputFieldType: "date",
  },
  },

  {
    json: {operatorList:["Is null", "Is not null", "Equals"],inputFieldType: "text" }
  },

  {
    double: {operatorList:[
      "Is null",
      "Is not null",
      "Equals",
      "Greater than",
      "Less than",
    ],
    inputFieldType: "number"
  },
  },

  {
    string: {operatorList:[
      "Start With",
      "Is null",
      "Is not null",
      "Comma separated values",
      "Equals",
    ],inputFieldType: "text"},
  },
  {
    long:{operatorList: ["Is null", "Is not null", "Equals", "Greater than", "Less than"],inputFieldType: "number"},
  },
  {
    uuid: {operatorList:["Is null", "Is not null", "Equals"],inputFieldType: "text"},
  },
  {
    integer: {operatorList:[
      "Is null",
      "Is not null",
      "Equals",

      "Greater than",
      "Less than",
    ],inputFieldType: "number"},
  },
  {
    enum: {operatorList:[
      "Is null",
      "Is not null",
      "Comma separated values",
      "Equals",

      "Greater than",
      "Less than",
    ], inputFieldType: "number"},
  },
];
export const operators = [
  "Start With",
  "Is null",
  "Is not null",
  "Comma separated values",
  "Equals",
  "Date Range",
  "Greater than",
  "Less than",
];
export default constKeys;
export const supportedLanguage = ["en-US"];
export const SHOW_ROWS = [10, 25, 50, 100];
export const ROUTE_TYPE = ["All"];

permissions.forEach((permission) => {
  let entity = permission
    .replace(/_VIEW$/, "")
    .replace(/_/g, "")
    .toLowerCase();
  if (permission === "PRODUCT_VIEW") {
    ROUTE_TYPE.push("product", "currency");
  } else if (permissionEntity.includes(permission)) {
    if (entity === "poevents") {
      entity = "dtrmevents";
    }
    if (entity === "rowmaterialpurchaseorder") {
      entity = "rmpo";
    }
    ROUTE_TYPE.push(entity);
  }
});

export const DARK_BLUE = "#292666";
