import React, { useState, useEffect } from "react";
import "../../../../style/sass/main.scss";
import TableGrid from "../../../../components/tableGrid";
import TableToolbar from "../../../../components/tableGrid/toolbar";
import "@progress/kendo-theme-default/dist/all.css";
import { get } from "lodash";
import {filesize} from 'filesize'

import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_INVENTORY_EVENTS,
  UPLOAD_INVENTORY_EVENTS_CSV_DATA,
} from "../../../../store/types";

import { useTranslation } from "react-i18next";
import { SHOW_ROWS } from "../../../../constant/constant";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import Breadcrumb from "../../../../components/breadcrumb";
import UploadDialog from "../../../../components/uploadDialog";
import { downloadCsvApi } from "../../../../apis";
import { csvFileReader } from "../../../../utils/csvFileReader";
import { entitiesName, permission } from "../../../../utils/Constants";
import { useNumericFormat } from "react-number-format";
import moment from "moment";
import ProcessingLoader from '../../../../components/processingLoader/'

const List = (props) => {
  const { t } = useTranslation();
  const EDM_API_ENDPOINT = process.env.REACT_APP_EDM_API_ENDPOINT;
  const SCAI_API_BASEURL = process.env.REACT_APP_SCAI_API_BASEURL_INVENTORY_EVENTS;
  const localeConfiguration = JSON.parse(localStorage.getItem("configuration")) ? JSON.parse(localStorage.getItem("configuration")).locale : {};
  const locationConfiguration = JSON.parse(localStorage.getItem("configuration")) ? JSON.parse(localStorage.getItem("configuration")).entities.locationHierarchy : {};
  const productConfiguration = JSON.parse(localStorage.getItem("configuration")) ? JSON.parse(localStorage.getItem("configuration")).entities.productHierarchy : {};
  const user = JSON.parse(localStorage.getItem("user"));
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const permissions = get(user, "permissions", []);
  const addEditPermission = permissions.includes(permission.salesHistoryAddEdit);

  const dispatch = useDispatch();
  const [rowLength, setRowLength] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [errorCSV, setErrorCSV] = useState("");
  const [allCheckboxId, setAllCheckboxId] = useState([]);
  const [childData, setChildData] = useState([]);
  const [updateflag, setUpdateflag] = useState(false);
  const [newallCheckboxId, setNewallCheckboxId] = useState([]);
  const [searchColumns, setSearchColumns] = useState("");

  const notRequiredColumns = JSON.parse(sessionStorage.getItem('inventoryEvents'));
  let strNRC;
  if (notRequiredColumns != null) {
    strNRC = notRequiredColumns.join(",");
  }

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };
  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      const error = await csvFileReader(file);
      if (!error) {
        setMyFiles([file])
        setErrorCSV("");
      } else {
        setErrorCSV(error);
        setMyFiles([]);
      }
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const files = myFiles.map((file) => (
    <li key={file.path} className="csv-upload-success-li">
      {file.path} - {file.size} bytes
    </li>
  ));
  useEffect(() => {
    if (visible === false) {
      setMyFiles([]);
      setErrorCSV("");
    }
  }, [visible]);

  const showMoreOptions = SHOW_ROWS.map((item) => ({
    label: `${t("Show")} ${item} ${t("Rows")}`,
    value: item,
  }));

  const body = {
    pageIndex: pageIndex,
    pageSize: rowLength,
    sortFieldStringWithASCOrDESC: sortValue,
    searchValue: searchField,
    tenantId: tenantId,
  };

  useEffect(() => {
    if (!refresh) {
      dispatch({ type: GET_ALL_INVENTORY_EVENTS, payload: body });
    }
  }, [dispatch, pageIndex, sortValue, rowLength]);

  const handleSearchSubmit = () => {
    setPageIndex(1);
    dispatch({
      type: GET_ALL_INVENTORY_EVENTS,
      payload: { ...body, pageIndex: 1 },
    });
  };
  const handleAdvanceSearch = (data) => {
    body.advanceSearchValue = data;
    body.buttonStatus = true;
    setPageIndex(1);
    dispatch({
      type: GET_ALL_INVENTORY_EVENTS,
      payload: { ...body, pageIndex: 1 },
    });
  };

  const clearAdvanceSearch = () => {
    body.buttonStatus = false;
    setPageIndex(1);
    dispatch({
      type: GET_ALL_INVENTORY_EVENTS,
      payload: { ...body, pageIndex: 1 },
    });
  };
  const refreshHandler = () => {
    setRefresh(true);
    setPageIndex(1);
    setSearchField("");
    setSortValue("");
    setRowLength(10);
    const refreshBody = {
      ...body,
      searchValue: "",
      pageIndex: 1,
      pageSize: 10,
      sortFieldStringWithASCOrDESC: "",
    };
    setTimeout(() => {
      dispatch({ type: GET_ALL_INVENTORY_EVENTS, payload: refreshBody });
      setRefresh(false);
    }, 300);
  };

  const { inventoryEventsList, csvUploadResponseData, csvUploadError, csvUpload, loader } =
    useSelector((state) => state.InventoryEventsReducer);
  const inventoryEventsEntities = get(inventoryEventsList, "entities", []);
  const totalElement = get(inventoryEventsList, "totalElements", "");
  const pageSize = get(inventoryEventsList, "pageSize", "");
  const { format } = useNumericFormat({
    decimalSeparator: localeConfiguration["decimalSeparator"],
    thousandsGroupStyle: localeConfiguration["thousandsGroupStyle"],
    thousandSeparator: localeConfiguration["thousandSeparator"],
    decimalScale: 2
  });
  const inventoryEventsDetails = inventoryEventsEntities

  const removeColumn = ["id",  "channel", 'sku'];
  let dataColumn =
    inventoryEventsDetails &&
    inventoryEventsDetails.length > 0 &&
    Object.keys(inventoryEventsDetails[0]).filter((item) => {
      return !removeColumn.includes(item);
    });

  let addRemoveTableDataColumn =
    inventoryEventsDetails &&
    inventoryEventsDetails.length > 0 &&
    Object.keys(inventoryEventsDetails[0]).filter((item) => {
      return !removeColumn.includes(item);
    });

  useEffect(() => {
    const storedCheckboxId = sessionStorage.getItem('inventoryEvents');
    if (storedCheckboxId) {
      const parsedCheckboxId = JSON.parse(storedCheckboxId);
      setAllCheckboxId(parsedCheckboxId);
    }
  }, []);

  const handleCheckboxChange = (e) => {
    const checkboxId = e.target.id;
    setAllCheckboxId(allCheckboxId => {
      if (!e.target.checked) {
        return [...allCheckboxId, checkboxId];
      } else {
        if (allCheckboxId.length == 1) {
          setAllCheckboxId([]);
          sessionStorage.setItem('inventoryEvents', JSON.stringify([]));
        }
        return allCheckboxId.filter(ID => ID !== checkboxId);
      }

    });
  };

  const handleDefault = () => {
    setAllCheckboxId([]);
    sessionStorage.setItem('inventoryEvents', JSON.stringify([]));
  };
  const unCheckDefault = () => {
    setAllCheckboxId(Object.values(addRemoveTableDataColumn));
    sessionStorage.setItem('inventoryEvents', JSON.stringify(Object.values(addRemoveTableDataColumn)));
  }
  if (!updateflag) {
    const localdata = JSON.parse(localStorage.getItem('inventoryEventsallcheckboxId'));
    // console.log("localdata",localdata);
    if (localdata != null) {
      dataColumn = Object.values(dataColumn).filter(
        (element) => !localdata.includes(element)
      );
    }
  }
  function handleHideandShow() {
    setNewallCheckboxId(allCheckboxId);
    localStorage.setItem('inventoryEventsallcheckboxId', JSON.stringify(allCheckboxId));
    if (dataColumn.length) {
      setUpdateflag(true);
      if (childData.length) {
        dataColumn = childData;
      }
      dataColumn = dataColumn.filter(
        (element) => !allCheckboxId.includes(element)
      );
      // console.log("handleHideandShow", dataColumn);
    }
  }

  if (entitiesName.includes('inventoryEvents') && allCheckboxId.length) {
    sessionStorage.setItem("inventoryEvents", JSON.stringify(allCheckboxId))
  }
  const handleChildData = (array) => {
    setChildData(array);
  };
  if (childData.length) {
    addRemoveTableDataColumn = childData;
  }
  const handleSearchItem = (data) => {
    const searchTerm = data.replace(/ /g, "_").toLowerCase();
    const matchFound = addRemoveTableDataColumn.some((item) =>
      item.includes(searchTerm)
    );
    if (matchFound) {
      setSearchColumns(data);
    }
  };

  if (searchColumns.length) {
    addRemoveTableDataColumn = addRemoveTableDataColumn.filter((item) =>
      item.includes(searchColumns.replace(/ /g, "_").toLowerCase())
    );
  }
  // if (dataColumn.length) {	
  //  if (childData.length) {	
  //    dataColumn = childData;	
  //  }	
  //  dataColumn = dataColumn.filter(	
  //    (element) => !allCheckboxId.includes(element)	
  //  );	
  // }	

  const customColumnHeaders = () => {
    if (inventoryEventsDetails && inventoryEventsDetails.length > 0) {
      return [
        { replaceWith: "sku_code", newLabel: productConfiguration ? productConfiguration["base"] + " " + t("labelCode") : t("sku_code") },
        { replaceWith: "sku_name", newLabel: productConfiguration ? productConfiguration["base"] : t("sku_name") },
        { replaceWith: "node_code", newLabel: locationConfiguration ? locationConfiguration["base"] + " " + t("labelCode") : t("node_code") },
        { replaceWith: "node", newLabel: locationConfiguration ? locationConfiguration["base"] : t("sku_code") },
      ];
    }
    else {
      return [];
    }
  }


  useEffect(() => {
    if (csvUploadError) {
      setVisible(false);
      toast.error(
        t(
          `${csvUploadError?.responseMessage ??
          csvUploadError.message ??
          csvUploadError
          }`
        ),
        {
          autoClose: false,
            closeOnClick: false,
            draggable: false,
        }
      );
    }
  }, [csvUploadError]);
  useEffect(() => {
    if (csvUpload) {
      setVisible(false);
      if (csvUploadResponseData.responseCode === "FAILED_HEADER_VALIDATION") {
        toast.error(`${csvUploadResponseData.responseMessage}`,
        {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_ROW_FAILURE"
      ) {
        toast.warning(
          `${
            t(`${csvUploadResponseData.responseCode +'\n'}`)
          }`,

          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        )
        toast.warning(
          <a href={`${csvUploadResponseData.errorFileName}`} target="_blank"> Download Error File </a>,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        )
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_OUT_ROW_FAILURE"
      ) {
        toast.success(
          `${t(`${csvUploadResponseData.responseCode}`) +
          " " +
          csvUploadResponseData.failedData.toString()
          }`,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else {
        toast.success(t(`${csvUploadResponseData.responseCode}`),
        {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      }
      refreshHandler();
    }
  }, [csvUpload]);
  const toggleDialog = () => {
    dispatch({ type: "inventoryEvents/inventoryEventsCSVUploadInitiate", payload: { csvUpload: false, loader: false } });
    setVisible(!visible);
  };
  const handleCSVUpload = () => {
    const fileSizeInMB = myFiles[0].size / (1024 * 1024);
    if(fileSizeInMB>40){
      return toast.warning("File size should be less than or equal to 40 MB",
      {
        autoClose: false,
            closeOnClick: false,
            draggable: false,
      })
    }
  //  if(myFiles[0].type!="text/csv" && myFiles[0].type!="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
  //   return toast.warning("Only CSV and EXCEL is supported")

  //  }
  if(myFiles[0].type!="text/csv" ){
    setVisible(!visible)

      return toast.error("Only CSV file is supported",
      {
        autoClose: false,
            closeOnClick: false,
            draggable: false,
      })

   }
    const uploadBody = {
      csvFile: myFiles[0],
      tenantId: tenantId,
    };
    dispatch({
      type: UPLOAD_INVENTORY_EVENTS_CSV_DATA,
      payload: uploadBody,
    });
    dispatch({ type: "inventoryEvents/inventoryEventsCSVUploadInitiate", payload: { csvUpload: false, loader: true } });
  };

  if (childData.length) {
    const localdata = JSON.parse(localStorage.getItem('inventoryEventsallcheckboxId'));
    if(localdata!=null){
      dataColumn = Object.values(childData).filter(
      (element)=> !localdata.includes(element)
      );
    } 
  }
  
  return (
    <>
      {loader && <ProcessingLoader />}
      <div>
        <section className="client-listing">
          <h3 className="section-heading">{t("Inventory Events")}</h3>

          <Breadcrumb
            list={[
              t("Transaction Data"),
              t("Inventory Events"),
            ]}
          />
          <div className="client-listing-content">
            <div className="client-listing-toolbar">
              <TableToolbar
                showMoreOptions={showMoreOptions}
                setRowLength={setRowLength}
                setPageIndex={setPageIndex}
                searchField={searchField}
                handleChange={handleChange}
                toggleDialog={toggleDialog}
                handleSearchSubmit={handleSearchSubmit}
                refreshHandler={refreshHandler}
                setSearchField={setSearchField}
                downloadUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/csv?tenant_id=${tenantId}&sortFieldStringWithASCOrDESC=${sortValue}&bu_id=${buId}&file_type=csv&searchValue=${searchField}&notRequiredColumns=${strNRC}`}
                dataList={inventoryEventsDetails}
                downloadCsvApi={downloadCsvApi}
                downloadName={"inventory-events.csv"}
                rowLength={rowLength}
                csvUploadPermission={addEditPermission}
                entityName={"inventory-events"}
                addRemoveTableDataColumn={addRemoveTableDataColumn ?? []}
                onCheckboxChange={handleCheckboxChange}
                sendDataToParent={handleChildData}
                allCheckboxId={allCheckboxId}
                setAllCheckboxId={setAllCheckboxId}
                handleSearchItem={handleSearchItem}
                ondefaultbutton={handleDefault}
                handleAdvanceSearch={handleAdvanceSearch}
                clearAdvanceSearch={clearAdvanceSearch}
                handleHideandShow={handleHideandShow}
                unCheckDefault={unCheckDefault}
              />
            </div>
            <div className="client-listing-grid">
              {" "}
              <TableGrid
                dataRows={inventoryEventsDetails ?? []}
                dataColumn={
                  updateflag
                    ? (Array.isArray(dataColumn) ? dataColumn.filter((element) => !newallCheckboxId.includes(element)) : [])
                    : (Array.isArray(dataColumn) ? dataColumn : [])
                }
                refresh={refresh}
                pageSize={pageSize}
                totalElement={totalElement}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setSortValue={setSortValue}
                width={'auto'}
                isLoading={loader}
                replaceColumnLevels={customColumnHeaders()}
                componentName={"inventoryEvents"}

              />
            </div>
          </div>
        </section>

        {visible && (
          <UploadDialog
            templateUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/template?tenant_id=${tenantId}&bu_id=${buId}`}
            toggleDialog={toggleDialog}
            handleCSVUpload={handleCSVUpload}
            myFiles={myFiles}
            listing={files}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            downloadCsvApi={downloadCsvApi}
            errorCSV={errorCSV}
            templateName={"inventory-events-template.csv"}
            dateFormat={localeConfiguration["dateFormat"]}
          />
        )}
      </div>
    </>
  );
};

export default List;
