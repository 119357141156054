import React, { useState, useEffect } from "react";
import "../../../../style/sass/main.scss";
import LocationGrid from "./locationGrid";
import Select from "react-select";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { TextField, InputAdornment } from "@mui/material";
import "@progress/kendo-theme-default/dist/all.css";
import { filesize } from "filesize";

import { isEmpty, get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_LOCATION,
  UPLOAD_LOCATION_CSV_DATA,
} from "../../../../store/types";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { SHOW_ROWS } from "../../../../constant/constant";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { downloadCsvApi } from "../../../../apis";
import { csvFileReader } from "../../../../utils/csvFileReader";
import { Error } from "@progress/kendo-react-labels";
import { entitiesName, permission } from "../../../../utils/Constants";
import ProcessingLoader from "../../../../components/processingLoader/";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Breadcrumb from "../../../../components/breadcrumb";
import TableToolbar from "../../../../components/tableGrid/toolbar";
import { NextPlanOutlined } from "@mui/icons-material";
import { use } from "i18next";

const List = (props) => {
  const { t } = useTranslation();
  const EDM_API_ENDPOINT = process.env.REACT_APP_EDM_API_ENDPOINT;
  const SCAI_API_BASEURL_LOCATION =
    process.env.REACT_APP_SCAI_API_BASEURL_LOCATION;

  const locationConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .locationHierarchy
    : {};
  const locationCustomHeader = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration"))?.entities
        ?.locationCustomHeader
    : {};
  const user = JSON.parse(localStorage.getItem("user"));
  const localeConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).locale
    : {};
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const permissions = get(user, "permissions", []);
  const addEditPermission = permissions.includes(permission.locationAddEdit);
  const dispatch = useDispatch();
  const [rowLength, setRowLength] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [errorCSV, setErrorCSV] = useState("");
  const [allCheckboxId, setAllCheckboxId] = useState([]);
  const [childData, setChildData] = useState([]);
  const [hideShowList, setHideShowList] = useState([]);
  const [searchColumns, setSearchColumns] = useState("");
  const [updateflag, setUpdateflag] = useState(false);
  const [newallCheckboxId, setNewallCheckboxId] = useState([]);
  const [csvDownloadTableDataRows, setCSVDownloadTableDataRows]=useState("")
  const hierarchyName = "location";
  const notRequiredColumns = JSON.parse(sessionStorage.getItem("location"));
  let strNRC;
  if (notRequiredColumns != null) {
    strNRC = notRequiredColumns.join(",");
  }

  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      const error = await csvFileReader(file);
      if (!error) {
        setMyFiles([file]);
        setErrorCSV("");
      } else {
        setErrorCSV(error);
        setMyFiles([]);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const files = myFiles.map((file) => (
    <li key={file.path} className="csv-upload-success-li">
      {file.path} - {file.size} bytes
    </li>
  ));
  useEffect(() => {
    if (visible === false) {
      setMyFiles([]);
      setErrorCSV("");
    }
  }, [visible]);
  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  const showMoreOptions = SHOW_ROWS.map((item) => ({
    label: `${t("Show")} ${item} ${t("Rows")}`,
    value: item,
  }));

  const toggleDialog = () => {
    dispatch({
      type: "location/locationCSVUploadInitiate",
      payload: { csvUpload: false, loader: false },
    });
    setVisible(!visible);
  };

  const body = {
    pageIndex: pageIndex,
    pageSize: rowLength,
    sortFieldStringWithASCOrDESC: sortValue,
    searchValue: searchField,
    productId: "0",
    tenantId: tenantId,
  };

  useEffect(() => {
    if (!refresh) {
      if (csvDownloadTableDataRows === "") {
        dispatch({ type: GET_ALL_LOCATION, payload: body });
      } else {
        body.advanceSearchValue = csvDownloadTableDataRows;
        body.buttonStatus = true;
        dispatch({ type: GET_ALL_LOCATION, payload: { ...body } });
      }
    }
  }, [dispatch, pageIndex, sortValue, rowLength]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setPageIndex(1);
    dispatch({ type: GET_ALL_LOCATION, payload: { ...body, pageIndex: 1 } });
  };

  const handleAdvanceSearch = (rows) => {
    if(rows.length!==1 && rows[0].columnName!=="Select Value" && rows[0].operator!=="Select Type"){

    } else{
      const advanceSearchTableColumn=JSON.parse(localStorage.getItem("featureHeader"))

    let result = '';
    rows.forEach((item, index) => {
        const keyIndex = index + 1;
        result += `value${keyIndex}=${advanceSearchTableColumn.filter(data=>data.feName===item.columnName)[0].beName},${item.operator.toLowerCase().replace(/\s+/g, '_')},${item.value}`;

        if (index !== rows.length - 1) {
            result += '&';
        }
    });
    setCSVDownloadTableDataRows(result)
    body.advanceSearchValue = result;
    body.buttonStatus = true;
    setPageIndex(1);
    dispatch({ type: GET_ALL_LOCATION, payload: { ...body, pageIndex: 1 } });
    }

  };

  const clearAdvanceSearch = () => {
    body.buttonStatus = false;
    setPageIndex(1);
    dispatch({ type: GET_ALL_LOCATION, payload: { ...body, pageIndex: 1 } });
  };

  const refreshHandler = () => {
    setRefresh(true);
    setPageIndex(1);
    setSearchField("");
    setSortValue("");
    setRowLength(10);
    const refreshBody = {
      ...body,
      searchValue: "",
      pageIndex: 1,
      pageSize: 10,
      sortFieldStringWithASCOrDESC: "",
    };
    setTimeout(() => {
      dispatch({ type: GET_ALL_LOCATION, payload: refreshBody });
      setRefresh(false);
    }, 300);
  };

  const locations = useSelector((state) => state.LocationReducer.locations);
  const { csvUploadResponseData, csvUploadErrorResponse, csvUpload, loader } =
    useSelector((state) => state.LocationReducer);
  const csvUploadError = useSelector(
    (state) => state.LocationReducer.csvUploadError
  );
  const locationsDetails = get(locations, "entities", "");
  const totalElement = get(locations, "totalElements", "");
  const pageSize = get(locations, "pageSize", "");
  useEffect(() => {
    if (csvUploadError) {
      setVisible(false);
      toast.error(t(`${csvUploadError.message}`), {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    }
  }, [csvUploadError]);
  const locationDataDetails =
    locationsDetails &&
    locationsDetails.map((item) => {
      const items = { ...item };
      items.node_type_code =
        Object.keys(item?.node_type ?? {}).length > 0
          ? item?.node_type?.node_type_code
          : "";
      items.node_type_title =
        Object.keys(item?.node_type ?? {}).length > 0
          ? item?.node_type?.node_type_title
          : "";
      if (
        locationCustomHeader &&
        Number(locationCustomHeader?.locationCustomHeaderCount) &&
        Number(locationCustomHeader?.locationCustomHeaderCount) > 0
      ) {
        for (
          let index = 1;
          index <= locationCustomHeader?.locationCustomHeaderCount;
          index++
        ) {
          items[locationCustomHeader[`header${index}`]] = item[`header${index}`]
          delete items[`header${index}`]
        }
      }
      items.latitude = item.coordinates ? item.coordinates.x : 0;
      items.longitude = item.coordinates ? item.coordinates.y : 0;
      return items;
    });

  useEffect(() => {
    if (csvUploadErrorResponse) {
      setVisible(false);
      toast.error(t(`${csvUploadErrorResponse.responseMessage}`), {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    }
  }, [csvUploadErrorResponse]);

  useEffect(() => {
    if (csvUpload) {
      setVisible(false);
      if (csvUploadResponseData.responseCode === "FAILED_HEADER_VALIDATION") {
        toast.error(`${csvUploadResponseData.responseMessage}`, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
        });
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_ROW_FAILURE"
      ) {
        toast.warning(
          `${t(`${csvUploadResponseData.responseCode + "\n"}`)}`,

          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
        toast.warning(
          <a href={`${csvUploadResponseData.errorFileName}`} target="_blank">
            {" "}
            Download Error File{" "}
          </a>,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_OUT_ROW_FAILURE"
      ) {
        toast.success(
          `${
            t(`${csvUploadResponseData.responseCode}`) +
            " " +
            csvUploadResponseData.failedData.toString()
          }`,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else {
        toast.success(t(`${csvUploadResponseData}`), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
        });
      }
      refreshHandler();
    }
  }, [csvUpload]);

  const handleCSVUpload = () => {
    const fileSizeInMB = myFiles[0].size / (1024 * 1024);
    if (fileSizeInMB > 40) {
      return toast.warning("File size should be less than or equal to 40 MB", {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    }
    if (myFiles[0].type != "text/csv") {
      setVisible(!visible);

      return toast.error("Only CSV file is supported", {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    }
    const uploadBody = {
      csvFile: myFiles[0],
      tenantId: tenantId,
    };
    dispatch({ type: UPLOAD_LOCATION_CSV_DATA, payload: uploadBody });
    dispatch({
      type: "location/locationCSVUploadInitiate",
      payload: { csvUpload: false, loader: true },
    });
  };

  const removeColumn = [
    "node_type",
    "id",
    "node_mapping_id",
   
    "coordinates",
  ];
  let locationGrid =
    locationDataDetails &&
    locationDataDetails.length > 0 &&
    Object.keys(locationDataDetails[0]).filter((item, i) => {
      return !removeColumn.includes(item);
    });

  let dataColumnDetails =
    locationDataDetails &&
    locationDataDetails.length > 0 &&
    Object.keys(locationDataDetails[0]).filter((item, i) => {
      return !removeColumn.includes(item);
    });

  let addRemoveTableDataColumn = Array.isArray(dataColumnDetails)
    ? dataColumnDetails.map((item) =>
        item.includes("locationHierarchyLevel") && locationConfiguration
          ? locationConfiguration[item]
          : item.toUpperCase() == "NODE_CODE" && locationConfiguration
          ? locationConfiguration["base"] + " " + t("labelCode")
          : item.toUpperCase() == "NODE" && locationConfiguration
          ? locationConfiguration["base"]
          : item
      )
    : [];

  useEffect(() => {
    const storedCheckboxId = sessionStorage.getItem("location");
    if (storedCheckboxId) {
      const parsedCheckboxId = JSON.parse(storedCheckboxId);
      setAllCheckboxId(parsedCheckboxId);
    }
  }, []);

  if (entitiesName.includes("location") && allCheckboxId.length) {
    sessionStorage.setItem("location", JSON.stringify(allCheckboxId));
  }

  const handleHideShowList = (array) => {
    setHideShowList(array);
  };
  if (hideShowList.length) {
    addRemoveTableDataColumn = hideShowList;
  }

  const handleChildData = (array) => {
    setChildData(array);
  };
  if (childData.length) {
    dataColumnDetails = childData;
  }
  const handleSearchItem = (data) => {
    const searchTerm = data.replace(/ /g, "_").toLowerCase();
    const matchFound = addRemoveTableDataColumn.some((item) =>
      item.includes(searchTerm)
    );
    if (matchFound) {
      setSearchColumns(data);
    }
  };

  if (searchColumns.length) {
    addRemoveTableDataColumn = addRemoveTableDataColumn.filter((item) =>
      item.includes(searchColumns.replace(/ /g, "_").toLowerCase())
    );
  }

  const handleCheckboxChange = (e) => {
    const checkboxId = e.target.id;
    const index = addRemoveTableDataColumn.indexOf(checkboxId);
    setAllCheckboxId((allCheckboxId) => {
      if (!e.target.checked) {
        return [...allCheckboxId, dataColumnDetails[index]];
      } else {
        if (allCheckboxId.length == 1) {
          setAllCheckboxId([]);
          sessionStorage.setItem("location", JSON.stringify([]));
        }
        return allCheckboxId.filter((ID) => ID !== dataColumnDetails[index]);
      }
    });
  };

  const handleDefault = () => {
    setAllCheckboxId([]);
    sessionStorage.setItem("location", JSON.stringify([]));
  };
  const unCheckDefault = () => {
    setAllCheckboxId(Object.values(dataColumnDetails));
    sessionStorage.setItem(
      "location",
      JSON.stringify(Object.values(dataColumnDetails))
    );
  };
  if (!updateflag) {
    const localdata = JSON.parse(localStorage.getItem("locationallcheckboxId"));
    if (localdata != null) {
      locationGrid = Object.values(locationGrid).filter(
        (element) => !localdata.includes(element)
      );
    }
  }
  function handleHideandShow() {
    setNewallCheckboxId(allCheckboxId);
    localStorage.setItem(
      "locationallcheckboxId",
      JSON.stringify(allCheckboxId)
    );
    if (locationGrid.length) {
      setUpdateflag(true);
      if (childData.length) {
        locationGrid = childData;
      }
      locationGrid = locationGrid.filter(
        (element) => !allCheckboxId.includes(element)
      );
    }
  }

  if (childData.length) {
    const localdata = JSON.parse(localStorage.getItem("locationallcheckboxId"));
    if (localdata != null) {
      locationGrid = Object.values(childData).filter(
        (element) => !localdata.includes(element)
      );
    }
  }
  const storedFeatureHeaderString = localStorage.getItem("featureHeader");
  let storedFeatureHeader;
  if (storedFeatureHeaderString !== "undefined") {
    storedFeatureHeader = JSON.parse(storedFeatureHeaderString);
  }
  return (
    <>
      {loader && <ProcessingLoader />}
      <div>
        <section className="client-listing">
          <h3 className="section-heading">{t("Location")}</h3>
          <Breadcrumb list={[t("Entities Data"), t("Location")]} />

          <div className="client-listing-content">
            <div className="client-listing-toolbar">
              <TableToolbar
                showMoreOptions={showMoreOptions}
                setRowLength={setRowLength}
                setPageIndex={setPageIndex}
                searchField={searchField}
                setSearchField={setSearchField}
                handleChange={handleChange}
                toggleDialog={toggleDialog}
                handleSearchSubmit={handleSearchSubmit}
                refreshHandler={refreshHandler}
                rowLength={rowLength}
                downloadUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL_LOCATION}/csv?tenant_id=${tenantId}&bu_id=${buId}&sortFieldStringWithASCOrDESC=${sortValue}&file_type=csv&searchValue=${searchField}&${csvDownloadTableDataRows}&notRequiredColumns=${strNRC}`}
                dataList={locationsDetails}
                downloadCsvApi={downloadCsvApi}
                downloadName={"location.csv"}
                csvUploadPermission={addEditPermission}
                entityName={"location"}
                componentNameAddEdit={"Location"}
                storedFeatureHeader={storedFeatureHeader}
                addRemoveTableDataColumn={addRemoveTableDataColumn ?? []}
                onCheckboxChange={handleCheckboxChange}
                sendDataToParent={handleChildData}
                allCheckboxId={allCheckboxId}
                setAllCheckboxId={setAllCheckboxId}
                handleSearchItem={handleSearchItem}
                ondefaultbutton={handleDefault}
                // showAdvanceSearch={"location"}
                handleAdvanceSearch={handleAdvanceSearch}
                clearAdvanceSearch={clearAdvanceSearch}
                hierarchyName={hierarchyName}
                handleHideandShow={handleHideandShow}
                unCheckDefault={unCheckDefault}
                dataColumnDetails={dataColumnDetails}
                dataForHideShowList={handleHideShowList}
                dataRows={locationDataDetails}
              />
            </div>
            <div className="client-listing-grid">
              {" "}
              <LocationGrid
                locationData={locationsDetails}
                refresh={refresh}
                pageSize={pageSize}
                totalElement={totalElement}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setSortValue={setSortValue}
                isLoading={loader}
                componentName={"location"}
                locationGrid={
                  updateflag
                    ? Array.isArray(locationGrid)
                      ? locationGrid.filter(
                          (element) => !newallCheckboxId.includes(element)
                        )
                      : []
                    : Array.isArray(locationGrid)
                    ? locationGrid
                    : []
                }
              />
            </div>
          </div>
        </section>

        {visible && (
          <Dialog id="window">
            <span className="span1">{t("Upload")}</span>
            <AiOutlineCloseCircle
              onClick={toggleDialog}
              className="k-icon k-btn1"
            />
            <DialogActionsBar>
              <div className="border2">
                <div className="div2">
                  <section className="container">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input
                        {...getInputProps()}
                        accept={".csv"}
                        type="file"
                        name="file"
                      />
                      <h4 className="kh4">Select a CSV file to upload</h4>
                      <p className="kp">and drag or drop it here</p>
                      <p className="kp date_format_text mt-2">
                        <b>Note:</b> Date format should be{" "}
                        <b>{localeConfiguration["dateFormat"]}</b>
                      </p>
                      <p className="kp date_format_text mt-2">
                        <b>* -</b> denotes mandatory columns
                      </p>
                    </div>
                    <aside>{myFiles[0] ? <ul>{files}</ul> : ""}</aside>
                    {errorCSV && (
                      <Error className="dialog-error">{t(errorCSV)}</Error>
                    )}
                  </section>
                </div>
              </div>
            </DialogActionsBar>

            <DialogActionsBar>
              <div className="custom-k-dialog-buttongroup">
                <button
                  onClick={() => {
                    downloadCsvApi({
                      url: `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_LOCATION}/template?tenant_id=${tenantId}&bu_id=${buId}`,
                      fileName: "location-template.csv",
                    });
                  }}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base download-template-button"
                >
                  {t("Download Template")}
                  <span className="k-icon k-i-download"></span>
                </button>
                <button
                  className={`k-button k-button-md k-rounded-md k-button-solid k-button-solid-base upload-csv-button-dialog ${
                    isEmpty(files) && "k-disabled"
                  } `}
                  onClick={handleCSVUpload}
                >
                  {t("Upload")}
                </button>
              </div>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default List;
