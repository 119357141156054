import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { TextField, InputAdornment, Icon, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MenuItem from "@mui/material/MenuItem";
import { get } from "lodash";
import Checkbox from "@mui/material/Checkbox";
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers-pro';
// import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

import SyncIcon from "@mui/icons-material/Sync";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  addEditTableColumnDataTypes,
  addEditPermission,
  advanceSearchPermission,
} from "../../../utils/Constants";
import { Collapse } from "@mui/material";
// new code - 0
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import Popover from "@mui/material/Popover";
import DialogActions from "@mui/material/DialogActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  forecastSyncAPI,
  getAllLikeSkuAPI,
  getLikeSkuSearchAdvanceFilterAPI,
} from "../../../apis";

import {
  GET_ALL_EDIT_FEATURES,
  GET_ALL_EDIT_LOCATION,
  POST_ALL_ADD_LOCATION,
  POST_ALL_ADD_FEATURES,
  GET_ALL_EDIT_PRODUCT,
  POST_ALL_ADD_PRODUCT,
  GET_ALL_EDIT_BUDGET,
  POST_ALL_ADD_BUDGET,
  GET_ALL_EDIT_PROMO_MASTER,
  POST_ALL_PROMO_MASTER,
  GET_ALL_EDIT_PROMO_TRANSACTION,
  POST_ALL_PROMO_TRANSACTION,
} from "../../../store/types";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import Typography from "@mui/material/Typography";
import Dialogg from "@mui/material/Dialog";
import Buttonn from "@mui/material/Button";
import { Dialog } from "@progress/kendo-react-dialogs";
import MenuIcon from "@mui/icons-material/Menu";
// import { DateRangePicker } from "rsuite";
import { DatePicker } from "antd";
import { Input } from "@progress/kendo-react-inputs";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTrashAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TableGrid from "..";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
// import { useSelector } from "react-redux";
// end new code - 0
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { InputSelect } from "../../inputSelect/";
import { Button } from "../../button/";

import { InputText } from "../../inputText/";
import { FILE_TYPE } from "../../../constant/constant";
import { MdOutlineFileDownload } from "react-icons/md";
import { ROUTE_TYPE } from "../../../constant/constant";
import DownloadIcon from "@mui/icons-material/Download";
// new code - 1
import { operators, operatorsDataType } from "../../../constant/constant";
import isAfter from "date-fns/isAfter";
import { makeStyles } from "@mui/styles";

import "./addEdit.scss";

const useStyles = makeStyles((theme) => ({
  redStart: {
    color: "red",
  },
}));
// import { Select } from 'antd';

const { RangePicker } = DatePicker;

const BootstrapDialog = styled(Dialogg)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
// end new code -1

const TableToolbar = ({
  showMoreOptions,
  setRowLength,
  setPageIndex,
  searchField,
  setSearchField,
  handleChange,
  toggleDialog,
  handleSearchSubmit,
  componentNameAddEdit,
  refreshHandler,
  downloadUrl,
  dataList,
  showAdvanceSearch,
  disabled,
  downloadCsvApi,
  downloadName,
  rowLength,
  csvUploadPermission,
  entityName,
  addRemoveTableDataColumn,
  productHierarchyConfiguration,
  locationHierarchyConfiguration,
  sendDataToParent,
  onCheckboxChange,
  allCheckboxId,
  handleSearchItem,
  promoNamesList,
  ondefaultbutton,
  unCheckDefault,
  handleAdvanceSearch,
  clearAdvanceSearch,
  hierarchyName,
  hierarchyNameExtra,
  handleHideandShow,
  dataColumnDetails,
  dataForHideShowList,
  dataRows,
  storedFeatureHeader,
  componentName,
  snopId,
}) => {
  const { t } = useTranslation();
  // new code -2
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [showHideAdvanceSearch, setShowHideAdvanceSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isGoButtonClicked, setIsGoButtonClicked] = useState(false);
  const [isOkButtonClicked, setIsOkButtonClicked] = useState(false);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [dataTypeError, setDataTypeErrors] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [activeInactiveColor, setActiveInactiveColor] = useState("active");
  const advanceSearchTableCColumnNames = addRemoveTableDataColumn.map((col) =>
    t(`${col}`)
  );

  // const specialCharacters = ["!", "@", "#", "$", "%", "^", "&","*", "(",")","=","+","{","}","[","[","?",",","<",">","~","`","'"];
  const onChangeSearchItem = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearchItem(e.target.value);
  };

  const hierarchyKeyExtra = `${hierarchyNameExtra}Hierarchy`;

  const hierarchyConfigurationExtra = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities[
        hierarchyKeyExtra
      ]
    : {};
  const hierarchyKey = `${hierarchyName}Hierarchy`;
  const hierarchyConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities[hierarchyKey]
    : {};

  let [rows, setRows] = useState([
    { id: 1, columnName: "Select Value", operator: "Select Type", value: "" }, //1
  ]);
  // const [advanceSearch , setAdvanceSearch] = useState(rows);
  const syncConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration"))?.entities?.custom
    : null;

  const { selectedRowIds } = useSelector((store) => store.commonReducer);
  const { addEditTableColumnLocation } = useSelector(
    (state) => state.LocationReducer
  );
  const { addEditTableColumnProduct } = useSelector(
    (state) => state.ProductReducer
  );
  const { addEditTableColumnFeature } = useSelector(
    (state) => state.FeatureReducer
  );
  const { addEditTableColumnBudget } = useSelector(
    (state) => state.BudgetReducer
  );
  const { addEditTableColumnPromoMaster } = useSelector(
    (state) => state.PromoMasterReducer
  );
  const { addEditTableColumnPromoTransaction } = useSelector(
    (state) => state.PromoReducer
  );
  // addEditTableColumnPromoMaster
  useEffect(() => {
    if (typeof addEditTableColumnPromoTransaction?.message === "string") {
      refreshHandler();

      toast.success(`${addEditTableColumnPromoTransaction?.message}`);
    }
  }, [addEditTableColumnPromoTransaction]);
  useEffect(() => {
    if (typeof addEditTableColumnBudget?.message === "string") {
      refreshHandler();

      toast.success(`${addEditTableColumnBudget?.message}`);
    }
  }, [addEditTableColumnBudget]);
  useEffect(() => {
    if (typeof addEditTableColumnPromoMaster?.message === "string") {
      refreshHandler();

      toast.success(`${addEditTableColumnPromoMaster?.message}`);
    }
  }, [addEditTableColumnPromoMaster]);

  useEffect(() => {
    if (typeof addEditTableColumnFeature?.message === "string") {
      refreshHandler();

      toast.success(`${addEditTableColumnFeature?.message}`);
    }
  }, [addEditTableColumnFeature]);
  useEffect(() => {
    if (typeof addEditTableColumnLocation?.message === "string") {
      refreshHandler();

      toast.success(`${addEditTableColumnLocation?.message}`);
    }
  }, [addEditTableColumnLocation]);
  useEffect(() => {
    if (typeof addEditTableColumnProduct?.message === "string") {
      refreshHandler();

      toast.success(`${addEditTableColumnProduct?.message}`);
    }
  }, [addEditTableColumnProduct]);
  const selectedIds = selectedRowIds.map((row) => row.id);
  let selectedRowData;
  if (componentNameAddEdit && componentNameAddEdit.length) {
    if (
      componentNameAddEdit.toLowerCase() === "feature" ||
      componentNameAddEdit.toLowerCase() === "budget" ||
      componentNameAddEdit.toLowerCase() === "promo" ||
      componentNameAddEdit.toLowerCase() === "promomaster"
    ) {
      selectedRowData =
        dataRows?.length &&
        dataRows?.filter((item) => selectedIds.includes(item.id));
    } else if (componentNameAddEdit.toLowerCase() === "product") {
      selectedRowData =
        dataRows.length &&
        dataRows?.filter((item) => selectedIds.includes(item.mapping_id));
    } else if (componentNameAddEdit.toLowerCase() === "location") {
      selectedRowData =
        dataRows.length &&
        dataRows?.filter((item) => selectedIds.includes(item.node_mapping_id));
    }
  }

  const flattenedRawData = flattenObject(selectedRowData);
  // const flattenedRawData = selectedRowData && selectedRowData[0];

  function flattenObject(obj) {
    let result = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          // Recursively flatten nested objects
          const nestedValues = flattenObject(obj[key]);
          Object.assign(result, nestedValues);
        } else {
          // Assign key-value pair for non-object values
          result[key] = obj[key];
        }
      }
    }

    return result;
  }

  const handleeee = (e) => {
    // consolel
  };

  const [nextId, setNextId] = useState(2);
  const handleClearFilter = () => {
    setAdvanceFilterInputDataType([]);
    if (isOkButtonClicked) {
      setIsOkButtonClicked(false);
      // clearAdvanceSearch();
    }
    setRows([
      { id: 1, columnName: "Select Value", operator: "Select Type", value: "" }, //2
    ]);
  };

  const addRow = () => {
    if (rows.length > 4) {
      return toast.warning("Can not add more than 5 row");
    }
    setRows([
      ...rows,
      {
        id: nextId,
        columnName: "Select Value",
        operator: "Select Type",
        value: "", //3
      },
    ]);
    setNextId(nextId + 1);
  };
  // const handleTableColumn = (id, event) => {
  //   const updatedRows = rows.map((row) => {
  //     if (row.id === id) {
  //       return { ...row, columnName: event.target.value };
  //     }
  //     return row;
  //   });

  //   setRows(updatedRows);
  // };
  const [advanceFilterInputDataType, setAdvanceFilterInputDataType] = useState(
    []
  );
  const handleOperators = (id, event) => {
    // setAdvanceFilterInputDataType(event.target.selectedIndex-1)
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, operator: event.target.value, value: "" }; //4
      }
      return row;
    });
    setRows(updatedRows);
    console.log("UpdatedRous", updatedRows);
  };

  const handleAdvanceSearchDateRange = (id, event) => {
    let startDateString = event[0].$d;
    let endDateString = event[1].$d;
    const sDate = new Date(startDateString);
    const eDate = new Date(endDateString);
    let startDate = sDate.toISOString().slice(0, 10);
    let endDate = eDate.toISOString().slice(0, 10);
    let date = "";
    //     for(let i=0;i<event.length;i++){
    //       const year = date.getFullYear();
    // const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
    // const day = String(date.getDate()).padStart(2, "0");

    // const formattedDate = `${year}-${month}-${day}`;
    //     }
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        if (event.length) return { ...row, value: `${startDate},${endDate}` };
        // return { ...row, value: event.target.value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleInputChangeOne = (id, event) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        if (event.length) return { ...row, value: event };
        return { ...row, value: event.target.value };
      }
      return row;
    });
    setRows(updatedRows);
  };
  // const handleInputChangeTwo = (id, event) => {
  //   const updatedRows = rows.map(row => {
  //     if (row.id === id) {
  //       return { ...row, value2: event.target.value };
  //     }
  //     return row;
  //   });
  //   setRows(updatedRows);
  // };
  const removeRow = (id) => {
    const newArray = [...advanceFilterInputDataType];

    // Set the value at the specific index in the new array
    newArray[id - 1] = undefined;
    setAdvanceFilterInputDataType(newArray);
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };
  const handleFilteredData = () => {
    setAdvanceFilterInputDataType([]);

    let flag = false;
    rows.map((row) => {
      if (row.columnName === "Select Value" || row.operator === "Select Type") {
        flag = true;
        return;
      }
    });
    if (flag) {
      return toast.warning("Column Name or Operator is missing");
    }

    if (
      rows.length === 1 &&
      rows[0].columnName === "Select Value" &&
      rows[0].operator === "Select Type"
    ) {
      setShowHideAdvanceSearch(false);
    } else {
      handleAdvanceSearch(rows);
      // let [rows, setRows] = useState([
      //   { id: 1, columnName: "Select Value", operator: "Select Type", value: "" }, //1
      // ]);
      setRows([
        ...rows, //1
      ]);
      setShowHideAdvanceSearch(false);
      setIsOkButtonClicked(true);
    }

    // for (let i = 0; i < rows.length; i++) {
    //   if (rows[i].operator === "Date Range") {
    //     if (typeof rows[i].value != "string") {
    //       const dateData = rows[i].value;
    //       let startDate;
    //       let endDate;
    //       if (dateData && dateData.length) {
    //         let startDateString = dateData[0].$d;
    //         let endDateString = dateData[1].$d;
    //         const sDate = new Date(startDateString);
    //         const eDate = new Date(endDateString);
    //         startDate = sDate.toISOString().slice(0, 10);
    //         endDate = eDate.toISOString().slice(0, 10);
    //       }
    //       rows[i].value = [startDate, endDate].join(",");
    //     }
    //   }

    //   if (rows[i].columnName) {
    //     rows[i].columnName = rows[i].columnName
    //       .toLowerCase()
    //       .replace(/ /g, "_")
    //       .replace(/_-_/g, " - ");
    //   }
    //   if (rows[i].operator == "Starts With") {
    //     rows[i].operator = "start_with";
    //   }
    //   if (rows[i].operator == "Is null") {
    //     rows[i].operator = "is_null";
    //   }
    //   if (rows[i].operator == "Is not null") {
    //     rows[i].operator = "is_not_null";
    //   }
    //   if (rows[i].operator == "Comma seperated values") {
    //     rows[i].operator = "comma_seprated_values";
    //   }
    //   if (rows[i].operator == "Equals") {
    //     rows[i].operator = "equals";
    //   }
    //   if (rows[i].operator == "Date Range") {
    //     rows[i].operator = "date_range";
    //   }
    //   if (rows[i].operator == "Greater than") {
    //     rows[i].operator = "greater_than";
    //   }
    //   if (rows[i].operator == "Less than") {
    //     rows[i].operator = "less_than";
    //   }
    // }
    // const isAnyValueSelectValue = rows.map((item) => {
    //   if (item.columnName == "select_value") {
    //     item.columnName = "";
    //   }
    //   if (item.operator == "Select Type") {
    //     item.operator = "";
    //   }
    //   return item;
    // });

    // const filterRows = isAnyValueSelectValue.map((row) => {
    //   const { id, ...restOfRow } = row;
    //   return restOfRow;
    // });

    // let hasEmptyColumnName = false;
    // let hasEmptyOperator = false;
    // let hasEmtpySearchValue = false;

    // for (let i = 0; i < filterRows.length; i++) {
    //   if (filterRows[i].columnName == "") {
    //     hasEmptyColumnName = true;
    //   }
    //   if (filterRows[i].operator == "") {
    //     hasEmptyOperator = true;
    //   }
    //   if (filterRows[i].operator == "start_with" && filterRows[i].value == "") {
    //     hasEmtpySearchValue = true;
    //   }
    // }

    // if (hasEmptyColumnName) {
    //   toast.warning("SELECT COLUMN NAME");
    // } else if (hasEmptyOperator) {
    //   toast.warning("SELECT OPERATOR");
    // } else if (hasEmtpySearchValue) {
    //   toast.warning("ENTER VALUE");
    // } else {
    //   setShowHideAdvanceSearch(false);
    //   setIsOkButtonClicked(true);
    //   handleAdvanceSearch(filterRows);
    // }

    // for (let i = 0; i < rows.length; i++) {
    //   if (rows[i].columnName == "") {
    //     rows[i].columnName = "Select Value";
    //   }
    //   if (rows[i].columnName) {
    //     const words = rows[i].columnName.split("_"); // Split by underscores
    //     const transformedWords = words.map((word, index) => {
    //       if (index === 0) {
    //         return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize the first word
    //       } else {
    //         return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Capitalize subsequent words
    //       }
    //     });
    //     rows[i].columnName = transformedWords.join(" "); // Join with spaces
    //   }

    //   if (rows[i].operator == "") {
    //     rows[i].operator = "Select Type";
    //   }
    //   if (rows[i].operator == "start_with") {
    //     rows[i].operator = "Starts With";
    //   }
    //   if (rows[i].operator == "is_null") {
    //     rows[i].operator = "Is null";
    //   }
    //   if (rows[i].operator == "is_not_null") {
    //     rows[i].operator = "Is not null";
    //   }
    //   if (rows[i].operator == "comma_seprated_values") {
    //     rows[i].operator = "Comma seperated values";
    //   }
    //   if (rows[i].operator == "equals") {
    //     rows[i].operator = "Equals";
    //   }
    //   if (rows[i].operator == "date_range") {
    //     rows[i].operator = "Date Range";
    //   }
    //   if (rows[i].operator == "greater_than") {
    //     rows[i].operator = "Greater than";
    //   }
    //   if (rows[i].operator == "less_than") {
    //     rows[i].operator = "Less than";
    //   }
    // }
  };

  const handleClickOpen = () => {
    setOpen(true);
    // setUpdateflag(false);
    // hideshowclick();
  };
  const handleClose = () => {
    setOpen(false);
  };
  // new code -2
  const handleDefault = () => {
    ondefaultbutton();
    // setAllCheckboxId([]);
  };
  const unCheckClick = () => {
    unCheckDefault();
  };
  const handleUpdatebutton = () => {
    if (allCheckboxId.length > addRemoveTableDataColumn.length - 3) {
      toast.error("Please Select at least 3 Columns", {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    } else {
      handleHideandShow();
    }
    setOpen(false);
  };
  // end new code - 2
  const handleSearch = (e) => {
    e.preventDefault();
    // const containsSpecialCharacter = specialCharacters.some(char => searchField.includes(char));
    // if (containsSpecialCharacter) {
    //   return toast.warning("Enter Valid Input");
    // }
    if (searchField.length >= 3) {
      setIsGoButtonClicked(true);
    }
    handleSearchSubmit(e);
  };

  // new code -3
  const handleCheckbox = (e) => {
    onCheckboxChange(e);
  };

  const handleOnDragEnd = (result) => {
    if (!searchTerm) {
      if (!result.destination) {
        return;
      }
      const temparray = Array.from(addRemoveTableDataColumn);
      const [tempreorderItem] = temparray.splice(result.source.index, 1);
      temparray.splice(result.destination.index, 0, tempreorderItem);
      addRemoveTableDataColumn = temparray;
      dataForHideShowList(temparray);

      const items = Array.from(dataColumnDetails);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      dataColumnDetails = items;
      sendDataToParent(items);
    }
  };

  const handleAdvanceSearchPopup = () => {
    setShowHideAdvanceSearch(true);
  };
  // let transformedArray;
  // if (addRemoveTableDataColumn.length) {
  //   transformedArray = addRemoveTableDataColumn.map((name) => {
  //     let nameParts = name.split("_");
  //     let transformedName = nameParts.map((part) => {
  //       return part.charAt(0).toUpperCase() + part.slice(1);
  //     });
  //     return transformedName.join(" ");
  //   });
  // }
  // // end new code -3
  // if (transformedArray && transformedArray.length) {
  //   for (let i = 0; i < transformedArray.length; i++) {
  //     // for product hierarchy
  //     if (transformedArray[i] === "ProductHierarchyLevel1") {
  //       transformedArray[i] = hierarchyConfiguration["productHierarchyLevel1"];
  //     }
  //     if (transformedArray[i] === "ProductHierarchyLevel2") {
  //       transformedArray[i] = hierarchyConfiguration["productHierarchyLevel2"];
  //     }
  //     if (transformedArray[i] === "ProductHierarchyLevel3") {
  //       transformedArray[i] = hierarchyConfiguration["productHierarchyLevel3"];
  //     }
  //     if (transformedArray[i] === "ProductHierarchyLevel4") {
  //       transformedArray[i] = hierarchyConfiguration["productHierarchyLevel4"];
  //     }
  //     if (transformedArray[i] === "DescriptionproductHierarchyLevel1") {
  //       transformedArray[i] = hierarchyConfiguration["productHierarchyLevel1"] + " " + "Description";
  //     }
  //     if (transformedArray[i] === "DescriptionproductHierarchyLevel2") {
  //       transformedArray[i] = hierarchyConfiguration["productHierarchyLevel2"] + "Description";
  //     }
  //     if (transformedArray[i] === "DescriptionproductHierarchyLevel3") {
  //       transformedArray[i] = hierarchyConfiguration["productHierarchyLevel3"] + "Description";
  //     }
  //     if (transformedArray[i] === "DescriptionproductHierarchyLevel4") {
  //       transformedArray[i] = hierarchyConfiguration["productHierarchyLevel4"] + "Description";
  //     }
  //     // for location hierarchy
  //     if (transformedArray[i] === "LocationHierarchyLevel1") {
  //       transformedArray[i] = hierarchyConfiguration["locationHierarchyLevel1"];
  //     }
  //     if (transformedArray[i] === "LocationHierarchyLevel2") {
  //       transformedArray[i] = hierarchyConfiguration["locationHierarchyLevel2"];
  //     }
  //     if (transformedArray[i] === "LocationHierarchyLevel3") {
  //       transformedArray[i] = hierarchyConfiguration["locationHierarchyLevel3"];
  //     }
  //     if (transformedArray[i] === "LocationHierarchyLevel4") {
  //       transformedArray[i] = hierarchyConfiguration["locationHierarchyLevel4"];
  //     }
  //     //  for personnel hierarchy
  //     if (transformedArray[i] === "DemandPlannerHierarchyLevel1") {
  //       transformedArray[i] = hierarchyConfiguration["demandPlannerHierarchyLevel1"];
  //     }
  //     if (transformedArray[i] === "DemandPlannerHierarchyLevel2") {
  //       transformedArray[i] = hierarchyConfiguration["demandPlannerHierarchyLevel2"];
  //     }
  //     if (transformedArray[i] === "DemandPlannerHierarchyLevel3") {
  //       transformedArray[i] = hierarchyConfiguration["demandPlannerHierarchyLevel3"];
  //     }
  //     if (transformedArray[i] === "DemandPlannerHierarchyLevel4") {
  //       transformedArray[i] = hierarchyConfiguration["demandPlannerHierarchyLevel4"];
  //     }
  //     if (transformedArray[i] === "SalesmanHierarchyLevel1") {
  //       transformedArray[i] = hierarchyConfigurationExtra["salesmanHierarchyLevel1"];
  //     }
  //     if (transformedArray[i] === "SalesmanHierarchyLevel2") {
  //       transformedArray[i] = hierarchyConfigurationExtra["salesmanHierarchyLevel2"];
  //     }
  //     if (transformedArray[i] === "SalesmanHierarchyLevel3") {
  //       transformedArray[i] = hierarchyConfigurationExtra["salesmanHierarchyLevel3"];
  //     }
  //     if (transformedArray[i] === "SalesmanHierarchyLevel4") {
  //       transformedArray[i] = hierarchyConfigurationExtra["salesmanHierarchyLevel4"];
  //     }
  //   }
  // }

  const handleEditClick = (e) => {
    setAddEditValue("Edit");

    const initialValues = {};
    // if (dataColumnDetails && dataColumnDetails.length) {
    //   dataColumnDetails.forEach((column) => {
    //     initialValues[column] = "";
    //   });
    //   setInputValues(initialValues);
    //   setIsEditModalOpen(true);
    // }
    if (addEditPopupData && addEditPopupData.length) {
      addEditPopupData.forEach((item) => {
        // if (item.isPrimaryKey !== "C") {
        // initialValues[item.beName] = "";
        //      }
        if (Object.keys(flattenedRawData).includes(item.beName)) {
          initialValues[item.beName] = flattenedRawData[item.beName];
        }
        // }
      });

      setInputValues(initialValues);
    }
    setIsEditModalOpen(true);
  };

  const handleAddClick = (e) => {
    const mandatoryFieldRed = document.getElementsByClassName(
      "label.MuiInputLabel-root"
    );
    setAddEditValue("Add");
    setProductAiReplacemtnDate("");
    setSkuSelectTableData([]);
    setPrefilledValueLikeSku(false);
    setLikeSkuId("");
    setSkuCodeFromCheckBox("");
    const initialValues = {};
    // if (dataColumnDetails && dataColumnDetails.length) {
    //   dataColumnDetails.forEach((column) => {
    //     initialValues[column] = "";
    //   });
    //   setInputValues(initialValues);
    //   setIsEditModalOpen(true);
    // }
    if (addEditPopupData && addEditPopupData.length) {
      addEditPopupData.forEach((item) => {
        initialValues[item.beName] = "";
      });
      if (componentNameAddEdit.toLowerCase() === "product") {
        initialValues["product_lifecycle_status"] = "true";
      }
      if (componentNameAddEdit.toLowerCase() === "promomaster") {
        initialValues["promo_status"] = "true";
      }
      if (componentNameAddEdit.toLowerCase() === "promo") {
        initialValues["status"] = "true";
      }
      

      setInputValues(initialValues);
      // setIsEditModalOpen(true);
    }
    setIsEditModalOpen(true);
  };
  const [mandatoryFieldError, setMandatoryFieldError] = useState(false);
  useEffect(() => {
    let setError = inputValues;
    addEditPopupData.map((item, index) => {
      // if(item.isPrimaryKey !=="C" && item.mandatory===true ){
      if (item.mandatory === true) {
        if (setError[item.beName] === "") {
          setMandatoryFieldError(true);
        } else {
          setMandatoryFieldError(false);
        }
      }
    });
  }, [inputValues]);
  const [dataTypeErrors, setDataTypeError] = useState({});
  const [dataTypeErrorBoolean, setDataTypeErrorBoolean] = useState({});

  const validateJSON = (value) => {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return false;
    }
  };
  const validateBoolean = (value) => {
    return value.toLowerCase() === "true" || value.toLowerCase() === "false";
  };
  const handleInputChange = (column, value, dataType, currentIndex, event) => {
    // if(event?.target?.checked===true|| event?.target?.checked===false){
    //   value=event?.target?.checked
    // }
    // const inputElement=document.getElementById(`outlined-size-normal-${index}`)
    // if(inputElement && dataType.toLowerCase() === "json"){
    //   inputElement.addEventListener("focus",()=>{
    //     if(inputElement.value==="Please enter else valid json"){
    //       inputElement.value=""
    //       inputElement.style.border="1px solid gray"
    //     }

    //   })
    //   inputElement.addEventListener("blur",()=>{
    //     try {
    //       if(value&& value.startsWith("{") && value.endsWith("}")){
    //         setInputValues((prevValues) => ({
    //           ...prevValues,
    //           [column]: value,
    //         }));
    //       }else{
    //         inputElement.style.border="1px solid red"
    //       inputElement.value="Please enter else valid json"
    //       }
    //       // JSON.parse(value);

    //     } catch (error) {
    //       inputElement.style.border="1px solid red"
    //       inputElement.value="Please enter catch valid json"
    //     }
    //   })
    // }
    if (dataType.toLowerCase() === "json") {
      const isValidJSON = validateJSON(value);

      setDataTypeError((prevErrors) => ({
        ...prevErrors,
        [column]: !isValidJSON,
      }));
      // if (isValidJSON && currentIndex > 0) {
      //   setInputValues((prevValues) => ({
      //     ...prevValues,
      //     [column]: value,
      //   }));
      // }

      // Only update input values if JSON is valid and it's not the first input field
      // if (isValidJSON && currentIndex > 0) {
      //   setInputValues((prevValues) => ({
      //     ...prevValues,
      //     [currentIndex]: value,
      //   }));
      // } else {
      //   setInputValues((prevValues) => ({
      //     ...prevValues,
      //     [column]: value,
      //   }));
      // }
    } else if (dataType.toLowerCase() === "boolean") {
      if (value === "true") {
        value = true;
      } else if (value === "false") {
        value = false;
      }
      // const isValidBoolean = validateBoolean(value);
      // setDataTypeErrorBoolean((prevErrors) => ({
      //   ...prevErrors,
      //   [column]: !isValidBoolean,
      // }));
      // if (isValidBoolean && currentIndex > 0) {
      //   setInputValues((prevValues) => ({
      //     ...prevValues,
      //     [column]: value,
      //   }));
      // }
    }
    setInputValues((prevValues) => ({
      ...prevValues,
      [column]: value,
    }));

    // // Only update input values if JSON is valid and it's not the first input field
    // if (isValidBoolean && currentIndex > 0) {
    //   setInputValues((prevValues) => ({
    //     ...prevValues,
    //     [currentIndex]: value,
    //   }));
    // } else {
    //   setInputValues((prevValues) => ({
    //     ...prevValues,
    //     [column]: value,
    //   }));
    // }
    // } else {
    //   setInputValues((prevValues) => ({
    //     ...prevValues,
    //     [column]: value,
    //   }));
    // }

    // setInputValues((prevValues) => ({
    //   ...prevValues,
    //   [column]: value,
    // }));
  };
  // addEditPopupData.map((inputField,index)=>{
  //   if(inputField.dataType.toLowerCase()==="json"
  //   && inputValues[inputField.beName]
  //   && inputValues[inputField.beName].startsWith("{")
  //   && inputValues[inputField.beName].endsWith("{") ){

  //   }
  // })
  const handleRemoveClick = () => {
    setShowRemoveConfirmation(true);
  };

  const handleConfirmRemove = () => {
    setShowRemoveConfirmation(false);
  };

  const handleCancelRemove = () => {
    setShowRemoveConfirmation(false);
  };
  // edit

  const user = JSON.parse(localStorage.getItem("user"));
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const permissions = get(user, "permissions", []);
  // const dispatch = useDispatch();
  // const [rowLength, setRowLength] = useState(10);
  // const [pageIndex, setPageIndex] = useState(1);

  // const body = {
  //   pageIndex: pageIndex,
  //   pageSize: rowLength,
  //   sortFieldStringWithASCOrDESC: "",
  //   searchValue: "",
  //   tenantId: tenantId,
  // };

  // edit end
  const [addEditValue, setAddEditValue] = useState("");
  const {
    featureList,
    csvUploadResponseData,
    csvUploadError,
    csvUpload,
    loader,
    addEditTableColumnMessage,
  } = useSelector((state) => state.FeatureReducer);
  const entities = get(featureList, "entities", []);

  const customDataTextField = [
    {
      lavel: "First",
    },
    {
      lavel: "Second",
    },
  ];

  const [activeInactiveBoolean, setActiveInactiveBoolean] = useState(true);

  // like sku search code
  const ProductCustomHeaderConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration"))?.entities
        ?.productCustomHeader
    : null;
  const ProductHierarchyConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration"))?.entities
        ?.productHierarchy
    : null;
  console.log("ProductHierarchyConfigurationnn", ProductHierarchyConfiguration);
  const [likeSkuSearchBody, setLikeSkuSearchBody] = useState({});
  const [likeSkuSearchResponse, setLikeSkuSearchResponse] = useState([]);
  const [copyAddEditValue, setCopyAddEditValue] = useState(null);
  const [skuSelectTableData, setSkuSelectTableData] = useState([]);
  const [autocompleteFocused, setAutocompleteFocused] = useState(false);
  const [likeSkuData, setLikeSkuData] = useState([
    { label: "Advance Search", year: 1996 },
  ]);
  const top100Films = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
    { label: "12 Angry Men", year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: "Pulp Fiction", year: 1994 },
  ];
  const [likeSkuId, setLikeSkuId] = useState("");
  const [skuCodeFromCheckBox, setSkuCodeFromCheckBox] = useState("");
  const [callOnFocusOnce, setCallOnfocusOnce] = useState(false);
  const handleAutocompleteFocus = (event, newValue) => {
    // handleAdvanceFilterLikeSku();
    const { sku_code, channel_name } = inputValues;
    let body = {};
    for (let index = 0; index < Object.keys(inputValues).length; index++) {
      // const element = array[index];
      if (
        Object.keys(inputValues)
          [index].toLowerCase()
          .includes("producthierarchylevel") &&
        !Object.keys(inputValues)
          [index].toLowerCase()
          .includes("descriptionproducthierarchylevel")
      ) {
        body[addEditPopupData[index].feName] =
          inputValues[Object.keys(inputValues)[index]];
      }
    }
    body["sku_code"] = sku_code;
    body["channel_name"] = channel_name;

    // const body = {
    //   sku_code: "skutest", // always new
    //   channel_name: "online", // existing
    //   brand: "brandnew",
    //   category: "catnew",
    //   sub_category: "subcatnew",
    //   // product: "Text_123",
    // };
    if (!callOnFocusOnce) {
      const toastInfoId = toast.info("Searching for sku", {
        autoClose: false,
        closeButton: false,
      });
      getAllLikeSkuAPI(body)
        .then((res) => {
          setCallOnfocusOnce(true);

          const toastInfo = toast.success("Download complete!", {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
            onClose: () => {
              toast.dismiss(toastInfoId);
            },
          });
          setLikeSkuData(res?.data?.responseData);
        })
        .catch((err) => {
          toast.dismiss(toastInfoId);
        });
    }
  };
  const [productAiReplacemtnDate, setProductAiReplacemtnDate] = useState("");
  const productAiReplacementDate = (date) => {
    if (productAiReplaceButton) {
      setInputValues((prevValues) => ({
        ...prevValues,
        ["likeSku_lifecycle_end_date"]: date.target.value,
      }));
    }
    setProductAiReplacemtnDate(date.target.value);
  };
  const handleAutocompleteChange = (event, newValue) => {
    // handleAdvanceFilterLikeSku();
    // const body = {
    //   sku_code: "skutest", // always new
    //   channel_name: "online", // existing
    //   brand: "brandnew",
    //   category: "catnew",
    //   sub_category: "subcatnew",
    //   // product: "Text_123",
    // };
    // if (!likeSkuData.length) {
    //   getAllLikeSkuAPI(body)
    //     .then((res) => console.log("likeresponseeee", res))
    //     .catch((err) => console.log(err));
    // }
  };

  const selectSkuOnChangeHandle = (checkOrNot, index, skuId, skuCode) => {
    // setSkuCodeFromCheckBox(skuCode)

    // setLikeSkuId(skuId)

    setSkuCodeFromCheckBox(checkOrNot ? skuCode : ""); // Clear the SKU code if unchecked
    setLikeSkuId(checkOrNot ? skuId : ""); // Clear the SKU ID if unchecked

    // Update the state to deselect other checkboxes
    const updatedData = skuSelectTableData.map((skus, i) => {
      if (i !== index) {
        return { ...skus, isChecked: false }; // Set isChecked to false for other checkboxes
      }
      return { ...skus, isChecked: checkOrNot }; // Update the current checkbox state
    });

    // Update the state with the modified data
    setSkuSelectTableData(updatedData);
  };
  const handleLikeSearch = () => {
    const { sku_code, channel_name } = inputValues;
    // setLikeSkuSearchBody((prevValues) => ({
    //   ...prevValues,
    //   ["sku_code"]: sku_code,
    //   ["channel_name"]: channel_name,
    // }));
    const toastInfoId = toast.info("Searching SKUs", {
      autoClose: false,
      closeButton: false,
    });
    let body = likeSkuSearchBody;
    // body["sku_code"] = "sku1";
    // body["channel_name"] = "online";
    body["sku_code"] = sku_code;
    body["channel_name"] = channel_name;
    // setLikeSkuSearchResponse([])

    getLikeSkuSearchAdvanceFilterAPI(body)
      .then((res) => {
        if (
          res?.data?.responseData?.length ||
          res?.data?.responseData?.length === 0
        ) {
          setSkuSelectTableData(res?.data?.responseData);
          const toastInfo = toast.success("Download complete!", {
            autoClose: 2000,
            closeOnClick: false,
            draggable: false,
            onClose: () => {
              toast.dismiss(toastInfoId);
            },
          });
        }
      })
      .catch((error) => {
        toast.error(error?.message);
        toast.dismiss(toastInfoId);
      });
  };
  const handleLikeSkuOnChange = (e, column) => {
    const value = e.target.value;
    setLikeSkuSearchBody((prevValues) => ({
      ...prevValues,
      [column]: value,
    }));
  };

  const [isLikeSearchOpen, setIsLikeSearchOpen] = useState(false);
  const [prefilledValueLikeSku, setPrefilledValueLikeSku] = useState(false);
  const handleLikeSearchPopup = () => {
    setIsLikeSearchOpen(false);
  };
  const handleAdvanceFilterLikeSku = () => {
    // setCopyAddEditValue(...inputValues);
    setIsLikeSearchOpen(true);
    setIsEditModalOpen(false);
    setSkuSelectTableData([]);
    setPrefilledValueLikeSku(true);
  };
  const handleLikeSkuSelect = () => {
    setInputValues((prevValues) => ({
      ...prevValues,
      ["like_sku_code"]: likeSkuId,
    }));
    setIsLikeSearchOpen(false);
    setIsEditModalOpen(true);
  };
  const handleCancelLikeSearchSku = () => {
    setIsLikeSearchOpen(false);
    setLikeSkuId("");
    setIsEditModalOpen(true);
  };

  const handleSaveEdit = () => {
    // if(Object.valu){
    //   return toast.warning("Please fill all the mandatory filled.")
    // }
    // if(addEditValue.toLocaleLowerCase()==="add"){
    //   addEditPopupData.map(item=>{
    //     if(item.mandatory===true ){

    //       console.log("useeffectIn")
    //       if(inputValues[item.beName]===""){
    //         setMandatoryFieldError(true)
    //         return toast.error("Please fill all the mandatory field")
    //         // console.log("useeffectIntrue")
    //       }

    //     }
    //   })
    // }

    // const ids = entities.map((ids) => ids.id);
    const ids = storedFeatureHeader.map((item) => item.isPrimaryKey === "U");
    const id = ids[0];

    if (
      addEditValue.toLowerCase() === "add" &&
      componentNameAddEdit.toLowerCase() === "promo"
    ) {
      let flag = false;
      addEditPopupData.map((item, index) => {
        // if(item.isPrimaryKey !=="C" && item.mandatory===true ){
        if (item.mandatory === true) {
          if (inputValues[item.beName] === "") {
            flag = true;
          }
        }
      });
      if (flag) {
        flag = false;
        return toast.error("Please fill all mandatory field.", {
          style: {
            position: "relative",
            zIndex: "9999999 !important",
          },
        });
      }
      dispatch({ type: POST_ALL_PROMO_TRANSACTION, payload: inputValues });
    }
    if (
      addEditValue.toLowerCase() === "edit" &&
      componentNameAddEdit.toLowerCase() === "promo"
    ) {
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      let convertedValues = {};
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;

          if (
            dataType.toLowerCase() === "localdate" ||
            dataType.toLowerCase() === "datetime" ||
            dataType.toLowerCase() === "date"
          ) {
            if (inputValues[key] === null) continue;
            // if (inputValues[key].includes("GMT")) {
            //   const dateObject = new Date(inputValues[key]);
            //   // Extract year, month, and day components
            //   const year = dateObject.getFullYear();
            //   const month = (dateObject.getMonth() + 1)
            //     .toString()
            //     .padStart(2, "0"); // Months are zero-based
            //   const day = dateObject.getDate().toString().padStart(2, "0");

            //   // Form the resulting date string in "yyyy-mm-dd" format
            //   const formattedDate = `${year}-${month}-${day}`;
            //   convertedValues[key] = formattedDate;
            convertedValues[key] = inputValues[key];
            // } else {
            // }
            // let yyyMMDD=String(new Date (inputValues[key]) )
            // convertedValues[key] = yyyMMDD.split("T")[0]
            // }
            // convertedValues[key] = new Date (inputValues[key])
          } else if (
            dataType.toLowerCase() === "double" ||
            dataType.toLowerCase() === "integer" ||
            dataType.toLowerCase() === "long" ||
            dataType.toLowerCase() === "float"
          ) {
            convertedValues[key] = Number(inputValues[key]) || 0.0;
          } else if (dataType.toLowerCase() === "boolean") {
            convertedValues[key] = Boolean(inputValues[key]);
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }

      convertedValues.id = selectedRowIds[0].id;

      dispatch({
        type: GET_ALL_EDIT_PROMO_TRANSACTION,
        payload: convertedValues,
      });
    }

    if (
      addEditValue.toLowerCase() === "add" &&
      componentNameAddEdit.toLowerCase() === "promomaster"
    ) {
      let flag = false;
      addEditPopupData.map((item, index) => {
        // if(item.isPrimaryKey !=="C" && item.mandatory===true ){
        if (item.mandatory === true) {
          if (inputValues[item.beName] === "") {
            flag = true;
          }
        }
      });
      if (flag) {
        flag = false;
        return toast.error("Please fill all mandatory field.", {
          style: {
            position: "relative",
            zIndex: "9999999 !important",
          },
        });
      }
      dispatch({ type: POST_ALL_PROMO_MASTER, payload: inputValues });
    }
    if (
      addEditValue.toLowerCase() === "edit" &&
      componentNameAddEdit.toLowerCase() === "promomaster"
    ) {
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      let convertedValues = {};
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;

          if (
            dataType.toLowerCase() === "localdate" ||
            dataType.toLowerCase() === "datetime" ||
            dataType.toLowerCase() === "date"
          ) {
            if (inputValues[key] === null) continue;
            // if (inputValues[key].includes("GMT")) {
            //   const dateObject = new Date(inputValues[key]);
            //   // Extract year, month, and day components
            //   const year = dateObject.getFullYear();
            //   const month = (dateObject.getMonth() + 1)
            //     .toString()
            //     .padStart(2, "0"); // Months are zero-based
            //   const day = dateObject.getDate().toString().padStart(2, "0");

            //   // Form the resulting date string in "yyyy-mm-dd" format
            //   const formattedDate = `${year}-${month}-${day}`;
            //   convertedValues[key] = formattedDate;
            convertedValues[key] = inputValues[key];
            // } else {
            // }
            // let yyyMMDD=String(new Date (inputValues[key]) )
            // convertedValues[key] = yyyMMDD.split("T")[0]
            // }
            // convertedValues[key] = new Date (inputValues[key])
          } else if (
            dataType.toLowerCase() === "double" ||
            dataType.toLowerCase() === "integer" ||
            dataType.toLowerCase() === "long" ||
            dataType.toLowerCase() === "float"
          ) {
            convertedValues[key] = Number(inputValues[key]) || 0.0;
          } else if (dataType.toLowerCase() === "boolean") {
            convertedValues[key] = Boolean(inputValues[key]);
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }

      convertedValues.id = selectedRowIds[0].id;

      dispatch({ type: GET_ALL_EDIT_PROMO_MASTER, payload: convertedValues });
    }

    if (
      addEditValue.toLowerCase() === "add" &&
      componentNameAddEdit.toLowerCase() === "feature"
    ) {
      let flag = false;
      addEditPopupData.map((item, index) => {
        // if(item.isPrimaryKey !=="C" && item.mandatory===true ){
        if (item.mandatory === true) {
          if (inputValues[item.beName] === "") {
            flag = true;
          }
        }
      });
      if (flag) {
        flag = false;
        return toast.error("Please fill all mandatory field.", {
          style: {
            position: "relative",
            zIndex: "9999999 !important",
          },
        });
      }
      dispatch({ type: POST_ALL_ADD_FEATURES, payload: inputValues });
    }
    if (
      addEditValue.toLowerCase() === "edit" &&
      componentNameAddEdit.toLowerCase() === "feature"
    ) {
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      let convertedValues = {};
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;

          if (
            dataType.toLowerCase() === "localdate" ||
            dataType.toLowerCase() === "datetime" ||
            dataType.toLowerCase() === "date"
          ) {
            if (inputValues[key] === null) continue;
            // if (inputValues[key].includes("GMT")) {
            //   const dateObject = new Date(inputValues[key]);
            //   // Extract year, month, and day components
            //   const year = dateObject.getFullYear();
            //   const month = (dateObject.getMonth() + 1)
            //     .toString()
            //     .padStart(2, "0"); // Months are zero-based
            //   const day = dateObject.getDate().toString().padStart(2, "0");

            //   // Form the resulting date string in "yyyy-mm-dd" format
            //   const formattedDate = `${year}-${month}-${day}`;
            //   convertedValues[key] = formattedDate;
            convertedValues[key] = inputValues[key];
            // } else {
            // }
            // let yyyMMDD=String(new Date (inputValues[key]) )
            // convertedValues[key] = yyyMMDD.split("T")[0]
            // }
            // convertedValues[key] = new Date (inputValues[key])
          } else if (
            dataType.toLowerCase() === "double" ||
            dataType.toLowerCase() === "integer" ||
            dataType.toLowerCase() === "long" ||
            dataType.toLowerCase() === "float"
          ) {
            convertedValues[key] = Number(inputValues[key]) || 0.0;
          } else if (dataType.toLowerCase() === "boolean") {
            convertedValues[key] = Boolean(inputValues[key]);
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }

      convertedValues.id = selectedRowIds[0].id;

      dispatch({ type: GET_ALL_EDIT_FEATURES, payload: convertedValues });
    }

    if (
      addEditValue.toLowerCase() === "add" &&
      componentNameAddEdit.toLowerCase() === "budget"
    ) {
      let flag = false;
      addEditPopupData.map((item, index) => {
        // if(item.isPrimaryKey !=="C" && item.mandatory===true ){
        if (item.mandatory === true) {
          if (inputValues[item.beName] === "") {
            flag = true;
          }
        }
      });
      if (flag) {
        flag = false;
        return toast.error("Please fill all mandatory field.", {
          style: {
            position: "relative",
            zIndex: "999999",
          },
        });
      }

      dispatch({ type: POST_ALL_ADD_BUDGET, payload: inputValues });
    }
    if (
      addEditValue.toLowerCase() === "edit" &&
      componentNameAddEdit.toLowerCase() === "budget"
    ) {
      let { ...rest } = inputValues;
      rest.id = selectedRowIds[0].id;

      let convertedValues = {};
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;

          if (
            dataType.toLowerCase() === "localdate" ||
            dataType.toLowerCase() === "datetime" ||
            dataType.toLowerCase() === "date"
          ) {
            if (inputValues[key] === null) continue;
            convertedValues[key] = inputValues[key];
          } else if (
            dataType.toLowerCase() === "double" ||
            dataType.toLowerCase() === "integer" ||
            dataType.toLowerCase() === "long" ||
            dataType.toLowerCase() === "float"
          ) {
            convertedValues[key] = inputValues[key];
          } else if (dataType.toLowerCase() === "boolean") {
            convertedValues[key] = Boolean(inputValues[key]);
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }

      convertedValues.id = selectedRowIds[0].id;

      // rest.feature2 = 2;
      // rest.feature3 = 3;
      // rest.descriptionfeature2 = "l";
      // rest.descriptionfeature3 = "o";
      // rest.node_code = "pen1";

      dispatch({ type: GET_ALL_EDIT_BUDGET, payload: convertedValues });
    }
    if (
      addEditValue.toLowerCase() === "add" &&
      componentNameAddEdit.toLowerCase() === "location"
    ) {
      let flag = false;
      addEditPopupData.map((item, index) => {
        // if(item.isPrimaryKey !=="C" && item.mandatory===true ){
        if (item.mandatory === true) {
          if (inputValues[item.beName] === "") {
            flag = true;
            // return
          }
        }
      });
      if (flag) {
        flag = false;
        return toast.error("Please fill all mandatory field.", {
          style: {
            position: "relative",
            zIndex: "999999",
          },
        });
      }
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      const currentDate = new Date();

      // Get the year, month, and day
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
      const day = String(currentDate.getDate()).padStart(2, "0");

      // Form the date string in "yyyy-mm-dd" format
      const formattedDate = `${year}-${month}-${day}`;
      let convertedValues = {};
      convertedValues.created_at = formattedDate;
      convertedValues.created_by = formattedDate;
      convertedValues.modified_at = formattedDate;
      convertedValues.modified_by = formattedDate;

      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;

          if (dataType.toLowerCase() === "json" && inputValues[key]) {
            try {
              JSON.parse(inputValues[key]);
              convertedValues[key] = inputValues[key];
            } catch (error) {
              // JSON is invalid
              // throw new Error("Invalid JSON format");
              // toast.error(`${error.message}`, {});
              flag = true;
            }
            // if(inputValues[key] && inputValues[key].includes("T")){
            // let yyyMMDD = String(new Date(inputValues[key]));
            // }
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }
      if (flag) {
        flag = false;
        return toast.error(`Json is not valid`, {
          style: {
            position: "relative",
            zIndex: "999999",
          },
        });
      }
      dispatch({ type: POST_ALL_ADD_LOCATION, payload: convertedValues });
    }
    if (
      addEditValue.toLowerCase() === "edit" &&
      componentNameAddEdit.toLowerCase() === "location"
    ) {
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      rest.id = selectedRowIds[0].id;

      let convertedValues = {};
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;

          if (
            dataType.toLowerCase() === "localdate" ||
            dataType.toLowerCase() === "datetime" ||
            dataType.toLowerCase() === "date"
          ) {
            if (inputValues[key] === null) continue;
            // if(inputValues[key] && inputValues[key].includes("T")){
            // let yyyMMDD = String(new Date(inputValues[key]));
            convertedValues[key] = inputValues[key];
            // }
          } else if (
            dataType.toLowerCase() === "double" ||
            dataType.toLowerCase() === "integer" ||
            dataType.toLowerCase() === "long" ||
            dataType.toLowerCase() === "float"
          ) {
            convertedValues[key] = Number(inputValues[key]) || 0.0;
          } else if (dataType.toLowerCase() === "boolean") {
            convertedValues[key] = Boolean(inputValues[key]);
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }

      let mappingId = selectedRowIds[0].id;
      convertedValues.node_mapping_id = mappingId;

      dispatch({ type: GET_ALL_EDIT_LOCATION, payload: convertedValues });
    }

    if (
      addEditValue.toLowerCase() === "add" &&
      componentNameAddEdit.toLowerCase() === "product"
    ) {
      let flag = false;
      addEditPopupData.map((item, index) => {
        // if(item.isPrimaryKey !=="C" && item.mandatory===true ){
        if (item.mandatory === true) {
          if (inputValues[item.beName] === "") {
            flag = true;
          }
        }
      });
      if (flag) {
        flag = false;
        return toast.error("Please fill all mandatory field.", {
          style: {
            position: "relative",
            zIndex: "999999",
          },
        });
      }
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      const currentDate = new Date();

      // Get the year, month, and day
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
      const day = String(currentDate.getDate()).padStart(2, "0");

      // Form the date string in "yyyy-mm-dd" format
      const formattedDate = `${year}-${month}-${day}`;
      let convertedValues = {};
      convertedValues.created_at = formattedDate;
      convertedValues.created_by = formattedDate;
      convertedValues.modified_at = formattedDate;
      convertedValues.modified_by = formattedDate;
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;
          if (key === "likeSku_lifecycle_end_date") {
            convertedValues[key] = inputValues[key];
          } else {
            if (dataType.toLowerCase() === "json" && inputValues[key]) {
              try {
                JSON.parse(inputValues[key]);
                convertedValues[key] = inputValues[key];
              } catch (error) {
                flag = true;
                // JSON is invalid
                // throw new Error("Invalid JSON format");
              }
              // if(inputValues[key] && inputValues[key].includes("T")){
              // let yyyMMDD = String(new Date(inputValues[key]));
              // }
            } else {
              convertedValues[key] = inputValues[key];
            }
          }
        }
      }
      if (flag) {
        flag = false;
        return toast.error(`Json is not valid`, {
          style: {
            position: "relative",
            zIndex: "999999",
          },
        });
      }
      // let {

      //   ...rest
      // } = inputValues;
      // // rest.id=id
      // rest.feature1 = parseInt(rest.feature1);
      // rest.feature2 = 2;
      // rest.feature3 = 3;
      // rest.descriptionfeature2 = "l";
      // rest.descriptionfeature3 = "o";

      // rest.node_code = "pen1";
      dispatch({ type: POST_ALL_ADD_PRODUCT, payload: rest });
    }
    if (
      addEditValue.toLowerCase() === "edit" &&
      componentNameAddEdit.toLowerCase() === "product"
    ) {
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      rest.id = selectedRowIds[0].id;

      let convertedValues = {};
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;
          if (key === "likeSku_lifecycle_end_date") {
            convertedValues[key] = inputValues[key];
          } else if (
            dataType.toLowerCase() === "localdate" ||
            dataType.toLowerCase() === "datetime" ||
            dataType.toLowerCase() === "date"
          ) {
            if (inputValues[key] === null) continue;
            convertedValues[key] = inputValues[key];
          } else if (
            dataType.toLowerCase() === "double" ||
            dataType.toLowerCase() === "integer" ||
            dataType.toLowerCase() === "long" ||
            dataType.toLowerCase() === "float"
          ) {
            convertedValues[key] = Number(inputValues[key]) || 0.0;
          } else if (dataType.toLowerCase() === "boolean") {
            convertedValues[key] = Boolean(inputValues[key]);
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }
      let mappingId = selectedRowIds[0].id;
      convertedValues.mapping_id = mappingId;

      dispatch({ type: GET_ALL_EDIT_PRODUCT, payload: convertedValues });
    }
    setAddEditValue("");
    setIsEditModalOpen(false);
  };

  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
    setAddEditValue("");
    setLikeSkuId("");
  };

  const navigate = useNavigate();
  // csv and excel file download
  const filedownloadoptions = FILE_TYPE.map((item) => ({
    label: `${item}`,
    value: item,
  }));

  const handleClear = () => {
    if (isGoButtonClicked) {
      refreshHandler();
      setIsGoButtonClicked(false);
    } else {
      setSearchField("");
    }
  };

  useEffect(() => {
    if (!searchField && isGoButtonClicked) {
      setIsGoButtonClicked(false);
      refreshHandler();
    }
  });
  let addEditPopupData = [];
  let showMandatoryTextOnce = 0;
  let showOptionalTextOnce = 0;

  if (storedFeatureHeader && storedFeatureHeader.length) {
    for (let i = 0; i < storedFeatureHeader.length; i++) {
      if (storedFeatureHeader[i].isPrimaryKey === "C") {
        addEditPopupData.push(storedFeatureHeader[i]);
      }
    }
    for (let i = 0; i < storedFeatureHeader.length; i++) {
      if (
        storedFeatureHeader[i].isPrimaryKey !== "C" &&
        storedFeatureHeader[i].mandatory === true &&
        storedFeatureHeader[i].isPrimaryKey !== "U"
      ) {
        addEditPopupData.push(storedFeatureHeader[i]);
      }
    }
    for (let i = 0; i < storedFeatureHeader.length; i++) {
      if (
        storedFeatureHeader[i].isPrimaryKey !== "C" &&
        storedFeatureHeader[i].mandatory === false &&
        storedFeatureHeader[i].isPrimaryKey !== "U"
      ) {
        addEditPopupData.push(storedFeatureHeader[i]);
      }
    }
    // storedFeatureHeader.sort((itemA, itemB) => {
    //   if (itemA.isPrimaryKey === "C") return -1; // itemA comes first
    //   if (itemB.isPrimaryKey === "C") return 1; // itemB comes first
    //   return 0; // No change in order
    // });
  }
  if (addEditPopupData && addEditPopupData.length) {
    for (let i = 0; i < addEditPopupData.length; i++) {}
    for (let i = 0; i < addEditPopupData.length; i++) {
      if (
        addEditPopupData[i].isPrimaryKey !== "C" &&
        addEditPopupData[i].mandatory === true &&
        addEditPopupData[i].isPrimaryKey !== "U"
      ) {
        if (showMandatoryTextOnce === 0) {
          showMandatoryTextOnce = i;
        }
      }
    }
    for (let i = 0; i < addEditPopupData.length; i++) {
      if (
        addEditPopupData[i].isPrimaryKey !== "C" &&
        addEditPopupData[i].mandatory === false &&
        addEditPopupData[i].isPrimaryKey !== "U"
      ) {
        if (showOptionalTextOnce === 0) {
          showOptionalTextOnce = i;
        }
      }
    }
    // storedFeatureHeader.sort((itemA, itemB) => {
    //   if (itemA.isPrimaryKey === "C") return -1; // itemA comes first
    //   if (itemB.isPrimaryKey === "C") return 1; // itemB comes first
    //   return 0; // No change in order
    // });
  }

  // if (dataColumnDetails && dataColumnDetails.length) {
  //   dataColumnDetails.sort((nameA, nameB) => {
  //     const objA = storedFeatureHeader.find((item) => item.name === nameA);
  //     const objB = storedFeatureHeader.find((item) => item.name === nameB);

  //     if (objA && objB) {
  //       return objA.name.localeCompare(objB.name);
  //     } else {
  //       // Handle the case where a name in arr is not found in obj
  //       return 0;
  //     }
  //   });
  // }
  const handleAddEditPopupClose = () => {
    // isEditModalOpen
    setIsEditModalOpen(false);
  };
  // const downloadfilefunction = (e) => {
  //   if (e === "CSV Download") {
  //     return dataList?.length > 0 && downloadUrl
  //       ? downloadCsvApi({
  //           url: downloadUrl,
  //           fileName: downloadName,
  //           entityName: entityName,
  //         })
  //       : false;
  //   } else {
  //     const fileType = downloadUrl.replace(/file_type=csv/g, "file_type=excel");
  //     const changedfilename = downloadName.replace(/csv/g, "xlsx");
  //     return dataList?.length > 0 && fileType
  //       ? downloadCsvApi({
  //           url: fileType,
  //           fileName: changedfilename,
  //           entityName: entityName,
  //         })
  //       : false;
  //   }
  // };
  const [previousFieldFilled, setPreviousFieldFilled] = useState(true);

  const [isListOpen, setListOpen] = useState(true);
  const [isListOpenMandatory, setisListOpenMandatory] = useState(true);
  const [isListOpenCustom, setisListOpenCustom] = useState(true);
  const [isListOpenProductAi, setisListOpenProductAi] = useState(true);
  const [productAiReplaceButton, setProductAiReplaceButton] = useState(false);
  const handleProductAiFieldToggle = () => {
    setisListOpenProductAi(!isListOpenProductAi);
  };

  const handleSyncForecast = () => {
    forecastSyncAPI(snopId, syncConfiguration?.sync)
      .then((res) => {
        alert(`${res?.data?.responseCode}`);
      })
      .catch((err) => {});
  };
  const toggleList = () => {
    setListOpen(!isListOpen);
  };
  const likeSkuCodeSearchData = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
    { label: "12 Angry Men", year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: "Pulp Fiction", year: 1994 },
    // { label: "Some", year: 1996 },
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
    { label: "12 Angry Men", year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: "Pulp Fiction", year: 1994 },
    { label: "Advance Filter", year: 1996 },
  ];
  const handleCustomFieldToggle = () => {
    setisListOpenCustom(!isListOpenCustom);
  };
  const toggleListMandatory = () => {
    setisListOpenMandatory(!isListOpenMandatory);
  };

  // advance search
  const [advanceSearchTableOperators, setAdvanceSearchTableOperators] =
    useState({});
  const advanceSearchTableColumn = JSON.parse(
    localStorage.getItem("featureHeader")
  );
  const handleTableColumn = (id, event) => {
    let value = event.target.value;
    let dataType = advanceSearchTableColumn
      .filter((data) => data.feName === value)[0]
      .dataType.toLowerCase();

    const filteredObject = operatorsDataType.find(
      (obj) => Object.keys(obj)[0] === dataType
    );
    // filteredObject[dataType].push(dataType)

    // setAdvanceFilterInputDataType(prevValue=>[...prevValue,filteredObject[dataType].inputFieldType])

    setAdvanceSearchTableOperators(filteredObject[dataType]);

    // Display the result
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        const newArray = [...advanceFilterInputDataType];

        // Set the value at the specific index in the new array
        newArray[id - 1] = filteredObject[dataType].inputFieldType;
        console.log(
          "advanceFilterInputDataType ---->",
          advanceFilterInputDataType
        );
        setAdvanceFilterInputDataType(newArray);
        return { ...row, columnName: event.target.value };
      }
      return row;
    });

    setRows(updatedRows);
  };
  return (
    <>
      {/* {showHideAdvanceSearch ? ( */}
      <Modal
        open={showHideAdvanceSearch}
        // onClose={handleAddEditPopupClose}
        onClose={false}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        style={{ position: "relative !important", zIndex: 999 }}
        disableBackdropClick
      >
        <div className="advance-search-popup-container">
          <div className="advance-search-table-container">
            <table className="advance-search-table">
              <thead>
                <tr>
                  <th>Column Name</th>
                  <th>Operator</th>
                  <th>Value</th>
                  <th>Remove Column</th>
                </tr>
              </thead>
              <tbody>
                {rows?.map((row, index) => (
                  <tr key={row.id}>
                    <td>
                      {/* <DropDownList
                        // data={advanceSearchTableCColumnNames}
                        data={advanceSearchTableColumn?.map(
                          (colName) => colName.feName
                        )}
                        //  data={[
                        //   { text: 'Column 1', value: 'column1' },
                        //   { text: 'Column 2', value: 'column2' },
                        //   { text: 'Column 3', value: 'column3' },
                        //   // Add more objects as needed
                        // ]}
                        // style={{position:"relative !important",zIndex:"1000 !important"}}
                        width="300px"
                        onChange={(event) => handleTableColumn(row.id, event)}
                        value={row.columnName}
                        placeholder="Select value"
                        className="advance_search_table_dropdown"
                      /> */}
                      <select
                        style={{
                          width: "100%",
                          textAlign: "center",
                          padding: "3px",
                          border: "none",
                          outline: "none",
                          border: "1px solid lightgray",
                          color: "#444",
                          fontWeight: "400",
                        }}
                        onChange={(event) => handleTableColumn(row.id, event)}
                        // placeholder="Select Value"
                        // placeholder="Select Value"

                        // value={row.columnName}
                      >
                        <option value="" disabled selected hidden>
                          {row.columnName === "Select Value"
                            ? "Select Value"
                            : row.columnName}
                        </option>
                        {advanceSearchTableColumn?.map((column) => {
                          return (
                            <>
                              <option value={column.feName}>
                                {column.feName}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </td>
                    <td>
                      {/* <DropDownList
                        // data={operators}
                        // data={operatiors}
                        data={advanceSearchTableOperators}
                        onChange={(event) => handleOperators(row.id, event)}
                        value={row.operator}
                        className="advance_search_table_dropdown"
                        // style={{position:"relative !important",zIndex:"1000 !important"}}
                      /> */}
                      <select
                        style={{
                          width: "100%",
                          textAlign: "center",
                          padding: "3px",
                          border: "none",
                          outline: "none",
                          border: "1px solid lightgray",
                          color: "#444",
                          fontWeight: "400",
                        }}
                        onChange={(event) => handleOperators(row.id, event)}
                        // value={row.operator}
                      >
                        {/* <option value="Select Type">
                        Select Type
                        </option> */}

                        <option value="" disabled selected hidden>
                          {row.operator === "Select Type"
                            ? "Select Type"
                            : row.operator}
                        </option>
                        {advanceSearchTableOperators.operatorList?.map(
                          (operator, index) => {
                            return (
                              <>
                                <option id={index} value={operator}>
                                  {operator}
                                </option>
                              </>
                            );
                          }
                        )}
                      </select>
                    </td>
                    {
                      row.operator == "Date Range" ? (
                        <td>
                          <RangePicker
                            // style={{position:"relative", zIndex:99999 }}

                            editable={false}
                            disabledDate={(date) => isAfter(date, new Date())}
                            // onChange={(event) =>
                            //   handleInputChangeOne(row.id, event)
                            // }
                            onChange={(event) =>
                              handleAdvanceSearchDateRange(row.id, event)
                            }
                          />
                        </td>
                      ) : row.operator === "Is null" ||
                        row.operator === "Is not null" ? (
                        <td>
                          <Input disabled={true} />
                        </td>
                      ) : (
                        <td>
                          <input
                            // type={advanceSearchTableColumn[index]}

                            style={{
                              border: "none",
                              width: "100%",
                              margin: "3px",
                              outline: "none",
                              border: "1px solid lightgray",
                              padding: "5px",
                              borderRadius: "2px",
                            }}
                            onChange={(event) =>
                              handleInputChangeOne(row.id, event)
                            }
                            value={row.value}
                            placeholder={"Enter Value"}
                            type={advanceFilterInputDataType[index]}
                            // type={operatorsDataType[advanceFilterInputDataType].inputFieldType}
                            // onInput={(event) => {
                            //   const numericInput = event.target.value.replace(
                            //     /[^0-9]/g,
                            //     ""
                            //   );
                            //   event.target.value = numericInput;
                            //   handleInputChangeOne(row.id, {
                            //     target: { value: numericInput },
                            //   });
                            // }}
                          />
                        </td>
                      )
                      //  :
                      // (
                      //   <td>
                      //     <Input
                      //       onChange={(event) =>
                      //         handleInputChangeOne(row.id, event)
                      //       }
                      //       value={row.value}
                      //       placeholder={"Enter Value"}
                      //     />
                      //   </td>
                      // )
                    }
                    <td>
                      <button onClick={() => removeRow(row.id)}>
                        <RemoveIcon />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="advance-search-button-container">
            <div className="advance-search-button-container-left">
              <Button
                buttonType={"primary"}
                disabled={disabled ?? false}
                className="ml-5"
                label={"Add Column"}
                onClick={addRow}
                type="submit"
              />
            </div>
            <div className="advance-search-button-container-right">
              <Button
                buttonType={"primary"}
                disabled={disabled ?? false}
                className="ml-5"
                label={"Clear Filter"}
                type="submit"
                onClick={handleClearFilter}
              />
              <Button
                buttonType={"primary"}
                disabled={disabled ?? false}
                className="ml-5"
                label={"OK"}
                type="submit"
                onClick={handleFilteredData}
                // onClick={() => { setShowHideAdvanceSearch(false) }}
              />
              <Button
                buttonType={"primary"}
                disabled={disabled ?? false}
                className="ml-5"
                label={"Cancel"}
                type="submit"
                // onClick={handleAdvanceSearchPopup}
                onClick={() => {
                  setShowHideAdvanceSearch(false);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
      {/* // ) : null} */}
      {/* end new code - 4 */}

      <div className="client-listing-toolbar-left">
        <div className="client-listing-toolbar-left-show">
          <InputSelect
            name="show"
            placeholder="Show 10 Rows"
            data={showMoreOptions ?? []}
            value={rowLength}
            onChange={(e) => {
              setRowLength(e.target.value);
              setPageIndex(1);
            }}
            disabled={disabled ?? false}
          />
        </div>

        <Button
          buttonType={"refresh"}
          disabled={disabled}
          className="ml-15"
          onClick={refreshHandler}
        />
        {componentNameAddEdit &&
          addEditPermission.includes(componentNameAddEdit.toLowerCase()) && (
            <>
              <Button
                // disabled={!(selectedRowIds?.length === 1)}
                className="ml-15"
                style={{ backgroundColor: "#E94F1C" }}
                label={
                  <>
                    <span className="mr-5">Add</span>
                    <FontAwesomeIcon icon={faPlus} />
                  </>
                }
                type="submit"
                onClick={handleAddClick}
              />

              <Button
                disabled={!(selectedRowIds?.length === 1)}
                className="ml-15"
                label={
                  <>
                    <span className="mr-5">Edit</span>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </>
                }
                type="submit"
                onClick={handleEditClick}
              />
            </>
          )}

        {/* like sku model start_with */}
        <Modal
          open={isLikeSearchOpen}
          onClose={handleLikeSearchPopup}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{ position: "relative !important", zIndex: "99 !important" }}
        >
          <div className="edit-confirmation-popup">
            <AppBar
              // position="static"
              style={{
                position: "sticky",
                top: 0,
                width: "100%",
                backgroundColor: "#efefef",
                color: "black",
              }}
            >
              <Toolbar>
                {/* {addEditValue} {componentNameAddEdit}
                 */}
                Like Search Sku
              </Toolbar>
            </AppBar>
            <div className="add_edit_popup_container">
              <div className="edit-popup-content">
                <div className="input-fields-container">
                  {/* {dataColumnDetails?.map((column, indexOouter) => ( */}
                  <Box
                    // key={indexOouter}
                    // component="form"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "100%",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    {typeof ProductCustomHeaderConfiguration?.productCustomHeaderCount ==
                      "number" &&
                      ProductCustomHeaderConfiguration?.productCustomHeaderCount >
                        0 &&
                      Object.keys(ProductCustomHeaderConfiguration)?.map(
                        (field, index) => {
                          return (
                            <>
                              {field.includes("header") && (
                                <TextField
                                  type={"text"}
                                  // placeholder={`Data Type : ${header.dataType}`}
                                  onChange={(e) => {
                                    handleLikeSkuOnChange(
                                      e,
                                      ProductCustomHeaderConfiguration[field]
                                    );
                                  }}
                                  label={
                                    ProductCustomHeaderConfiguration[field]
                                  }
                                  // style={{
                                  //   width: "100%",
                                  //   border:
                                  //     dataTypeErrors[header.beName] ||
                                  //     dataTypeErrorBoolean[header.beName]
                                  //       ? "1px solid red"
                                  //       : "",
                                  // }}
                                />
                              )}
                            </>
                          );
                        }
                      )}
                    {typeof ProductHierarchyConfiguration?.productHierarchyNoOfLevels ==
                      "number" &&
                      ProductHierarchyConfiguration?.productHierarchyNoOfLevels >
                        0 &&
                      Object.keys(ProductHierarchyConfiguration)?.map(
                        (field, index) => {
                          return (
                            <>
                              {field.includes("productHierarchyLevel") && (
                                <TextField
                                  onChange={(e) => {
                                    handleLikeSkuOnChange(
                                      e,
                                      ProductHierarchyConfiguration[field]
                                    );
                                  }}
                                  type={"text"}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // placeholder={`Data Type : ${header.dataType}`}
                                  label={ProductHierarchyConfiguration[field]}
                                  // style={{
                                  //   width: "100%",
                                  //   border:
                                  //     dataTypeErrors[header.beName] ||
                                  //     dataTypeErrorBoolean[header.beName]
                                  //       ? "1px solid red"
                                  //       : "",
                                  // }}
                                />
                              )}
                            </>
                          );
                        }
                      )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    {" "}
                    <button
                      style={{
                        backgroundColor: "#ff4500",
                        color: "white",
                        fontSize: "12px",
                        padding: "3px 5px",
                        borderRadius: "10px",
                        fontWeight: "400",
                      }}
                      onClick={handleLikeSearch}
                    >
                      Search
                    </button>
                  </Box>
                  <Box
                    // key={indexOouter}
                    // component="form"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "100%",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    {skuSelectTableData && skuSelectTableData.length ? (
                      <table className="select_sku_table_checkbox">
                        <thead>
                          <tr>
                            <th></th>
                            <th>SKU Code</th>
                            <th>SKU Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {skuSelectTableData &&
                            skuSelectTableData.length &&
                            skuSelectTableData.map((skus, index) => {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={skus.isChecked}
                                        onChange={(e) => {
                                          selectSkuOnChangeHandle(
                                            e.target.checked,
                                            index,
                                            skus.skuId,
                                            skus.skuCode
                                          );
                                        }}
                                      />
                                    </td>
                                    <td>{skus.skuCode}</td>
                                    <td>{skus.skuName}</td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                    ) : (
                      <p style={{ color: "red" }}>No SKU Data</p>
                    )}
                  </Box>
                </div>
              </div>
              <div className="add_edit_button">
                <button
                  style={{ backgroundColor: "#ff4500" }}
                  size="small"
                  variant="contained"
                  onClick={handleLikeSkuSelect}
                  // disabled={!mandatoryFieldError}
                  disabled={skuCodeFromCheckBox.length ? false : true}
                >
                  Select
                </button>

                <button
                  size="small"
                  variant="contained"
                  onClick={handleCancelLikeSearchSku}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {/* like sku model end */}

        <Modal
          open={isEditModalOpen}
          onClose={handleAddEditPopupClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{ position: "relative !important", zIndex: "99 !important" }}
        >
          <div className="edit-confirmation-popup">
            <AppBar
              // position="static"
              style={{
                position: "sticky",
                top: 0,
                width: "100%",
                backgroundColor: "#efefef",
                color: "black",
              }}
            >
              <Toolbar>
              {addEditValue} {componentNameAddEdit === "promo" ? "Promotion Transactions" 
                            : componentNameAddEdit === "promomaster"? "Promotion" 
                            : componentNameAddEdit}
              </Toolbar>
            </AppBar>
            <div className="add_edit_popup_container">
              <div className="edit-popup-content">
                <div className="input-fields-container">
                  {/* {dataColumnDetails?.map((column, indexOouter) => ( */}
                  <Box
                    // key={indexOouter}
                    // component="form"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "100%",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    {addEditPopupData?.length > 0 &&
                      addEditPopupData?.map((header, index) => {
                        return (
                          <>
                            {header?.isPrimaryKey === "C" ? (
                              // column === header.beName
                              // dataColumnDetails.includes(header.beName)
                              <>
                                {header.feName === "Product Hierarchy Level" &&
                                componentNameAddEdit === "promo" ? (
                                  <>
                                    <label
                                      htmlFor=""
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "8px",
                                        color: "gray",
                                      }}
                                    >
                                      {header.feName}{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select
                                      disabled={
                                        addEditValue.toLowerCase() === "edit"
                                          ? true
                                          : false
                                      }
                                      defaultValue={
                                        addEditValue.toLowerCase() === "edit"
                                          ? flattenedRawData[header.beName] ??
                                            ""
                                          : // : inputValues[header.beName]=Object.values(productHierarchyConfiguration).filter(item=>typeof item!="number")[0]
                                            null
                                      }
                                      placeholder="Select Value"
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        padding: "3px",
                                        border: "none",
                                        outline: "none",
                                        border: "1px solid lightgray",
                                        color: "#444",
                                        fontWeight: "400",
                                      }}
                                      onChange={(e) => {
                                        handleInputChange(
                                          header.beName,
                                          e.target.value,
                                          header.dataType,
                                          index,
                                          e
                                        );
                                      }}
                                      name="cars"
                                      id="cars"
                                    >
                                      <option value="" disabled selected hidden>
                                        Select Value
                                      </option>
                                      {Object.values(
                                        productHierarchyConfiguration
                                      )
                                        .filter(
                                          (item) => typeof item != "number"
                                        )
                                        ?.map((item) => {
                                          return (
                                            <>
                                              <option value={item}>
                                                {item}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </select>
                                  </>
                                ) : header.feName ===
                                    "Location Hierarchy Level" &&
                                  componentNameAddEdit === "promo" ? (
                                  <>
                                    <label
                                      htmlFor=""
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "8px",
                                        color: "gray",
                                      }}
                                    >
                                      {header.feName}{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <select
                                      defaultValue={
                                        addEditValue.toLowerCase() === "edit"
                                          ? flattenedRawData[header.beName] ??
                                            ""
                                          : // :inputValues[header.beName]=Object.values(locationHierarchyConfiguration).filter(item=>typeof item!="number")[0]
                                            null
                                      }
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        padding: "3px",
                                        border: "none",
                                        outline: "none",
                                        border: "1px solid lightgray",
                                        color: "#444",
                                        fontWeight: "400",
                                      }}
                                      placeholder="Select Value"
                                      onChange={(e) => {
                                        handleInputChange(
                                          header.beName,
                                          e.target.value,
                                          header.dataType,
                                          index,
                                          e
                                        );
                                      }}
                                      name="cars"
                                      id="cars"
                                    >
                                      <option value="" disabled selected hidden>
                                        Select Value
                                      </option>
                                      {Object.values(
                                        locationHierarchyConfiguration
                                      )
                                        .filter(
                                          (item) => typeof item != "number"
                                        )
                                        ?.map((item) => {
                                          return (
                                            <>
                                              <option value={item}>
                                                {item}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </select>
                                  </>
                                ) : header.feName === "Promo Name" &&
                                  componentNameAddEdit === "promo" ? (
                                  <>
                                    <label
                                      htmlFor=""
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "8px",
                                        color: "gray",
                                      }}
                                    >
                                      {header.feName}{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <select
                                      disabled={
                                        addEditValue.toLowerCase() === "edit"
                                          ? true
                                          : false
                                      }
                                      defaultValue={
                                        addEditValue.toLowerCase() === "edit"
                                          ? flattenedRawData[header.beName] ??
                                            ""
                                          : // : inputValues[header.beName]=true
                                            // :inputValues[header.beName]=promoNamesList[0].promoName
                                            null
                                      }
                                      style={{
                                        width: "100%",
                                        cursor:
                                          addEditValue.toLowerCase() === "edit"
                                            ? "not-allowed"
                                            : "pointer",
                                        textAlign: "center",
                                        padding: "3px",
                                        border: "none",
                                        outline: "none",
                                        border: "1px solid lightgray",
                                        color: "#444",
                                        fontWeight: "400",
                                      }}
                                      placeholder="Select Value"
                                      onChange={(e) => {
                                        handleInputChange(
                                          header.beName,
                                          e.target.value,
                                          header.dataType,
                                          index,
                                          e
                                        );
                                      }}
                                      name="cars"
                                      id="cars"
                                    >
                                      <option value="" disabled selected hidden>
                                        Select Value
                                      </option>
                                      {promoNamesList &&
                                        promoNamesList.length &&
                                        promoNamesList?.map((item) => {
                                          return (
                                            <>
                                              <option value={item.promoName}>
                                                {item.promoName}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </select>
                                  </>
                                ) : (
                                  <TextField
                                    width={200}
                                    style={{ width: "100%" }}
                                    // label={header.feName}
                                    label={
                                      <span>
                                        {header.feName}{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </span>
                                    }
                                    disabled={
                                      addEditValue === "Edit" ? true : false
                                    }
                                    placeholder={`Data Type : ${header.dataType}`}
                                    type={
                                      // header.dataType === "LocalDate"
                                      //   ? "date"
                                      //   : "text"
                                      header.dataType.toLowerCase() ===
                                        "localdate" ||
                                      header.dataType.toLowerCase() ===
                                        "datetime" ||
                                      header.dataType.toLowerCase() === "date"
                                        ? "date"
                                        : header.dataType.toLowerCase() ===
                                            "string" ||
                                          header.dataType.toLowerCase() ===
                                            "json"
                                        ? "text"
                                        : header.dataType.toLowerCase() ===
                                            "double" ||
                                          header.dataType.toLowerCase() ===
                                            "integer" ||
                                          header.dataType.toLowerCase() ===
                                            "long" ||
                                          header.dataType.toLowerCase() ===
                                            "float"
                                        ? "number"
                                        : "text"
                                    }
                                    // required
                                    id={`outlined-size-normal-${index}`}
                                    // defaultValue={
                                    //   flattenedRawData[header.beName] ?? ""
                                    // }
                                    // defaultValue={
                                    //   addEditValue.toLowerCase() === "edit"
                                    //     ? flattenedRawData[header.beName] ?? ""
                                    //     : null
                                    // }
                                    defaultValue={
                                      addEditValue.toLowerCase() === "edit"
                                        ? flattenedRawData[header.beName] ?? ""
                                        : prefilledValueLikeSku
                                        ? inputValues[header.beName]
                                        : null
                                    }
                                    // skuCodeFromCheckBox
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    editable={
                                      header?.isPrimaryKey === "C" &&
                                      // column === header.beName &&
                                      // dataColumnDetails.includes(header.beName) &&

                                      storedFeatureHeader?.isEditUi === true
                                    }
                                    onChange={(e) => {
                                      const enteredValue = e.target.value;
                                      const defaultDataType =
                                        flattenedRawData[header.beName];
                                      handleInputChange(
                                        header.beName,
                                        e.target.value,
                                        header.dataType,
                                        index,
                                        e
                                      );

                                      // Validate data type when the user enters data
                                      let isValidDataType = true;

                                      if (defaultDataType === "number") {
                                        // If the expected data type is number, check if the entered value is a valid number
                                        isValidDataType = !isNaN(
                                          Number(enteredValue)
                                        );
                                      }

                                      // Update error state for the specific column
                                      setDataTypeErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [header.beName]: !isValidDataType,
                                      }));
                                    }}
                                    // editable={
                                    //   storedFeatureHeader?.beName === column &&
                                    //   storedFeatureHeader?.isEditUi === true
                                    // }
                                  />
                                )}
                              </>
                            ) : null}

                            {header?.mandatory === true &&
                            // column === header.beName &&
                            // dataColumnDetails.includes(header.beName) &&
                            header?.isPrimaryKey !== "U" &&
                            header?.isPrimaryKey !== "C" ? (
                              <>
                                {showMandatoryTextOnce === index ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div style={{ color: "gray" }}>
                                      Other mandatory fields
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100px",
                                          margin: "0px 5px",
                                          height: "1.3px",
                                          backgroundColor: "gray",
                                        }}
                                      ></div>

                                      <div>
                                        {isListOpenMandatory ? (
                                          <span
                                            onClick={toggleListMandatory}
                                            style={{
                                              height: "10px",
                                              width: "10px",
                                              backgroundColor: "gray",
                                              borderRadius: "50%",
                                            }}
                                          >
                                            <ExpandMoreIcon />
                                          </span>
                                        ) : (
                                          <span
                                            style={{
                                              height: "10px",
                                              width: "10px",
                                              backgroundColor: "gray",
                                              borderRadius: "50%",
                                            }}
                                            onClick={toggleListMandatory}
                                          >
                                            <ExpandLessIcon />
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                <Collapse
                                  style={{
                                    display: isListOpenMandatory
                                      ? "block"
                                      : "none",
                                  }}
                                  in={isListOpenMandatory}
                                  className="show_hide_mandatory_field"
                                >
                                  {header.feName ===
                                    "Product Hierarchy Level" &&
                                  componentNameAddEdit === "promo" ? (
                                    <>
                                      <label
                                        htmlFor=""
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "8px",
                                          color: "gray",
                                        }}
                                      >
                                        {header.feName}{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <select
                                        disabled={
                                          addEditValue.toLowerCase() === "edit"
                                            ? true
                                            : false
                                        }
                                        defaultValue={
                                          addEditValue.toLowerCase() === "edit"
                                            ? flattenedRawData[header.beName] ??
                                              ""
                                            : // : inputValues[header.beName]=Object.values(productHierarchyConfiguration).filter(item=>typeof item!="number")[0]
                                              null
                                        }
                                        placeholder="Select Value"
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          padding: "3px",
                                          border: "none",
                                          outline: "none",
                                          border: "1px solid lightgray",
                                          color: "#444",
                                          fontWeight: "400",
                                        }}
                                        onChange={(e) => {
                                          handleInputChange(
                                            header.beName,
                                            e.target.value,
                                            header.dataType,
                                            index,
                                            e
                                          );
                                        }}
                                        name="cars"
                                        id="cars"
                                      >
                                        <option
                                          value=""
                                          disabled
                                          selected
                                          hidden
                                        >
                                          Select Value
                                        </option>
                                        {Object.values(
                                          productHierarchyConfiguration
                                        )
                                          .filter(
                                            (item) => typeof item != "number"
                                          )
                                          ?.map((item) => {
                                            return (
                                              <>
                                                <option value={item}>
                                                  {item}
                                                </option>
                                              </>
                                            );
                                          })}
                                      </select>
                                    </>
                                  ) : header.feName ===
                                      "Location Hierarchy Level" &&
                                    componentNameAddEdit === "promo" ? (
                                    <>
                                      <label
                                        htmlFor=""
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "8px",
                                          color: "gray",
                                        }}
                                      >
                                        {header.feName}{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>

                                      <select
                                        defaultValue={
                                          addEditValue.toLowerCase() === "edit"
                                            ? flattenedRawData[header.beName] ??
                                              ""
                                            : // :inputValues[header.beName]=Object.values(locationHierarchyConfiguration).filter(item=>typeof item!="number")[0]
                                              null
                                        }
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          padding: "3px",
                                          border: "none",
                                          outline: "none",
                                          border: "1px solid lightgray",
                                          color: "#444",
                                          fontWeight: "400",
                                        }}
                                        placeholder="Select Value"
                                        onChange={(e) => {
                                          handleInputChange(
                                            header.beName,
                                            e.target.value,
                                            header.dataType,
                                            index,
                                            e
                                          );
                                        }}
                                        name="cars"
                                        id="cars"
                                      >
                                        <option
                                          value=""
                                          disabled
                                          selected
                                          hidden
                                        >
                                          Select Value
                                        </option>
                                        {Object.values(
                                          locationHierarchyConfiguration
                                        )
                                          .filter(
                                            (item) => typeof item != "number"
                                          )
                                          ?.map((item) => {
                                            return (
                                              <>
                                                <option value={item}>
                                                  {item}
                                                </option>
                                              </>
                                            );
                                          })}
                                      </select>
                                    </>
                                  ) : header.feName === "Promo Name" &&
                                    componentNameAddEdit === "promo" ? (
                                    <>
                                      <label
                                        htmlFor=""
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "8px",
                                          color: "gray",
                                        }}
                                      >
                                        {header.feName}{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>

                                      <select
                                        disabled={
                                          addEditValue.toLowerCase() === "edit"
                                            ? true
                                            : false
                                        }
                                        defaultValue={
                                          addEditValue.toLowerCase() === "edit"
                                            ? flattenedRawData[header.beName] ??
                                              ""
                                            : // : inputValues[header.beName]=true
                                              // :inputValues[header.beName]=promoNamesList[0].promoName
                                              null
                                        }
                                        style={{
                                          width: "100%",
                                          cursor:
                                            addEditValue.toLowerCase() ===
                                            "edit"
                                              ? "not-allowed"
                                              : "pointer",
                                          textAlign: "center",
                                          padding: "3px",
                                          border: "none",
                                          outline: "none",
                                          border: "1px solid lightgray",
                                          color: "#444",
                                          fontWeight: "400",
                                        }}
                                        placeholder="Select Value"
                                        onChange={(e) => {
                                          handleInputChange(
                                            header.beName,
                                            e.target.value,
                                            header.dataType,
                                            index,
                                            e
                                          );
                                        }}
                                        name="cars"
                                        id="cars"
                                      >
                                        <option
                                          value=""
                                          disabled
                                          selected
                                          hidden
                                        >
                                          Select Value
                                        </option>
                                        {promoNamesList &&
                                          promoNamesList.length &&
                                          promoNamesList?.map((item) => {
                                            return (
                                              <>
                                                <option value={item.promoName}>
                                                  {item.promoName}
                                                </option>
                                              </>
                                            );
                                          })}
                                      </select>
                                    </>
                                  ) : header.dataType.toLowerCase() ===
                                      "boolean" &&
                                    header.feName
                                      .toLowerCase()
                                      .includes("status") ? (
                                    <div className="add_edit_active_inactive">
                                      <div>
                                        <p className="add_edit_lifecycle_status">
                                          {header.feName}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </p>
                                      </div>

                                      <div>
                                        <button
                                          className="add_edit_active_button"
                                          type="button"
                                          style={{
                                            backgroundColor:
                                              activeInactiveColor === "active"
                                                ? "#32b199"
                                                : "",
                                            color:
                                              activeInactiveColor === "active"
                                                ? "white"
                                                : "",
                                          }}
                                          onClick={(e) => {
                                            handleInputChange(
                                              header.beName,
                                              "true",
                                              header.dataType,
                                              index,
                                              e
                                            );
                                            setActiveInactiveColor("active");
                                            // setActiveInactiveBoolean(true)
                                          }}
                                        >
                                          Active
                                        </button>
                                        <button
                                          className="add_edit_inactive_button"
                                          style={{
                                            backgroundColor:
                                              activeInactiveColor === "inactive"
                                                ? "#ef3a3a"
                                                : "",
                                            color:
                                              activeInactiveColor === "inactive"
                                                ? "white"
                                                : "",
                                          }}
                                          type="button"
                                          onClick={(e) => {
                                            handleInputChange(
                                              header.beName,
                                              "false",
                                              header.dataType,
                                              index,
                                              e
                                            );
                                            setActiveInactiveColor("inactive");
                                            // setActiveInactiveBoolean(false)
                                          }}
                                        >
                                          Inactive
                                        </button>
                                      </div>
                                    </div>
                                  ) : header.dataType.toLowerCase() ===
                                    "boolean" ? (
                                    <>
                                      <label
                                        htmlFor=""
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "8px",
                                          color: "gray",
                                        }}
                                      >
                                        {header.feName}{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <select
                                        defaultValue={
                                          addEditValue.toLowerCase() === "edit"
                                            ? flattenedRawData[header.beName] ??
                                              ""
                                            : (inputValues[
                                                header.beName
                                              ] = true)
                                        }
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          padding: "3px",
                                          border: "none",
                                          outline: "none",
                                          border: "1px solid lightgray",
                                          color: "#444",
                                          fontWeight: "400",
                                        }}
                                        onChange={(e) => {
                                          handleInputChange(
                                            header.beName,
                                            e.target.value,
                                            header.dataType,
                                            index,
                                            e
                                          );
                                        }}
                                        name="cars"
                                        id="cars"
                                      >
                                        <option value={"true"}>Yes</option>
                                        <option value={"false"}>No</option>
                                      </select>
                                    </>
                                  ) : (
                                    <TextField
                                      width={200}
                                      // label={header.feName}
                                      label={
                                        <span>
                                          {header.feName}{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </span>
                                      }
                                      placeholder={`Data Type : ${header.dataType}`}
                                      style={{
                                        width: "100%",
                                        border:
                                          dataTypeErrors[header.beName] ||
                                          dataTypeErrorBoolean[header.beName]
                                            ? "1px solid red"
                                            : "",
                                      }}
                                      type={
                                        // header.dataType === "LocalDate"
                                        //   ? "date"
                                        //   : "text"
                                        header.dataType.toLowerCase() ===
                                          "localdate" ||
                                        header.dataType.toLowerCase() ===
                                          "datetime" ||
                                        header.dataType.toLowerCase() === "date"
                                          ? "date"
                                          : header.dataType.toLowerCase() ===
                                              "string" ||
                                            header.dataType.toLowerCase() ===
                                              "json"
                                          ? "text"
                                          : header.dataType.toLowerCase() ===
                                              "double" ||
                                            header.dataType.toLowerCase() ===
                                              "integer" ||
                                            header.dataType.toLowerCase() ===
                                              "long" ||
                                            header.dataType.toLowerCase() ===
                                              "float"
                                          ? "number"
                                          : "text"
                                      }
                                      // required
                                      id={`outlined-size-normal-${index}`}
                                      defaultValue={
                                        // addEditValue.toLowerCase() === "edit"
                                        //   ? flattenedRawData[header.beName] ??
                                        //     ""
                                        //   : null
                                        addEditValue.toLowerCase() === "edit"
                                          ? flattenedRawData[header.beName] ??
                                            ""
                                          : prefilledValueLikeSku
                                          ? inputValues[header.beName]
                                          : null
                                      }
                                      variant="outlined"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      // editable={
                                      //   header?.mandatory === true &&
                                      //   // column === header.beName &&
                                      //   // dataColumnDetails.includes(header.beName)&&
                                      //   header?.isPrimaryKey !== "C" &&
                                      //   storedFeatureHeader?.isEditUi === true
                                      // }

                                      // InputProps={{
                                      //   startAdornment: (
                                      //     <InputAdornment position="start">
                                      //       <Typography className={classes.redStart}>R</Typography>
                                      //     </InputAdornment>
                                      //   ),
                                      // }}
                                      onChange={(e) => {
                                        const enteredValue = e.target.value;
                                        const defaultDataType =
                                          flattenedRawData[header.beName];
                                        handleInputChange(
                                          header.beName,
                                          e.target.value,
                                          header.dataType,
                                          index,
                                          e
                                        );

                                        // Validate data type when the user enters data
                                        let isValidDataType = true;

                                        if (defaultDataType === "number") {
                                          // If the expected data type is number, check if the entered value is a valid number
                                          isValidDataType = !isNaN(
                                            Number(enteredValue)
                                          );
                                        }

                                        // Update error state for the specific column
                                        setDataTypeErrors((prevErrors) => ({
                                          ...prevErrors,
                                          [header.beName]: !isValidDataType,
                                        }));
                                      }}
                                      // editable={
                                      //   storedFeatureHeader?.beName === column &&
                                      //   storedFeatureHeader?.isEditUi === true
                                      // }
                                    />
                                  )}
                                  {dataTypeErrors[header.beName] ? (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Please enter valid JSON
                                    </p>
                                  ) : dataTypeErrorBoolean[header.beName] ? (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Please enter valid Boolean
                                    </p>
                                  ) : null}
                                </Collapse>
                              </>
                            ) : null}
                            {header?.mandatory === false &&
                            // column === header.beName &&
                            // dataColumnDetails.includes(header.beName) &&
                            header?.isPrimaryKey !== "U" &&
                            header?.isPrimaryKey !== "C" ? (
                              <>
                                {showOptionalTextOnce === index ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div style={{ color: "gray" }}>
                                      Optional fields
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100px",
                                          margin: "0px 5px",
                                          height: "1.3px",
                                          backgroundColor: "gray",
                                        }}
                                      ></div>

                                      <div>
                                        {isListOpen ? (
                                          <span
                                            style={{
                                              height: "10px",
                                              width: "10px",
                                              backgroundColor: "gray",
                                              borderRadius: "50%",
                                            }}
                                            onClick={toggleList}
                                          >
                                            <ExpandMoreIcon
                                            // onClick={toggleList}
                                            />
                                          </span>
                                        ) : (
                                          <span
                                            style={{
                                              height: "10px",
                                              width: "10px",
                                              backgroundColor: "gray",
                                              borderRadius: "50%",
                                            }}
                                            onClick={toggleList}
                                          >
                                            {" "}
                                            <ExpandLessIcon
                                            // onClick={toggleList}
                                            />
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                <Collapse
                                  style={{
                                    display: isListOpen ? "block" : "none",
                                  }}
                                  in={isListOpen}
                                >
                                  {header.beName === "created_at" ||
                                  header.beName === "created_by" ||
                                  header.beName === "modified_at" ||
                                  header.beName === "modified_by" ? null : (
                                    <>
                                      {header.dataType.toLowerCase() ===
                                        "boolean" &&
                                      header.feName
                                        .toLowerCase()
                                        .includes("status") ? (
                                        <div className="add_edit_active_inactive">
                                          <div>
                                            <p className="add_edit_lifecycle_status">
                                              {header.feName}
                                              {/* <span style={{ color: "red" }}>
                                                *
                                              </span> */}
                                            </p>
                                          </div>

                                          <div>
                                            <button
                                              className="add_edit_active_button"
                                              type="button"
                                              style={{
                                                backgroundColor:
                                                  activeInactiveColor ===
                                                  "active"
                                                    ? "#32b199"
                                                    : "",
                                                color:
                                                  activeInactiveColor ===
                                                  "active"
                                                    ? "white"
                                                    : "",
                                              }}
                                              onClick={(e) => {
                                                handleInputChange(
                                                  header.beName,
                                                  "true",
                                                  header.dataType,
                                                  index,
                                                  e
                                                );
                                                setActiveInactiveColor(
                                                  "active"
                                                );
                                                // setActiveInactiveBoolean(true)
                                              }}
                                            >
                                              Active
                                            </button>
                                            <button
                                              className="add_edit_inactive_button"
                                              style={{
                                                backgroundColor:
                                                  activeInactiveColor ===
                                                  "inactive"
                                                    ? "#ef3a3a"
                                                    : "",
                                                color:
                                                  activeInactiveColor ===
                                                  "inactive"
                                                    ? "white"
                                                    : "",
                                              }}
                                              type="button"
                                              onClick={(e) => {
                                                handleInputChange(
                                                  header.beName,
                                                  "false",
                                                  header.dataType,
                                                  index,
                                                  e
                                                );
                                                setActiveInactiveColor(
                                                  "inactive"
                                                );
                                                // setActiveInactiveBoolean(false)
                                              }}
                                            >
                                              Inactive
                                            </button>
                                          </div>
                                        </div>
                                      ) : header.beName.toLowerCase() ===
                                        "like_sku_code" ? (
                                        <>
                                          <div className="like_sku_advance_filter_container">
                                            <Autocomplete
                                              style={{
                                                width: "100%",
                                              }}
                                              disabled={
                                                inputValues["sku_code"] &&
                                                inputValues["channel_name"]
                                                  ? false
                                                  : true
                                              }
                                              disablePortal
                                              id="combo-box-demo"
                                              // defaultValue={  skuCodeFromCheckBox && skuCodeFromCheckBox?.length ?skuCodeFromCheckBox:""}
                                              defaultValue={
                                                likeSkuId && likeSkuId?.length
                                                  ? skuCodeFromCheckBox
                                                  : ""
                                              }
                                              options={likeSkuData}
                                              // onFocus={handleAutocompleteFocus}
                                              // onChange={
                                              //   handleAutocompleteChange
                                              // }
                                              onChange={(event, newValue) => {
                                                if (newValue === null) {
                                                  setInputValues((prevValues) => ({
                                                    ...prevValues,
                                                    ["like_sku_code"]: "",
                                                  }));
                                                  setLikeSkuId("");
                                                }

                                                console.log(
                                                  "Selected Value:",
                                                  typeof newValue,
                                                  newValue
                                                ); // Check the structure of the selected value
                                                if (newValue) {
                                                  handleInputChange(
                                                    "like_sku_code",
                                                    newValue.skuId, // Assuming skuCode is the property you want to extract
                                                    header.dataType,
                                                    index
                                                  );
                                                }
                                              }}
                                              sx={{ width: 300 }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label="Like Sku Code"
                                                />
                                              )}
                                              renderOption={(props, option) => {
                                                if (option.year === 1996) {
                                                  return (
                                                    <a
                                                      href="https://example.com"
                                                      style={{
                                                        // position: "fixed",

                                                        textDecoration:
                                                          "underline",
                                                        display: "block",
                                                        // bottom: "0",
                                                        // right: "10px",
                                                        // marginTop:"50px",
                                                        padding: "8px",
                                                      }}
                                                      onClick={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                        handleAdvanceFilterLikeSku();
                                                      }}
                                                    >
                                                      {option.label}
                                                    </a>
                                                  );
                                                }
                                              }}
                                              //     );
                                              //   } else {
                                              //     return (
                                              //       <span {...props}>
                                              //         {/* {option.label} */}
                                              //         {option.skuCode}

                                              //       </span>
                                              //     );
                                              //   }
                                              // }}
                                            />
                                            {/* <a
                                              onClick={
                                                handleAdvanceFilterLikeSku
                                              }
                                              className="advance_filter_like_sku_button"
                                            >
                                              Advance Filter
                                            </a> */}
                                          </div>
                                        </>
                                      ) : (
                                        <TextField
                                          placeholder={`Data Type : ${header.dataType}`}
                                          label={header.feName}
                                          style={{
                                            width: "100%",
                                            border:
                                              dataTypeErrors[header.beName] ||
                                              dataTypeErrorBoolean[
                                                header.beName
                                              ]
                                                ? "1px solid red"
                                                : "",
                                          }}
                                          type={
                                            // header.dataType === "LocalDate"
                                            //   ? "date"
                                            //   : "text"
                                            header.dataType.toLowerCase() ===
                                              "localdate" ||
                                            header.dataType.toLowerCase() ===
                                              "datetime" ||
                                            header.dataType.toLowerCase() ===
                                              "date"
                                              ? "date"
                                              : header.dataType.toLowerCase() ===
                                                  "string" ||
                                                header.dataType.toLowerCase() ===
                                                  "json"
                                              ? "text"
                                              : header.dataType.toLowerCase() ===
                                                  "double" ||
                                                header.dataType.toLowerCase() ===
                                                  "integer" ||
                                                header.dataType.toLowerCase() ===
                                                  "long" ||
                                                header.dataType.toLowerCase() ===
                                                  "float"
                                              ? "number"
                                              : "text"
                                          }
                                          id={`outlined-size-normal-${index}`}
                                          // defaultValue={
                                          //   flattenedRawData[header.beName] ??
                                          //   ""
                                          // }
                                          defaultValue={
                                            // addEditValue.toLowerCase() ===
                                            // "edit"
                                            //   ? flattenedRawData[
                                            //       header.beName
                                            //     ] ?? ""
                                            //   : null
                                            addEditValue.toLowerCase() ===
                                            "edit"
                                              ? flattenedRawData[
                                                  header.beName
                                                ] ?? ""
                                              : prefilledValueLikeSku
                                              ? inputValues[header.beName]
                                              : null
                                          }
                                          variant="outlined"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          // editable={
                                          //   header?.mandatory === false &&
                                          //   // column === header.beName &&
                                          //   // dataColumnDetails.includes(header.beName) &&

                                          //   header?.isPrimaryKey !== "C" &&
                                          //   storedFeatureHeader?.isEditUi === true
                                          // }
                                          onChange={(e) => {
                                            const enteredValue = e.target.value;
                                            const defaultDataType =
                                              flattenedRawData[header.beName];
                                            handleInputChange(
                                              header.beName,
                                              e.target.value,
                                              header.dataType,
                                              index,
                                              e
                                            );

                                            // Validate data type when the user enters data
                                            let isValidDataType = true;

                                            if (defaultDataType === "number") {
                                              // If the expected data type is number, check if the entered value is a valid number
                                              isValidDataType = !isNaN(
                                                Number(enteredValue)
                                              );
                                            }

                                            // Update error state for the specific column
                                            setDataTypeErrors((prevErrors) => ({
                                              ...prevErrors,
                                              [header.beName]: !isValidDataType,
                                            }));
                                          }}
                                          // editable={
                                          //   storedFeatureHeader?.beName === column &&
                                          //   storedFeatureHeader?.isEditUi === true
                                          // }
                                        />
                                      )}

                                      {dataTypeErrors[header.beName] ? (
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "10px",
                                          }}
                                        >
                                          Please enter valid JSON
                                        </p>
                                      ) : dataTypeErrorBoolean[
                                          header.beName
                                        ] ? (
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "10px",
                                          }}
                                        >
                                          Please enter valid Boolean
                                        </p>
                                      ) : null}
                                    </>
                                  )}
                                  {/* <TextField
                                      placeholder={`Data Type : ${header.dataType}`}
                                      label={header.feName}
                                      type={
                                        // header.dataType === "LocalDate"
                                        //   ? "date"
                                        //   : "text"
                                        header.dataType.toLowerCase() ===
                                          "localdate" ||
                                        header.dataType.toLowerCase() ===
                                          "datetime" ||
                                        header.dataType.toLowerCase() === "date"
                                          ? "date"
                                          : header.dataType.toLowerCase() ===
                                              "string" ||
                                            header.dataType.toLowerCase() ===
                                              "json"
                                          ? "text"
                                          : header.dataType.toLowerCase() ===
                                              "double" ||
                                            header.dataType.toLowerCase() ===
                                              "integer" ||
                                            header.dataType.toLowerCase() ===
                                              "long" ||
                                            header.dataType.toLowerCase() ===
                                              "float"
                                          ? "number"
                                          : "text"
                                      }
                                      id={`outlined-size-normal-${index}`}
                                      defaultValue={
                                        flattenedRawData[header.beName] || ""
                                      }
                                      variant="outlined"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      // editable={
                                      //   header?.mandatory === false &&
                                      //   // column === header.beName &&
                                      //   // dataColumnDetails.includes(header.beName) &&

                                      //   header?.isPrimaryKey !== "C" &&
                                      //   storedFeatureHeader?.isEditUi === true
                                      // }
                                      onChange={(e) => {
                                        const enteredValue = e.target.value;
                                        const defaultDataType =
                                          flattenedRawData[header.beName];
                                        handleInputChange(
                                          header.beName,
                                          e.target.value
                                        );

                                        // Validate data type when the user enters data
                                        let isValidDataType = true;

                                        if (defaultDataType === "number") {
                                          // If the expected data type is number, check if the entered value is a valid number
                                          isValidDataType = !isNaN(
                                            Number(enteredValue)
                                          );
                                        }

                                        // Update error state for the specific column
                                        setDataTypeErrors((prevErrors) => ({
                                          ...prevErrors,
                                          [header.beName]: !isValidDataType,
                                        }));
                                      }}
                                      // editable={
                                      //   storedFeatureHeader?.beName === column &&
                                      //   storedFeatureHeader?.isEditUi === true
                                      // }
                                    /> */}
                                </Collapse>
                              </>
                            ) : null}
                          </>
                        );
                      })}
                    {/* <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ color: "gray" }}>Custom Field</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "100px",
                            margin: "0px 5px",
                            height: "1.3px",
                            backgroundColor: "gray",
                          }}
                        ></div>

                        <div>
                          {isListOpenCustom ? (
                            <span
                              onClick={handleCustomFieldToggle}
                              style={{
                                height: "10px",
                                width: "10px",
                                backgroundColor: "gray",
                                borderRadius: "50%",
                              }}
                            >
                              <ExpandMoreIcon />
                            </span>
                          ) : (
                            <span
                              style={{
                                height: "10px",
                                width: "10px",
                                backgroundColor: "gray",
                                borderRadius: "50%",
                              }}
                              onClick={handleCustomFieldToggle}
                            >
                              <ExpandLessIcon />
                            </span>
                          )}
                        </div>
                        <Collapse
                          style={{
                            display: isListOpen ? "block" : "none",
                          }}
                          in={isListOpenCustom}
                        >
                          {customDataTextField.map((customFiled) => {
                            return (
                              <TextField
                                // placeholder={`Data Type : ${header.dataType}`}
                                label={customFiled.lavel}
                                // style={{
                                //   width: "100%",
                                //   border:
                                //     dataTypeErrors[header.beName] ||
                                //     dataTypeErrorBoolean[header.beName]
                                //       ? "1px solid red"
                                //       : "",
                                // }}
                              />
                            );
                          })}
                        </Collapse>
                      </div>
                    </div> */}
                    {}
                    {likeSkuId.length &&
                    componentNameAddEdit.toLowerCase() === "product" ? (
                      <>
                        <Box
                          // key={indexOouter}
                          // component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            width: "100%",
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ color: "gray" }}>Product Ai</div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: "100px",
                                  margin: "0px 5px",
                                  height: "1.3px",
                                  backgroundColor: "gray",
                                }}
                              ></div>

                              <div>
                                {isListOpenProductAi ? (
                                  <span
                                    onClick={handleProductAiFieldToggle}
                                    style={{
                                      height: "10px",
                                      width: "10px",
                                      backgroundColor: "gray",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <ExpandMoreIcon />
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      height: "10px",
                                      width: "10px",
                                      backgroundColor: "gray",
                                      borderRadius: "50%",
                                    }}
                                    onClick={handleProductAiFieldToggle}
                                  >
                                    <ExpandLessIcon />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <Collapse
                              style={{
                                display: isListOpenProductAi ? "block" : "none",
                              }}
                              in={isListOpenProductAi}
                            >
                              <div>
                                <p>Is this a replacement ?</p>
                              </div>
                              <div
                                style={{
                                  padding: "8px 12px",
                                  borderRadius: "5px",
                                  backgroundColor: "#f7f9fc",
                                  width: "40%",
                                  fontWeight: "400",
                                }}
                              >
                                <button
                                  style={{
                                    padding: "8px 12px",
                                    borderRadius: "5px",
                                    color: productAiReplaceButton
                                      ? "white"
                                      : "black",
                                    backgroundColor: productAiReplaceButton
                                      ? "#8d939b"
                                      : "#f7f9fc",
                                  }}
                                  onClick={() =>
                                    setProductAiReplaceButton(true)
                                  }
                                >
                                  Yes
                                </button>
                                <button
                                  style={{
                                    padding: "8px 12px",
                                    borderRadius: "5px",
                                    color: productAiReplaceButton
                                      ? "black"
                                      : "white",
                                    backgroundColor: productAiReplaceButton
                                      ? "#f7f9fc"
                                      : "#8d939b",
                                    marginLeft: "20px",
                                  }}
                                  onClick={() =>
                                    setProductAiReplaceButton(false)
                                  }
                                >
                                  No
                                </button>
                              </div>
                              <div>
                                {productAiReplaceButton ? (
                                  <TextField
                                    style={{ width: "100%", marginTop: "10px" }}
                                    type="date"
                                    // label="Controlled picker"
                                    label="Life Cycle End Date of Like Sku"
                                    // placeholder="dd/mm/yyyy"
                                    defaultValue={""}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    value={productAiReplacemtnDate}
                                    onChange={(newValue) => {
                                      productAiReplacementDate(newValue);
                                    }}
                                  />
                                ) : null}
                              </div>
                            </Collapse>
                          </div>
                        </Box>
                      </>
                    ) : null}
                  </Box>
                </div>
              </div>
              <div className="add_edit_button">
                <button
                  style={{ backgroundColor: "#ff4500" }}
                  size="small"
                  variant="contained"
                  onClick={handleSaveEdit}
                  // disabled={!mandatoryFieldError}
                >
                  Apply
                </button>

                <button
                  size="small"
                  variant="contained"
                  onClick={handleCancelEdit}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {/* <Button
          disabled={selectedRowIds?.length < 1}
          className="ml-15"
          label={
            <>
              <span className="mr-5">Delete</span>
              <FontAwesomeIcon icon={faTrashAlt} />
            </>
          }
          type="submit"
          onClick={handleRemoveClick}
        /> */}
        {showRemoveConfirmation && (
          <div className="confirmation-popup">
            <div className="popup-content">
              <p>Are you sure you want to delete ?</p>
            </div>
            <div className="buttons-container">
              <button onClick={handleCancelRemove}>No</button>
              <button onClick={handleConfirmRemove}>Yes</button>
            </div>
          </div>
        )}
        {/* new code - 5 */}
        {/* <Dialog closeIcon={false} className={"custom-dialog-loader"}> */}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="bootstrap_dialog"
        >
          {/* <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>	
Modal title	
</BootstrapDialogTitle> */}
          {/* <DialogContent dividers>	
  {	
    addRemoveTableDataColumn.length && addRemoveTableDataColumn.map((item, index) => {	
      return (	
        <div style={{ display: "flex", alignItems: 'center' }}>	
          <input type="checkbox" id={item} defaultChecked={true} checked={allCheckboxId.includes(item) ? false : true} onChange={handleCheckbox} />	
          <span>{t(`${item}`)}</span>	
        </div>	
      )	
    })	
  }	
</DialogContent> */}
          <DialogContent dividers style={{ width: "auto", minWidth: "420px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    defaultChecked={true}
                    checked={allCheckboxId.length == 0 ? true : false}
                    onChange={
                      allCheckboxId.length == 0 ? unCheckClick : handleDefault
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div style={{ marginLeft: "-40%" }}>
                <Input
                  type="text"
                  size="sm"
                  style={{ width: "180%" }}
                  className="searchItem"
                  value={searchTerm}
                  placeholder="Search column..."
                  // value={searchTerm}
                  onChange={onChangeSearchItem}
                />
              </div>
              <div>
                <span className="hideshowclose" onClick={handleClose}>
                  &times;
                </span>
              </div>
            </div>

            {addRemoveTableDataColumn.length && (
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="characters">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {addRemoveTableDataColumn.map((item, index) => (
                        // <Draggable key={index} draggableId={String(index)} index={index}>
                        //   {(provided) => (
                        //     <div
                        //       className="draggable-item"
                        //       ref={provided.innerRef}
                        //       {...provided.draggableProps}
                        //       {...provided.dragHandleProps}
                        //     // style={{ display: "flex", alignItems: 'center'}}
                        //     >
                        //       <label for={item}></label>
                        //       <input type="checkbox" id={item} defaultChecked={true} checked={allCheckboxId.includes(item) ? false : true} onChange={handleCheckbox} />
                        //       <span>{t(`${item}`)}</span>
                        //       {/* {t(`${item}`)} <MenuIcon/> */}
                        //       <MenuIcon/>
                        //     </div>
                        //   )}
                        // </Draggable>
                        <Draggable
                          key={index}
                          draggableId={String(index)}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="draggable-item"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <label
                                htmlFor={item}
                                className="checkbox-container"
                              >
                                <input
                                  key={item}
                                  type="checkbox"
                                  id={item}
                                  defaultChecked={true}
                                  checked={
                                    allCheckboxId.includes(
                                      dataColumnDetails[index]
                                    )
                                      ? false
                                      : true
                                  }
                                  onChange={handleCheckbox}
                                />
                                {/* ))} */}
                                <span className="checkmark"></span>
                              </label>
                              &nbsp;&nbsp;
                              <span className="item-text">{t(`${item}`)}</span>
                              <MenuIcon />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </DialogContent>
          <DialogActions>
            {/* <Button
              label={"close"}
              buttonType={"primary"}
              className={"ml-15"}
              onClick={handleClose}
            /> */}
            <Button
              label={"Update"}
              buttonType={"primary"}
              className={"ml-16"}
              onClick={handleUpdatebutton}
            />
            {/* <Button
              label={"Check All"}
              buttonType={"primary"}
              className={"ml-16"}
              onClick={handleDefault}
            /> */}
            {/* close */}
            {/* </Button> */}
          </DialogActions>
        </BootstrapDialog>
        {/* </Dialog> */}
        {/* end new code - 5 */}
      </div>

      <div className="client-listing-toolbar-right">
        {/* new code - 6 */}
        {/* <Button
          className={`ml-15 ${
            allCheckboxId.length > 0 ? "hideshowborder" : ""
          }`}
          onClick={handleClickOpen}
          label={"Hide/Show"}
        />  */}
        {/* end new code - 6 */}
        {/* {
          componentName==="forecast" && syncConfiguration.sync.length?<Button
          buttonType={"upload"}
          // disabled={disabled ?? false}
          className="ml-15"
          onClick={handleSyncForecast}
          label={t("Sync")}
        />:null
        } */}
        {componentName === "forecast" &&
        snopId &&
        syncConfiguration?.sync?.length ? (
          <Buttonn
            // componentName==="forecast" && snopId ?<Button
            startIcon={<SyncIcon />}
            // buttonType={"sync"}
            // disabled={disabled ?? false}
            className="ml-15"
            onClick={handleSyncForecast}
            // label={t("Sync")}
          >
            Sync
          </Buttonn>
        ) : null}
        {csvUploadPermission && (
          <Button
            buttonType={"upload"}
            disabled={disabled ?? false}
            className="ml-15"
            onClick={toggleDialog}
            label={t("Upload")}
          />
        )}{" "}
        &nbsp;&nbsp;&nbsp;
        <div className="client-listing-toolbar-right-show input-select-wrapper">
          {/*
        <InputSelect
            name={"Download"}
            // buttonType={'download'}
            // placeholder="Download"
            data={filedownloadoptions ?? []}
            value={"Download"}

            // renderValue={(selected) => (
            //   <InputAdornment position="end">
            //     <DownloadIcon />
            //   </InputAdornment>
            // )}
            // className="ml-15"
            // startAdornmentAdornment={
            //   <InputAdornment position="start">
            //     <DownloadIcon />
            //   </InputAdornment>
            // }

            disabled={disabled ?? false}
            className={"search-listing-toolbar"}
            // disabled={disabled ?? false}
            onChange={(e) => {
              downloadfilefunction(e.target.value);
            }}
          />
         */}

          <Button
            buttonType={"download"}
            disabled={!(dataList?.length > 0) || !downloadUrl}
            className="ml-15"
            onClick={() => {
              return dataList?.length > 0 && downloadUrl
                ? downloadCsvApi({
                    url: downloadUrl,
                    fileName: downloadName,
                  })
                : false;
            }}
            label={t("CSV Download")}
          />
        </div>
        <form onSubmit={handleSearch} className={"custom-search-form"}>
          <div className={`client-listing-toolbar-right-csv-download mrg`}>
            <InputText
              // label={"Search"}
              name={"search"}
              value={searchField}
              placeholder={"Search..."}
              onChange={handleChange}
              adornmentIcon={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {searchField && (
                      <IconButton onClick={handleClear}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              disabled={disabled ?? false}
              className={
                isGoButtonClicked
                  ? "highlight-border"
                  : "search-listing-toolbar"
              }
            />
          </div>
          <div className="client-listing-toolbar-right-add-client toolbar-search-btn">
            <Button
              buttonType={"primary"}
              // disabled={disabled ?? false}
              disabled={searchField.length < 3}
              // onClick={handleGoButtonClick}
              className={"ml-5"}
              label={t("GO")}
              type="submit"
            />
          </div>
        </form>
        {/* new code - 7  */}
        {showAdvanceSearch &&
          advanceSearchPermission.includes(showAdvanceSearch) && (
            <div>
              <Button
                buttonType={"primary"}
                disabled={disabled ?? false}
                className={isOkButtonClicked ? "highlight-body" : "ml-5"}
                label={"Advance Filter"}
                type="submit"
                onClick={handleAdvanceSearchPopup}
              />
            </div>
          )}
        {/* end new code  - 7 */}
      </div>
    </>
  );
};

export default TableToolbar;
