import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  rmNodeMappingList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
};

const RMNodeMappingSlice = createSlice({
  name: "rmNodeMappingSlice",
  initialState: initialState,
  reducers: {
    getAllRMNodeMappingSlice: (state, action) => {
      state.rmNodeMappingList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    RMNodeMappingCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    RMNodeMappingCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    RMNodeMappingCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
 getAllRMNodeMappingSlice,
 RMNodeMappingCSVUploadComplete,
 RMNodeMappingCSVUploadInitiate,
 RMNodeMappingCSVUploadError
} = RMNodeMappingSlice.actions;

export default RMNodeMappingSlice.reducer;
