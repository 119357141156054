import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  salesHistoryList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
};

export const SalesHistorySlice = createSlice({
  name: "actualSalesHistorSlice",
  initialState: initialState,
  reducers: {
    getAllSalesHistorySlice: (state, action) => {
      state.salesHistoryList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    salesHistoryCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    salesHistoryCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    salesHistoryCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
  getAllSalesHistorySlice,
  salesHistoryCSVUploadInitiate,
  salesHistoryCSVUploadComplete,
  salesHistoryCSVUploadError
} = SalesHistorySlice.actions;

export default SalesHistorySlice.reducer;
