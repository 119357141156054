import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import "./style.scss";

export function InputSelect({
  onChange,
  value,
  label,
  isError,
  error,
  name,
  data,
  isMultiple,
  isCheckbox,
  placeholder,
  disabled,
  t
}) {
  return (
    <FormControl fullWidth className="MuiInputBase-formControl-select-custom">
      {!disabled && label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value}
        label={disabled ? "" : label ?? ""}
        displayEmpty
        onChange={onChange}
        name={name}
        disabled={disabled}
        multiple={isMultiple ?? false}
        renderValue={(selected) => {
          if (selected?.length === 0) {
            return (placeholder && <span className="select-placeholder">{placeholder}</span>) ?? "";
          }
          if (typeof selected === "string" || typeof selected === 'number') {
            const findS = data && data.filter((s) => (s?.id ?? s?.value) === selected);
            return (findS && findS[0]?.label) ?? selected;
          }
          if (Array.isArray(selected)) {
            const findA =
              selected &&
              selected.map((d) => {
                const findAs = data && data.filter((s) => s.id === d);
                return (findAs && findAs[0]?.label) ?? selected;
              });
            return value.length === data.length ? 'All Selected' : findA.join(", ");
          }
          return selected;
        }}
      >
        {isCheckbox && isMultiple && data.length > 0 && (
          <MenuItem value="all">
            <Checkbox
              indeterminate={value.length !== data.length && value.length > 0}
              checked={value.length === data.length}
              className={'mui-checkbox-style'}
            />
            <ListItemText>{t("labelAll")}</ListItemText>
          </MenuItem>
        )}

        {data &&
          data.map((item, idx) => (
            <MenuItem value={item?.id ?? item?.value} key={idx}>
              {isCheckbox && isMultiple && (
                <Checkbox checked={value && value.includes(item?.id ?? item?.value)} className={'mui-checkbox-style'}/>
              )}
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          ))}
      </Select>
      {isError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
