import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_PROMOTION_TYPE, UPLOAD_PROMOTION_TYPE_CSV_DATA } from "../../../types";
import { getAllPromotionTypeAPI, uploadPromotionTypeCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";
import { getAllpromotiontypeSlice,promotiontypeCSVUploadComplete,promotiontypeCSVUploadError } from "../../../slices/transactional/promotiontype";

export function* getAllPromotionTypeSaga(action) {
  try {
    yield put(getAllpromotiontypeSlice({
      data: {},
      loader: true
    }))
    const res = yield getAllPromotionTypeAPI(action.payload);
    yield put(getAllpromotiontypeSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllpromotiontypeSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadPromotionTypeCSVDataAPI(action.payload);
    yield put(promotiontypeCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        promotiontypeCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchPromotionTypeSaga() {
  yield takeEvery(GET_ALL_PROMOTION_TYPE, getAllPromotionTypeSaga);
  yield takeEvery(UPLOAD_PROMOTION_TYPE_CSV_DATA,uploadCSVSaga);
}
