import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_BUDGET,UPLOAD_BUDGET_CSV_DATA,GET_ALL_EDIT_BUDGET,POST_ALL_ADD_BUDGET } from "../../../types";
import { getAllBudgetAPI,getFeatureHeaderAPI, getEditBudgetHeaderAPI,postAddBudgetHeaderAPI, uploadBudgetCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";
import {
  getAllBudgetSlice,getEditAllBudgetSlice,postAddAllBudgetSlice,  budgetCSVUploadComplete,budgetCSVUploadError
} from "../../../slices/entities/budget";


export function* postAllBudgetSaga(action) {
  try {
    yield put(postAddAllBudgetSlice({
      data: {},
      loader: true
    }))
    const res = yield postAddBudgetHeaderAPI(action.payload);
    yield put(postAddAllBudgetSlice());
    yield put(postAddAllBudgetSlice({
      data: res?.data ?? {},
      loader: false
    }))
    
  } catch (error) {

   
    yield put(postAddAllBudgetSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}
export function* getAllEditBudgetSaga(action) {
  try {
    yield put(getEditAllBudgetSlice({
      data: {},
      loader: true
    }))
    const res = yield getEditBudgetHeaderAPI(action.payload);
    yield put(getEditAllBudgetSlice());
    yield put(getEditAllBudgetSlice({
      data: res?.data?? {},
      loader: false
    }))
    
  } catch (error) {
    yield put(getEditAllBudgetSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

function* getAllBudgetSaga(action) {
  try {

   

    yield put(getAllBudgetSlice({
      data: {},
      loader: true
    }));
    // if(localStorage.getItem("featureHeader")){
      yield localStorage.removeItem("featureHeader")
      const featureHeader = yield getFeatureHeaderAPI("budget");
      yield localStorage.setItem('featureHeader', JSON.stringify(featureHeader.data.responseData));

      
      // console.log("featureHeader",featureHeader.data.responseData);
    // }
    const res = yield getAllBudgetAPI(action.payload);
    yield put(getAllBudgetSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }));
    // if(!localStorage.getItem("featureHeader")){
      // const featureHeader = yield getFeatureHeaderAPI("budget");
      // localStorage.setItem('featureHeader', JSON.stringify(featureHeader.data.responseData));
      // console.log("featureHeader",featureHeader.data.responseData);
    // }else{
    //   console.log("trsting meri",localStorage.getItem("featureHeader"));
    // }
  } catch (error) {
  
  
  
  
  
    yield put(getAllBudgetSlice({
      data: {},
      loader: false
    }));
    toast.error((error && error?.response?.data?.responseData.message) ?? error.message,
    {
      autoClose: false,
      closeOnClick: false,
      draggable: false,
    });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadBudgetCSVDataAPI(action.payload);
    yield put(budgetCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(budgetCSVUploadError(error.response?.data?.responseData));
  }
}
export function* watchBudgetSaga() {
  yield takeEvery(GET_ALL_BUDGET, getAllBudgetSaga);
  yield takeEvery(UPLOAD_BUDGET_CSV_DATA, uploadCSVSaga);
  yield takeEvery(GET_ALL_EDIT_BUDGET, getAllEditBudgetSaga);
  yield takeEvery(POST_ALL_ADD_BUDGET, postAllBudgetSaga);


}
