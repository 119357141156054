import { put, takeEvery } from "redux-saga/effects";
import {
    getAllSyncStatusAPI
} from "../../../apis";
import {
    getAllSyncstatusSlice
} from "../../slices/syncstatus";
import { GET_ALL_SYNCSTATUS } from "../../types";
import { toast } from "react-toastify";

export function* getAllSyncStatusSaga(action) {
    try {
        const syncstatus = yield getAllSyncStatusAPI(action.payload);
        yield put(getAllSyncstatusSlice(syncstatus.data.responseData));
    } catch (error) {
        toast.error(error?.response?.data?.responseData?.message ?? error.message,
            {
                autoClose: false,
                closeOnClick: false,
                draggable: false,
            });
    }
}

export function* watchSyncStatusSaga() {
    yield takeEvery(GET_ALL_SYNCSTATUS, getAllSyncStatusSaga);
}
