import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_FEATURES,POST_ALL_ADD_FEATURES, UPLOAD_FEATURES_CSV_DATA,GET_ALL_EDIT_FEATURES } from "../../../types";
import { getFeatureHeaderAPI,getAllFeatureAPI,postAddFeatureHeaderAPI,getEditFeatureHeaderAPI, uploadFeatureCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";
import {
  getAllFeatureSlice,
  featureCSVUploadComplete,
  featureCSVUploadError,
  getEditAllFeatureSlice,
  postAddAllFeatureSlice
} from "../../../slices/transactional/feature";

export function* postAllFeatureSaga(action) {
  try {
    yield put(postAddAllFeatureSlice({
      data: {},
      loader: true
    }))
    const res = yield postAddFeatureHeaderAPI(action.payload);
    yield put(postAddAllFeatureSlice());
    yield put(postAddAllFeatureSlice({
      data: res?.data?? {},
      loader: false
    }))
    
  } catch (error) {
    yield put(postAddAllFeatureSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* getAllFeatureSaga(action) {
  try {
    yield put(getAllFeatureSlice({
      data: {},
      loader: true
    }))
    // if(localStorage.getItem("featureHeader")){
      yield localStorage.removeItem("featureHeader")
      const featureHeader = yield getFeatureHeaderAPI("features");
      yield localStorage.setItem('featureHeader', JSON.stringify(featureHeader.data.responseData));
      // console.log("featureHeader",featureHeader.data.responseData);
    // }
    const res = yield getAllFeatureAPI(action.payload);
    yield put(getAllFeatureSlice());
    yield put(getAllFeatureSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
    // if(!localStorage.getItem("featureHeader")){
      // const featureHeader = yield getFeatureHeaderAPI("features");
      // localStorage.setItem('featureHeader', JSON.stringify(featureHeader.data.responseData));
      // console.log("featureHeader",featureHeader.data.responseData);
    // }else{
    //   console.log("trsting meri",localStorage.getItem("featureHeader"));
    // }
  } catch (error) {
    yield put(getAllFeatureSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}


export function* getAllEditFeatureSaga(action) {
  try {
    yield put(getEditAllFeatureSlice({
      data: {},
      loader: true
    }))
    const res = yield getEditFeatureHeaderAPI(action.payload);
    yield put(getEditAllFeatureSlice());
    yield put(getEditAllFeatureSlice({
      data: res?.data ?? {},
      loader: false
    }))
    
  } catch (error) {
    yield put(getEditAllFeatureSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}


export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadFeatureCSVDataAPI(action.payload);
    yield put(featureCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
      featureCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchFeatureSaga() {
  yield takeEvery(GET_ALL_FEATURES, getAllFeatureSaga);
  yield takeEvery(UPLOAD_FEATURES_CSV_DATA, uploadCSVSaga);
  yield takeEvery(GET_ALL_EDIT_FEATURES, getAllEditFeatureSaga);
  yield takeEvery(POST_ALL_ADD_FEATURES,postAllFeatureSaga)
  

}
