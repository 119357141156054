export const SHOW_ROWS = [10, 25, 50, 100];
export const addEditTableColumnDataTypes=['Float', 'String', 'JSON', 'long', 'DateTime', 'Date', 'json', 'Boolean', 'Integer'].map(dataType => dataType.toLowerCase())


export const permission = {
  productAddEdit: "PRODUCT_CREATEEDIT",
  locationAddEdit: "LOCATION_CREATEEDIT",
  mappingAddEdit: "MAPPING_CREATEEDIT",
  networkAddEdit: "NETWORK_CREATEEDIT",
  personnelAddEdit: "PERSONNEL_CREATEEDIT",
  salesHistoryAddEdit: "SALESHISTORY_CREATEEDIT",
  featureAddEdit: "FEATURES_CREATEEDIT",
  forecastSummaryAddEdit: "FORECASTSUMMARY_CREATEEDIT",
  salesOrdersAddEdit: "SALES_ORDER__CREATEEDIT",
  promoTransactionEdit:"PROMO_TRANSACTION_CREATEEDIT",
  promoMasterEdit:"PROMO MASTER_CREATEEDIT",
  goodsInHand:"GOODS_IN_HAND",
  goodsInTransit:"GOODS_IN_TRANSIT",
  goodsInPBNS:"GOODS_IN_PBNS"
};
export const addEditPermission=[
  "product",
  "location",
  "budget",
  "feature",
  "promo",
  "promomaster"
]
export const advanceSearchPermission=[
  "product",
  "location",
  "budget",
  "feature",
  "promo",
  "promomaster"
]
export const entitiesName = [
  "mapping",
  "currency",
  "network",
  "actualSalesHistory",
  "feature",
  "forecast",
  "productionWorkOrder",
  "promo",
  "poEvents",
  "personal",
  "location",
  "product",
  "goodsInHand",
  "goodsInPBNS",
  "goodsInTransit",
  "rmpo",
  "rmNodeMapping",
  "stockTransferOrder",
  "shipment",
  "inventoryEvents",
  "billOfMaterials",
  "purchaseOrder",
  "stoEvents",
  "budget",
  "raw-material",
  "marketingevents",
  "promotiontype",
  "splitmaster",
  "split",
  "salesorders"
];
