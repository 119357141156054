import React from "react";
import List from './list'

const Budget = () => {
  return (
    <>
      <List />
    </>
  );
};
export default Budget;
