

export const setTableWidth = (minWidth, grid, dataColumn) => {
    if (grid.current !== null && grid.current.offsetWidth > 0) {
      if (grid.current.offsetWidth < 567 && dataColumn.length > 3) {
        return minWidth 
      } else if (grid.current.offsetWidth < 767 && dataColumn.length > 5) {
        return minWidth + 20
      } else if (grid.current.offsetWidth < 1199 && dataColumn.length >= 9) {
        return minWidth + 20
      } else if (grid.current.offsetWidth < 1540 && dataColumn.length > 12 && dataColumn.length <= 20) {
        return minWidth + 30;
      } else if (grid.current.offsetWidth > 1540 && dataColumn.length > 18) {
        return minWidth + 30;
      } else {
        return minWidth + 30
      }
    }
    return "auto";
  };