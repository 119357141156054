import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_MARKETING_EVENTS, UPLOAD_MARKETING_EVENTS_CSV_DATA } from "../../../types";
import { getAllMarketingEventsAPI, uploadMarketingEventsCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";
import { getAllmarketingeventsSlice,marketingeventsCSVUploadComplete,marketingeventsCSVUploadError } from "../../../slices/transactional/marketingevents";

export function* getAllMarketingEventsSaga(action) {
  try {
    yield put(getAllmarketingeventsSlice({
      data: {},
      loader: true
    }))
    const res = yield getAllMarketingEventsAPI(action.payload);
    yield put(getAllmarketingeventsSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllmarketingeventsSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadMarketingEventsCSVDataAPI(action.payload);
    yield put(marketingeventsCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        marketingeventsCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchMarketingEventsSaga() {
  yield takeEvery(GET_ALL_MARKETING_EVENTS, getAllMarketingEventsSaga);
  yield takeEvery(UPLOAD_MARKETING_EVENTS_CSV_DATA,uploadCSVSaga);
}
