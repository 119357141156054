import React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import "./style.scss";
import Loader from "../../assets/Loader.gif";

export default function ProcessingLoader() {
  return (
    <div>
      <Dialog closeIcon={false} className={"custom-dialog-loader"} style={{
          backdropFilter: "blur(2.5px)",
          backgroundColor: "transparent", 
        }}>
        <img src={Loader} alt="loader" />
      </Dialog>
    </div>
  );
}