import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  stockTransferOrderList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
};

export const StoSlice = createSlice({
  name: "stoSlice",
  initialState: initialState,
  reducers: {
    getAllstoSlice: (state, action) => {
      state.stockTransferOrderList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    stoCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    stoCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    stoCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
  getAllstoSlice,
  stoCSVUploadInitiate,
  stoCSVUploadComplete,
  stoCSVUploadError
} = StoSlice.actions;

export default StoSlice.reducer;
