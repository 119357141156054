import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  ForecastList: [],
  snopList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
};

export const ForecastSlice = createSlice({
  name: "ForecastSlice",
  initialState: initialState,
  reducers: {
    getAllForecastSlice: (state, action) => {
      state.ForecastList = action?.payload?.data ?? [];
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    ForecastCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    ForecastCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.csvUploadResponseData = action.payload;
      state.loader = false
      return state;
    },
    ForecastCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
    getSnopListingSlice: (state, action) => {
      state.snopList = action?.payload?.data ?? [];
      state.loader = action?.payload?.loader ?? false
      return state;
    },
  },
});

export const {
  getAllForecastSlice,
  ForecastCSVUploadInitiate,
  ForecastCSVUploadComplete,
  ForecastCSVUploadError,
  getSnopListingSlice
} = ForecastSlice.actions;

export default ForecastSlice.reducer;
