import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_SPLITMASTER, UPLOAD_SPLITMASTER_CSV_DATA } from "../../../types";
import { getAllSplitMasterAPI, uploadSplitMasterCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";
import {
  getAllSplitMasterSlice,
  splitmasterCSVUploadComplete,
  splitmasterCSVUploadError
} from "../../../slices/entities/splitmaster";
function* getAllSplitMasterSaga(action) {
  try {
    yield put(getAllSplitMasterSlice({
      data: {},
      loader: true
    }));
    const res = yield getAllSplitMasterAPI(action.payload);
    yield put(getAllSplitMasterSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }));
  } catch (error) {
    yield put(getAllSplitMasterSlice({
      data: {},
      loader: false
    }));
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {

  try {
    const res = yield uploadSplitMasterCSVDataAPI(action.payload);

    yield put(splitmasterCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(splitmasterCSVUploadError(error.response?.data?.responseData));
  }
}
export function* watchSplitMasteraga() {
  yield takeEvery(GET_ALL_SPLITMASTER, getAllSplitMasterSaga);
  yield takeEvery(UPLOAD_SPLITMASTER_CSV_DATA, uploadCSVSaga);
}
