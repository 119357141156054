import React from "react";
import List from './list'

const SplitMaster = () => {
  return (
    <>
      <List />
    </>
  );
};
export default SplitMaster;
