import React from "react";
import usePagination from "@mui/material/usePagination";

export default function Pagination({
  start,
  endCount,
  totalElement,
  count,
  showFirstButton,
  showLastButton,
  page,
  onChange,
}) {
  const { items } = usePagination({
    count: count ?? 0,
    showFirstButton: showFirstButton ?? false,
    showLastButton: showLastButton ?? false,
    page: page,
    onChange: onChange,
  });
  return (
    <div className="tablegrid_pagination">
      <div
        className="k-pager k-widget k-grid-pager d-flex pt-1"
        role="application"
        aria-roledescription="pager"
      >
        <div className="k-pager-numbers-wrap">
          <ul className="k-pager-numbers">
            {items.map(({ page, type, selected, disabled, ...item }, index) => {
              let children = null;
              if (type === "start-ellipsis" || type === "end-ellipsis") {
                children = "…";
              } else if (type === "page") {
                children = (
                  <span
                    role="button"
                    aria-label="undefined 1"
                    aria-current="true"
                    className={`k-link ${selected && "k-selected"}`}
                    {...item}
                  >
                    {page}
                  </span>
                );
              } else if (type === "first" || type === "last") {
                children =
                  type === "first" ? (
                    <span
                      className={`k-link k-pager-nav k-pager-first ${
                        disabled && "k-disabled"
                      }`}
                      title="Go to the first page"
                      role="button"
                      aria-disabled="true"
                      {...item}
                    >
                      <span
                        className="k-icon k-i-caret-alt-to-left k-color-inherit"
                        role="presentation"
                      ></span>
                    </span>
                  ) : (
                    <span
                      className={`k-link k-pager-nav k-pager-last ${
                        disabled && "k-disabled"
                      }`}
                      title="Go to the last page"
                      role="button"
                      {...item}
                    >
                      <span
                        className="k-icon k-i-caret-alt-to-right k-color-inherit"
                        role="presentation"
                      ></span>
                    </span>
                  );
              } else {
                children =
                  type === "previous" ? (
                    <span
                      className={`k-link k-pager-nav ${
                        disabled && "k-disabled"
                      }`}
                      title="Go to the previous page"
                      role="button"
                      aria-disabled="true"
                      {...item}
                    >
                      <span
                        className="k-icon k-i-caret-alt-left k-color-inherit"
                        role="presentation"
                      ></span>
                    </span>
                  ) : (
                    <span
                      className={`k-link k-pager-nav ${
                        disabled && "k-disabled"
                      }`}
                      title="Go to the next page"
                      role="button"
                      {...item}
                    >
                      <span
                        className="k-icon k-i-caret-alt-right k-color-inherit"
                        role="presentation"
                      ></span>
                    </span>
                  );
              }
              return <li key={index}>{children}</li>;
            })}
          </ul>
        </div>

        <div className="k-pager-info k-label">{`${
          start + 1
        } ${endCount} of ${totalElement} items`}</div>
      </div>
    </div>
  );
}
