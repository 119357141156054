import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_GOODS_IN_HAND, UPLOAD_GOODS_IN_HAND_CSV_DATA } from "../../../types";
import { getAllGoodsInHandAPI, uploadGoodsInHandCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";
import { getAllGoodsInHandSlice,goodsInHandCSVUploadComplete,goodsInHandCSVUploadError } from "../../../slices/transactional/goodsinhand";

export function* getAllGoodsInHandSaga(action) {
  try {
    yield put(getAllGoodsInHandSlice({
      data: {},
      loader: true
    }))
    const res = yield getAllGoodsInHandAPI(action.payload);
    yield put(getAllGoodsInHandSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllGoodsInHandSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    
    const res = yield uploadGoodsInHandCSVDataAPI(action.payload);
    yield put(goodsInHandCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        goodsInHandCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchGoodsInHandSaga() {
  yield takeEvery(GET_ALL_GOODS_IN_HAND, getAllGoodsInHandSaga);
  yield takeEvery(UPLOAD_GOODS_IN_HAND_CSV_DATA,uploadCSVSaga);
}
