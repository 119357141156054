import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_INVENTORY_EVENTS,UPLOAD_INVENTORY_EVENTS_CSV_DATA } from "../../../types";
import { getAllInventoryEventsAPI,uploadInventoryEventsCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";

import { getAllInventoryEventsSlice,inventoryEventsCSVUploadComplete,inventoryEventsCSVUploadError } from "../../../slices/transactional/inventoryEvents";

export function* getAllInventoryEventsSaga(action) {

 
  try {
    yield put(getAllInventoryEventsSlice({
      data: {},
      loader: true
    }))
    const res = yield getAllInventoryEventsAPI(action.payload);
    yield put(getAllInventoryEventsSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllInventoryEventsSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadInventoryEventsCSVDataAPI(action.payload);
    yield put(inventoryEventsCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        inventoryEventsCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchInventoryEventsSaga() {
  yield takeEvery(GET_ALL_INVENTORY_EVENTS, getAllInventoryEventsSaga);
  yield takeEvery(UPLOAD_INVENTORY_EVENTS_CSV_DATA,uploadCSVSaga);
}
