import * as actionTypes from "../types/index";
 
const initialState = {
    selectedRowIds: [],
};
 
const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_ROW_DATA:
            return { ...state, selectedRowIds: action.payload };
        default:
            return state;
    }
};
 
export default commonReducer;