import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  poEventsList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
};

export const poEventsSlice = createSlice({
  name: "poEventsSlice",
  initialState: initialState,
  reducers: {
    getAllpoEventsSlice: (state, action) => {
      state.poEventsList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    poEventsCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    poEventsCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    poEventsCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
  getAllpoEventsSlice,
  poEventsCSVUploadInitiate,
  poEventsCSVUploadComplete,
  poEventsCSVUploadError
} = poEventsSlice.actions;

export default poEventsSlice.reducer;
