import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  salesOrdersList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
};

export const SalesOrdersSlice = createSlice({
  name: "salesordersSlice",
  initialState: initialState,
  reducers: {
    getAllSalesOrdersSlice: (state, action) => {
      state.salesOrdersList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    salesOrdersCSVUploadInitiate: (state, action) => {

        
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    salesOrdersCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    salesOrdersCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
  getAllSalesOrdersSlice,
  salesOrdersCSVUploadInitiate,

  
  salesOrdersCSVUploadComplete,
  salesOrdersCSVUploadError
} = SalesOrdersSlice.actions;

export default SalesOrdersSlice.reducer;
