import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Error } from "@progress/kendo-react-labels";
import { isEmpty } from "lodash";
import { Button } from "../button/";
import "./uploadDialog.scss";

const UploadDialog = ({
  templateUrl,
  toggleDialog,
  handleCSVUpload,
  myFiles,
  listing,
  getRootProps,
  getInputProps,
  downloadCsvApi,
  templateName,
  errorCSV,
  dateFormat,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Dialog id="window" className={`kendo-customD-upload-dialog`}>
        <div className="upload-title">
          <span className="span1">{t("Upload")}</span>
          <Button buttonType={"close"} onClick={toggleDialog} />
        </div>
        <DialogActionsBar>
          <div className="border2">
            <div className="div2">
              <section className="container">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input
                      {...getInputProps()}
                      accept={".csv, .xlsx"}
                      type="file"
                      name="file"
                    />
                    <h4 className="kh4">Select a CSV file to upload</h4>
                    <p className="kp mb-0">or drag or drop it here</p>
                    {dateFormat && (
                      <p className="kp date_format_text mt-2">
                        <b>Note:</b> Date format should be <b>{dateFormat}</b>
                      </p>
                    )}
                    <p className="kp date_format_text mt-2">
                        <b>* -</b> denotes mandatory columns
                    </p>
                  </div>
                

                {myFiles && (
                  <aside>{myFiles[0] ? <ul>{listing}</ul> : ""}</aside>
                )}
                {errorCSV && (
                  <Error className="dialog-error">{t(errorCSV)}</Error>
                )}
              </section>
            </div>
          </div>
        </DialogActionsBar>

        <DialogActionsBar>
          <div className="custom-k-dialog-buttongroup">
            {templateUrl && (
              <Button
                buttonType={"download"}
                label={t("Download Template")}
                onClick={() => {
                  downloadCsvApi({
                    url: templateUrl,
                    fileName: templateName,
                  });
                }}
              />
            )}
            {handleCSVUpload && (
              <Button
                buttonType={"primary"}
                label={t("Upload")}
                onClick={handleCSVUpload}
                disabled={isEmpty(listing)}
              />
            )}
          </div>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};

export default React.memo(UploadDialog);
