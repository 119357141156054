import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./store/reducer";
import createSagaMiddleware from "@redux-saga/core";
import { watcherSaga } from "./store/sagas";

let sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.CUSTOM_NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(middleware),
});

sagaMiddleware.run(watcherSaga);
