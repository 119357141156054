import * as XLSX from 'xlsx';
export const csvFileReader = (file) => {
  return new Promise((rsolve, reject) => {
    if (file.type === "text/csv") {
      const reader = new FileReader();
      reader.onabort = () => {
        rsolve("CSV_READING_ABORTED");
      };
      reader.onerror = () => {
        rsolve("CSV_READING_FAILED");
      };
      reader.onload = (e) => {
        const text = reader?.result;

        const headers = text && text.slice(0, text.indexOf("\n")).split(",");

        const rows = text && text.slice(text.indexOf("\n") + 1).split("\n");

        if (headers.length > 1 && !!rows[0]) {
          rsolve("");
        } else {
          rsolve("NOT_ACCEPT_EMPTY_CSV");
        }
      };
      reader.readAsText(file);
    } else if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      const reader = new FileReader();
      reader.onabort = () => {
        rsolve("EXCEL_READING_ABORTED");
      };
      reader.onerror = () => {
        rsolve("EXCEL_READING_FAILED");
      };
      reader.onload = (e) => {
        const text = reader?.result;

        const base64Data = text.split(',')[1];

        // Decode the Base64 string
        const binaryData = atob(base64Data);

        // Convert the binary data to a Uint8Array
        const uint8Array = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        // Read the Excel file using XLSX
        const workbook = XLSX.read(uint8Array, { type: 'array' });

        // Get the first sheet name
        const sheetName = workbook.SheetNames[0];

        // Get worksheet data
        const worksheet = workbook.Sheets[sheetName];

        // Convert worksheet to JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headers = jsonData[0];
        
        if (headers.length > 1 && jsonData.slice(1).length) {
          rsolve("");
        } else {
          rsolve("NOT_ACCEPT_EMPTY_EXCEL");
        }
      };
      // reader.readAsText(file);
      reader.readAsDataURL(file)
    }
    else {
      rsolve("NOT_CSV_FILE");
    }
  });
};
