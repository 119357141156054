import React, { useState, useEffect } from "react";
import "../../../../style/sass/main.scss";
import moment from "moment";
import "@progress/kendo-theme-default/dist/all.css";
import Alert from '@mui/material/Alert';
import {filesize} from 'filesize'

import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_CURRENCY,
  UPLOAD_CURRENCY_CSV_DATA,
} from "../../../../store/types";
import Breadcrumb from "../../../../components/breadcrumb";
import { useTranslation } from "react-i18next";
import { SHOW_ROWS } from "../../../../constant/constant";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { downloadCsvApi } from '../../../../apis'
import { csvFileReader } from "../../../../utils/csvFileReader";
import UploadDialog from "../../../../components/uploadDialog";
import TableGrid from "../../../../components/tableGrid";
import TableToolbar from "../../../../components/tableGrid/toolbar";
import { entitiesName, permission } from "../../../../utils/Constants";
import ProcessingLoader from "../../../../components/processingLoader";

const List = (props) => {
  const { t } = useTranslation();
  const EDM_API_ENDPOINT = process.env.REACT_APP_EDM_API_ENDPOINT;
  const SCAI_API_BASEURL_CURRENCY =
    process.env.REACT_APP_SCAI_API_BASEURL_CURRENCY;

  const user = JSON.parse(localStorage.getItem("user"));
  const localeConfiguration = JSON.parse(localStorage.getItem("configuration")) ? JSON.parse(localStorage.getItem("configuration")).locale : {};
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const permissions = get(user, "permissions", []);
  const addEditPermission = permissions.includes(permission.productAddEdit);
  const dispatch = useDispatch();
  const [rowLength, setRowLength] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [errorCSV, setErrorCSV] = useState("");
  const [allCheckboxId, setAllCheckboxId] = useState([]);
  const [childData, setChildData] = useState([]);
  const [searchColumns, setSearchColumns] = useState("");
  const [updateflag, setUpdateflag] = useState(false);
  const [newallCheckboxId, setNewallCheckboxId] = useState([]);
  const notRequiredColumns = JSON.parse(sessionStorage.getItem('currency'));
  let strNRC;
  if (notRequiredColumns != null) {
    strNRC = notRequiredColumns.join(",");
  }
  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      const error = await csvFileReader(file);
      if (!error) {
        setMyFiles([file]);
        setErrorCSV("");
      } else {
        setErrorCSV(error);
        setMyFiles([]);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const files = myFiles.map((file) => (
    <li key={file.path} className="csv-upload-success-li">
      {file.path} - {file.size} bytes
    </li>
  ));
  useEffect(() => {
    if (visible === false) {
      setMyFiles([]);
      setErrorCSV('')
    }
  }, [visible]);

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  const showMoreOptions = SHOW_ROWS.map((item) => ({
    label: `${t("Show")} ${item} ${t("Rows")}`,
    value: item,
  }));

  const toggleDialog = () => {
    dispatch({ type: "currency/currencyCSVUploadInitiate" });
    setVisible(!visible);
  };

  const body = {
    pageIndex: pageIndex,
    pageSize: rowLength,
    sortFieldStringWithASCOrDESC: sortValue,
    searchValue: searchField,
    tenantId: tenantId,
    parent: 'currency'
  };


  useEffect(() => {
    if (!refresh) {
      dispatch({ type: GET_ALL_CURRENCY, payload: body });
    }
  }, [dispatch, pageIndex, sortValue, rowLength]);

  const handleSearchSubmit = () => {
    setPageIndex(1);
    dispatch({ type: GET_ALL_CURRENCY, payload: { ...body, pageIndex: 1 } });
  };

  const handleAdvanceSearch = (data) => {
    body.advanceSearchValue = data;
    body.buttonStatus = true;
    setPageIndex(1);
    dispatch({ type: GET_ALL_CURRENCY, payload: { ...body, pageIndex: 1 } });
  };

  const clearAdvanceSearch = () => {
    body.buttonStatus = false;
    setPageIndex(1);
    dispatch({ type: GET_ALL_CURRENCY, payload: { ...body, pageIndex: 1 } });
  };


  const refreshHandler = () => {
    setRefresh(true);
    setPageIndex(1);
    setSearchField("");
    setSortValue("");
    setRowLength(10);
    const refreshBody = {
      ...body,
      searchValue: "",
      pageIndex: 1,
      pageSize: 10,
      sortFieldStringWithASCOrDESC: "",
    };
    setTimeout(() => {
      dispatch({ type: GET_ALL_CURRENCY, payload: refreshBody });
      setRefresh(false);
    }, 300);
  };
  const currencies = useSelector((state) => state.CurrencyReducer.currencies);

  const currencyEntities = get(currencies, "entities", "");
  const totalElement = get(currencies, "totalElements", "");
  const pageSize = get(currencies, "pageSize", "");
  const csvUpload = useSelector((state) => state.CurrencyReducer.csvUpload);
  const csvUploadResponse = useSelector(
    (state) => state.CurrencyReducer.csvUploadResponseData
  );
  const csvUploadErrorResponse = useSelector(
    (state) => state.CurrencyReducer.csvUploadError
  );
  const loader = useSelector(
    (state) => state.CurrencyReducer.loader
  );

  useEffect(() => {
    if (csvUploadErrorResponse) {
      setVisible(false);
      toast.error(t(`${csvUploadErrorResponse.responseMessage}`),
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    }
  }, [csvUploadErrorResponse]);

  useEffect(() => {
    if (csvUpload) {
      setVisible(false);
      if (csvUploadResponse.responseCode === "FAILED_HEADER_VALIDATION") {
        toast.error(`${csvUploadResponse.responseMessage}`,
        {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      } else if (csvUploadResponse.responseCode === "SUCCESS_WITH_ROW_FAILURE") {
        toast.warning(
          `${t(`${csvUploadResponse.responseCode + '\n'}`)
          }`,

          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        )
        toast.warning(
          <a href={`${csvUploadResponse.errorFileName}`} target="_blank"> Download Error File </a>,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        )
      } else if (csvUploadResponse.responseCode === "SUCCESS_WITH_OUT_ROW_FAILURE") {
        toast.success(
          `${t(`${csvUploadResponse.responseCode}`) + ' ' + csvUploadResponse.failedData.toString()}`,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          });
      } else {
        toast.success(
          t(`${csvUploadResponse.responseCode}`),
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      }
      refreshHandler();
    }
  }, [csvUpload]);

  const handleCSVUpload = () => {
    const fileSizeInMB = myFiles[0].size / (1024 * 1024);
    if(fileSizeInMB>40){
      return toast.warning("File size should be less than or equal to 40 MB",
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      })
    }
  //  if(myFiles[0].type!="text/csv" && myFiles[0].type!="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
  //   return toast.warning("Only CSV and EXCEL is supported")

  //  }
  if(myFiles[0].type!="text/csv" ){
    setVisible(!visible)

      return toast.error("Only CSV file is supported",
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      })

   }
    const uploadBody = {
      csvFile: myFiles[0],
      tenantId: tenantId,
    };
    dispatch({ type: UPLOAD_CURRENCY_CSV_DATA, payload: uploadBody });
    dispatch({ type: "currency/currencyCSVUploadInitiate" });
  };

  const currencyDetails =
    currencyEntities &&
    currencyEntities.map((item) => {
      const items = { ...item };
      items.from_date = moment(item.from_date).format(localeConfiguration["dateFormat"].toUpperCase());
      items.to_date = moment(item.to_date).format(localeConfiguration["dateFormat"].toUpperCase());
      items.is_active = item.is_active ? "Active" : "In-Active";
      return items;
    });

  // const removeColumn = ["created_at", "created_by", "modified_at", "modified_by"];
  const removeColumn = [];

  let dataColumn =
    currencyDetails &&
    currencyDetails.length > 0 &&
    Object.keys(currencyDetails[0]).filter((item) => {
      return !removeColumn.includes(item);
    });

  let addRemoveTableDataColumn =
    currencyDetails &&
    currencyDetails.length > 0 &&
    Object.keys(currencyDetails[0]).filter((item) => {
      return !removeColumn.includes(item);
    });

  useEffect(() => {
    const storedCheckboxId = sessionStorage.getItem('currency');
    if (storedCheckboxId) {
      const parsedCheckboxId = JSON.parse(storedCheckboxId);
      setAllCheckboxId(parsedCheckboxId);
    }
  }, []);


  const handleCheckboxChange = (e) => {
    const checkboxId = e.target.id;
    setAllCheckboxId(allCheckboxId => {
      if (!e.target.checked) {
        return [...allCheckboxId, checkboxId];
      } else {
        if (allCheckboxId.length == 1) {
          setAllCheckboxId([]);
          sessionStorage.setItem('currency', JSON.stringify([]));
        }
        return allCheckboxId.filter(ID => ID !== checkboxId);
      }

    });
  };

  const handleDefault = () => {
    setAllCheckboxId([]);
    sessionStorage.setItem('currency', JSON.stringify([]));
  };
  const unCheckDefault = () => {
    setAllCheckboxId(Object.values(addRemoveTableDataColumn));
    sessionStorage.setItem('currency', JSON.stringify(Object.values(addRemoveTableDataColumn)));
  }
  if (!updateflag) {
    const localdata = JSON.parse(localStorage.getItem('currencyallcheckboxId'));
    // console.log("localdata",localdata);
    if (localdata != null) {
      dataColumn = Object.values(dataColumn).filter(
        (element) => !localdata.includes(element)
      );
    }
  }
  function handleHideandShow() {
    setNewallCheckboxId(allCheckboxId);
    localStorage.setItem('currencyallcheckboxId', JSON.stringify(allCheckboxId));
    if (dataColumn.length) {
      setUpdateflag(true);
      if (childData.length) {
        dataColumn = childData;
      }
      dataColumn = dataColumn.filter(
        (element) => !allCheckboxId.includes(element)
      );
      // console.log("handleHideandShow", dataColumn);
    }
  }

  if (entitiesName.includes('currency') && allCheckboxId.length) {
    sessionStorage.setItem("currency", JSON.stringify(allCheckboxId))
  }

  const handleChildData = (array) => {
    setChildData(array);
  };
  if (childData.length) {
    addRemoveTableDataColumn = childData;
  }
  const handleSearchItem = (data) => {
    const searchTerm = data.replace(/ /g, "_").toLowerCase();
    const matchFound = addRemoveTableDataColumn.some((item) =>
      item.includes(searchTerm)
    );
    if (matchFound) {
      setSearchColumns(data);
    }
  };



  if (searchColumns.length) {
    addRemoveTableDataColumn = addRemoveTableDataColumn.filter((item) =>
      item.includes(searchColumns.replace(/ /g, "_").toLowerCase())
    );
  }
  // if (dataColumn.length) {	
  //   if (childData.length) {	
  //     dataColumn = childData;	
  //   }	
  //   dataColumn = dataColumn.filter(	
  //     (element) => !allCheckboxId.includes(element)	
  //   );	
  // }	
  if (childData.length) {
    const localdata = JSON.parse(localStorage.getItem('currencyallcheckboxId'));
    if(localdata!=null){
      dataColumn = Object.values(childData).filter(
      (element)=> !localdata.includes(element)
      );
    } 
  }
  return (
    <>
      {loader && <ProcessingLoader />}
      <div>
        <section className="client-listing">
          <h3 className="section-heading">{t("Currency")}</h3>
          <Breadcrumb list={["Entities Data", "Currency"]} />
          <div className="client-listing-content">
            <div className="client-listing-toolbar">
              <TableToolbar
                showMoreOptions={showMoreOptions}
                setRowLength={setRowLength}
                setPageIndex={setPageIndex}
                searchField={searchField}
                handleChange={handleChange}
                toggleDialog={toggleDialog}
                handleSearchSubmit={handleSearchSubmit}
                refreshHandler={refreshHandler}
                setSearchField={setSearchField}
                rowLength={rowLength}
                downloadUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL_CURRENCY}/csv?tenant_id=${tenantId}&bu_id=${buId}&sortFieldStringWithASCOrDESC=${sortValue}&file_type=csv&searchValue=${searchField}&notRequiredColumns=${strNRC}`}
                dataList={currencyDetails}
                downloadCsvApi={downloadCsvApi}
                downloadName={"currency.csv"}
                csvUploadPermission={addEditPermission}
                entityName={"currency"}
                addRemoveTableDataColumn={addRemoveTableDataColumn ?? []}
                onCheckboxChange={handleCheckboxChange}
                sendDataToParent={handleChildData}
                allCheckboxId={allCheckboxId}
                setAllCheckboxId={setAllCheckboxId}
                handleSearchItem={handleSearchItem}
                ondefaultbutton={handleDefault}
                handleAdvanceSearch={handleAdvanceSearch}
                clearAdvanceSearch={clearAdvanceSearch}
                handleHideandShow={handleHideandShow}
                unCheckDefault={unCheckDefault}
              />
            </div>
            <div className="client-listing-grid">
              {" "}
              <TableGrid
                dataRows={currencyDetails ?? []}
                dataColumn={
                  updateflag
                    ? (Array.isArray(dataColumn) ? dataColumn.filter((element) => !newallCheckboxId.includes(element)) : [])
                    : (Array.isArray(dataColumn) ? dataColumn : [])
                }
                refresh={refresh}
                pageSize={pageSize}
                totalElement={totalElement}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setSortValue={setSortValue}
                componentName={"currency"}

              />
            </div>
          </div>
        </section>

        {visible && (
          <UploadDialog
            templateUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL_CURRENCY}/template?tenant_id=${tenantId}&bu_id=${buId}`}
            toggleDialog={toggleDialog}
            handleCSVUpload={handleCSVUpload}
            myFiles={myFiles}
            listing={files}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            downloadCsvApi={downloadCsvApi}
            templateName={"currency-template.csv"}
            errorCSV={errorCSV}
            dateFormat={localeConfiguration["dateFormat"]}
          />
        )}
      </div>
    </>
  );
};

export default List;
