import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  inventoryEventsList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
};

const InventoryEventsSlice = createSlice({
  name: "inventoryEvents",
  initialState: initialState,
  reducers: {
    getAllInventoryEventsSlice: (state, action) => {
      state.inventoryEventsList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    inventoryEventsCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    inventoryEventsCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    inventoryEventsCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
 getAllInventoryEventsSlice,
 inventoryEventsCSVUploadComplete,
 inventoryEventsCSVUploadInitiate,
 inventoryEventsCSVUploadError
} = InventoryEventsSlice.actions;

export default InventoryEventsSlice.reducer;
