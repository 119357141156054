import "./style.scss";
import { MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";
import { CloseIcon } from "../icons/CloseIcon";
export const Button = ({
  buttonType,
  label,
  className,
  disabled,
  onClick,
  icon,
  iconPosition,
  type,
}) => {
  if (buttonType === "primary") {
    return (
      <button
        disabled={disabled ?? false}
        className={`btn primary-button ${className ?? ""}`}
        onClick={onClick}
        type={type ?? "button"}
        >
        {iconPosition === "start" && icon}
        {label}
        {iconPosition === "end" && icon}
      </button>
    );
  } else if (buttonType === "outline-primary") {
    return (
      <button
        disabled={disabled ?? false}
        className={`btn cancel-button ${className ?? ""}`}
        onClick={onClick}
        type={type ?? "button"}
      >
        {iconPosition === "start" && icon}
        {label}
        {iconPosition === "end" && icon}
      </button>
    );
  } else if (buttonType === "download") {
    return (
      <button
        disabled={disabled ?? false}
        className={`btn secondary-button ${className ?? ""}`}
        onClick={onClick}
        type={type ?? "button"}
      >
        {label}
        <MdOutlineFileDownload />
      </button>
    );
  } else if (buttonType === "upload") {
    return (
      <button
        disabled={disabled ?? false}
        className={`btn secondary-button ${className ?? ""}`}
        onClick={onClick}
        type={type ?? "button"}
      >
        {label}
        <MdOutlineFileUpload />
      </button>
    );
  } else if (buttonType === "refresh") {
    return (
      <button
        disabled={disabled ?? false}
        className={`btn refresh-btn ${className ?? ""}`}
        onClick={onClick}
        type={type ?? "button"}
      >
        <i className="fa fa-undo" aria-hidden="true"></i>
      </button>
    );
  } else if (buttonType === "close") {
    return (
      <button onClick={onClick} className="close-btn" type="button">
        <CloseIcon />
      </button>
    );
  } else {
    return (
      <button
        disabled={disabled ?? false}
        className={`btn ${className ?? ""}`}
        onClick={onClick}
        type={type ?? "button"}
      >
        {label}
      </button>
    );
  }
};
