import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_PO_EVENTS, UPLOAD_PO_EVENTS_CSV_DATA } from "../../../types";
import { getAllpoEventsAPI, uploadpoEventsCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";
import { getAllpoEventsSlice,
    poEventsCSVUploadComplete,
    poEventsCSVUploadError } from "../../../slices/transactional/poEvents";

export function* getAllpoEventsSaga(action) {
  try {
    yield put(getAllpoEventsSlice({
      data: {},
      loader: true
    }))
    const res = yield getAllpoEventsAPI(action.payload);
    yield put(getAllpoEventsSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllpoEventsSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadpoEventsCSVDataAPI(action.payload);
    yield put(poEventsCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        poEventsCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchpoEventsSaga() {
  yield takeEvery(GET_ALL_PO_EVENTS, getAllpoEventsSaga);
  yield takeEvery(UPLOAD_PO_EVENTS_CSV_DATA,uploadCSVSaga);
}
