import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_PRODUCTION_WORK_ORDER, UPLOAD_PRODUCTION_WORK_ORDER_CSV_DATA } from "../../../types";
import { getAllProductionWorkOrderAPI, uploadProductionWorkOrderCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";

import { getAllProductionWorkOrderSlice,productionWorkOrderCSVUploadComplete,productionWorkOrderCSVUploadError } from "../../../slices/transactional/productionWorkOrder";

export function* getAllProductionWorkOrderSaga(action) {

 
  try {
    yield put(getAllProductionWorkOrderSlice({
      data: {},
      loader: true
    }))
    const res = yield getAllProductionWorkOrderAPI(action.payload);
    yield put(getAllProductionWorkOrderSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllProductionWorkOrderSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadProductionWorkOrderCSVDataAPI(action.payload);
    yield put(productionWorkOrderCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        productionWorkOrderCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchProductionWorkOrderSaga() {
  yield takeEvery(GET_ALL_PRODUCTION_WORK_ORDER, getAllProductionWorkOrderSaga);
  yield takeEvery(UPLOAD_PRODUCTION_WORK_ORDER_CSV_DATA,uploadCSVSaga);
}
