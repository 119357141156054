import { put, takeEvery } from "redux-saga/effects";
import { getAllPersonnelAPI, uploadCSVDataPersonnelAPI } from "../../../../apis";
import {
  getAllPersonnelSlice,
  personnelCSVUploadComplete,
  personnelCSVUploadError,
} from "../../../slices/entities/personnel";
import { GET_ALL_PERSONNEL, UPLOAD_PERSONNEL_CSV_DATA } from "../../../types";
import { toast } from "react-toastify";

export function* getAllPersonnelSaga(action) {
  try {
    yield put(getAllPersonnelSlice({
      data: {},
      loader: true
    }))
    const personnel = yield getAllPersonnelAPI(action.payload);
    yield put(getAllPersonnelSlice({
      data: personnel.data.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllPersonnelSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadPersonnelCSVSaga(action) {
  try {
    const res = yield uploadCSVDataPersonnelAPI(action.payload);
    yield put(personnelCSVUploadComplete(res.data.responseData));
  } catch (error) {
    yield put(personnelCSVUploadError(error.response.data.responseData));
  }
}

export function* watchPersonnelSaga() {
  yield takeEvery(GET_ALL_PERSONNEL, getAllPersonnelSaga);
  yield takeEvery(UPLOAD_PERSONNEL_CSV_DATA, uploadPersonnelCSVSaga);
}
