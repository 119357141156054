import React from "react";
import List from "./list";

const Personnel = () => {
  return (
    <>
      <List />
    </>
  );
};
export default Personnel;
