import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  splitmasterList: {},
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
};

export const SplitMasterSlice = createSlice({
  name: "splitmaster",
  initialState: initialState,
  reducers: {
    getAllSplitMasterSlice: (state, action) => {
      state.splitmasterList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    splitmasterCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    splitmasterCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    splitmasterCSVUploadError: (state, action) => {
      state.loader = false
      state.csvUploadError = action.payload;
      return state;
    }
  },
});

export const {
  getAllSplitMasterSlice,
  splitmasterCSVUploadInitiate,
  splitmasterCSVUploadComplete,
  splitmasterCSVUploadError
} = SplitMasterSlice.actions;

export default SplitMasterSlice.reducer;
