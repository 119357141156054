import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  budgetList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
  addEditTableColumnBudget:null
};

export const BudgetSlice = createSlice({
  name: "budget",
  initialState: initialState,
  reducers: {
    getAllBudgetSlice: (state, action) => {
      state.budgetList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    getEditAllBudgetSlice: (state, action) => {
      state.addEditTableColumnBudget = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    postAddAllBudgetSlice: (state, action) => {
      state.addEditTableColumnBudget = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    budgetCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    budgetCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.csvUploadResponseData = action.payload;
      state.loader = false
      return state;
    },
    budgetCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
  getAllBudgetSlice,
  budgetCSVUploadComplete,
  budgetCSVUploadInitiate,
  budgetCSVUploadError,
 getEditAllBudgetSlice,
 postAddAllBudgetSlice
} = BudgetSlice.actions;

export default BudgetSlice.reducer;
