import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_RM_NODE_MAPPING, UPLOAD_RM_NODE_MAPPING_CSV_DATA } from "../../../types";
import { getAllRMNodeMappingAPI, uploadRMNodeMappingCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";

import { getAllRMNodeMappingSlice,RMNodeMappingCSVUploadComplete,RMNodeMappingCSVUploadError } from "../../../slices/transactional/rmNodeMapping";

export function* getAllRMNodeMappingSaga(action) {

 
  try {
    yield put(getAllRMNodeMappingSlice({
      data: {},
      loader: true
    }))
    const res = yield getAllRMNodeMappingAPI(action.payload);
    yield put(getAllRMNodeMappingSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllRMNodeMappingSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadRMNodeMappingCSVDataAPI(action.payload);
    yield put(RMNodeMappingCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        RMNodeMappingCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchRMNodeMappingSaga() {
  yield takeEvery(GET_ALL_RM_NODE_MAPPING, getAllRMNodeMappingSaga);
  yield takeEvery(UPLOAD_RM_NODE_MAPPING_CSV_DATA,uploadCSVSaga);
}
