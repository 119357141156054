import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  productionWorkOrderList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
};

const ProductionWorkOrderSlice = createSlice({
  name: "productionWorkOrderSlice",
  initialState: initialState,
  reducers: {
    getAllProductionWorkOrderSlice: (state, action) => {
      state.productionWorkOrderList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    productionWorkOrderCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    productionWorkOrderCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    productionWorkOrderCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
  getAllProductionWorkOrderSlice,
  productionWorkOrderCSVUploadInitiate,
  productionWorkOrderCSVUploadComplete,
  productionWorkOrderCSVUploadError
} = ProductionWorkOrderSlice.actions;

export default ProductionWorkOrderSlice.reducer;
