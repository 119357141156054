import React from "react";
import TextField from "@mui/material/TextField";
import "./style.scss";

export const InputText = React.memo(({
    onChange,
    value,
    isError,
    error,
    label,
    placeholder,
    disabled,
    adornmentIcon,
    adornmentClass,
    name,
    className
}) => {
  return (
    <>
      <TextField
        className={`MuiInputBase-formControl-custom ${adornmentClass ?? ''} ${className ?? ''}`}
        error={isError}
        label={(disabled ? '' : label) ?? ''}
        helperText={isError && error}
        onChange={onChange}
        value={value}
        fullWidth={true}
        disabled={disabled}
        placeholder={disabled && !!label && !placeholder ?  label : placeholder ?? ''}
        name={name}
        InputProps={{
            ...(adornmentIcon && adornmentIcon)
          }}
      />
    </>
  );
});
