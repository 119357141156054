import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  goodsInTransitList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
};

const GoodsInTransitSlice = createSlice({
  name: "goodsInTransitSlice",
  initialState: initialState,
  reducers: {
    getAllGoodsInTransitSlice: (state, action) => {
      state.goodsInTransitList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    gooodsInTransitCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    goodsInTransitCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    goodsInTransitCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
  getAllGoodsInTransitSlice,
  gooodsInTransitCSVUploadInitiate,
  goodsInTransitCSVUploadComplete,
  goodsInTransitCSVUploadError
} = GoodsInTransitSlice.actions;

export default GoodsInTransitSlice.reducer;
