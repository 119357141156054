import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { InputSelect } from "../../inputSelect/";
import { Button } from "../../button/";
import { InputText } from "../../inputText/";
import { DateRangePicker } from "rsuite";
import { useDispatch } from "react-redux";
import "rsuite/dist/rsuite.css";
import { DatePicker } from "antd";
import moment from 'moment';

const {RangePicker} = DatePicker

const SyncStatusbar = ({
  routeTypes,
  showMoreOptions,
  setRowLength,
  setPageIndex,
  disabled,
  rowLength,
  setRouteType,
  routeType,
  startDate,
  endDate,
  setStartDate,
  setDateData,
  setEndDate,
}) => {
  const { t } = useTranslation();

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  return (
    <>
      <div className="client-listing-toolbar-left">
        <RangePicker
          editable={false}
          disabledDate={disabledDate}
          onChange={(e) => {
            setDateData(e);
          }}
        />{" "}
        &nbsp;
        <div className="client-listing-toolbar-left-show">
          <InputSelect
            name="show"
            placeholder="Route Type"
            data={showMoreOptions ?? []}
            value={rowLength}
            onChange={(e) => {
              setRowLength(e.target.value);
              setPageIndex(1);
            }}
            disabled={disabled ?? false}
          />
        </div>
        &nbsp;
        <div className="client-listing-toolbar-left-show">
          <InputSelect
            name="show"
            placeholder="Route Type"
            data={routeTypes ?? []}
            value={routeType}
            onChange={(e) => {
              setRouteType(e.target.value);
              setPageIndex(1);
            }}
            disabled={disabled ?? false}
          />
        </div>
      </div>

      <div className="client-listing-toolbar-right"></div>
    </>
  );
};

export default SyncStatusbar;
