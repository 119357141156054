import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_PROMO,
  UPLOAD_PROMO_CSV_DATA,
  GET_ALL_EDIT_PROMO_TRANSACTION,
  POST_ALL_PROMO_TRANSACTION
 } from "../../../types";
import { getAllPromoAPI,
  uploadPromoCSVDataAPI,
  getEditPromoTransactionHeaderAPI,
  postAddPromoTransactionHeaderAPI,
  getAllPromoNamesAPI,
  getFeatureHeaderAPI
 } from "../../../../apis";
import { toast } from "react-toastify";

import { getAllPromoSlice,
  promoCSVUploadComplete,
  getEditAllPromoTransactionSlice,
  postAddAllPromoTransactionSlice,
  promoCSVUploadError } from "../../../slices/transactional/Promo";




export function* postAllPromoTransactionSaga(action) {
  try {
    yield put(postAddAllPromoTransactionSlice({
      data: {},
      loader: true
    }))
    const res = yield postAddPromoTransactionHeaderAPI(action.payload);
    yield put(postAddAllPromoTransactionSlice());
    yield put(postAddAllPromoTransactionSlice({
      data: res?.data ?? {},
      loader: false
    }))
    
  } catch (error) {
    yield put(postAddAllPromoTransactionSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}
export function* getAllEditPromoTransactionSaga(action) {
  try {
    yield put(getEditAllPromoTransactionSlice({
      data: {},
      loader: true
    }))
    const res = yield getEditPromoTransactionHeaderAPI(action.payload);
    yield put(getEditAllPromoTransactionSlice());
    yield put(getEditAllPromoTransactionSlice({
      data: res?.data?? {},
      loader: false
    }))
    
  } catch (error) {
    yield put(getEditAllPromoTransactionSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}
export function* getAllPromoSaga(action) {

 
  try {
    yield put(getAllPromoSlice({
      data: {},
      loader: true
    }))
    yield localStorage.removeItem("featureHeader")
    const featureHeader = yield getFeatureHeaderAPI("promo");
    yield localStorage.setItem('featureHeader', JSON.stringify(featureHeader.data.responseData));


    // getAllPromoNames
    // yield localStorage.removeItem("featureHeader")
    if(!sessionStorage.getItem('promoNamesList')){
      const featureHeader = yield   getAllPromoNamesAPI();
      yield sessionStorage.setItem('promoNamesList', JSON.stringify(featureHeader.data.responseData));
    }
   
  
    const res = yield getAllPromoAPI(action.payload);
    yield put(getAllPromoSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllPromoSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadPromoCSVDataAPI(action.payload);
    yield put(promoCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        promoCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchPromoSaga() {
  yield takeEvery(GET_ALL_PROMO, getAllPromoSaga);
  yield takeEvery(UPLOAD_PROMO_CSV_DATA,uploadCSVSaga);
  yield takeEvery(GET_ALL_EDIT_PROMO_TRANSACTION, getAllEditPromoTransactionSaga);
  yield takeEvery(POST_ALL_PROMO_TRANSACTION, postAllPromoTransactionSaga);
}
