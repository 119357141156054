import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

import { forEach, isEmpty } from "lodash";
import Loader from "../../components/loader";
import { useTranslation } from "react-i18next";
import Pagination from "./pagination/";
import { setTableWidth } from "../../utils/setTableWidth";
import ProcessingLoader from "../processingLoader";
import { entitiesName } from "../../utils/Constants";
import { get } from "lodash";
// import { Toolbar } from "@mui/material";
// import TableToolbar from "./toolbar";
import { useDispatch, useSelector } from "react-redux";
import { SET_SELECTED_ROW_DATA } from "../../store/types";
const COLUMN_MIN = 4;
const ADJUST_PADDING = 4;
const TableGrid = (props) => {
  const { t } = useTranslation();
  const {
    dataRows,
    dataColumn,
    refresh,
    pageSize,
    totalElement,
    setPageIndex,
    pageIndex,
    setSortValue,
    skip,
    width,
    replaceColumnLevels,
    isLoading,
    componentName,
  } = props;
  console.log("datarows",dataRows)
  console.log("dataColumn",dataColumn)

  const initialDataState = {
    skip: skip ?? 0,
  };
  const [dataState, setDataState] = useState(initialDataState);
  const [sortKey, setSortKey] = useState("");
  const [sortDir, setSortDir] = useState(false);
  const grid = React.useRef(null);
  if (entitiesName.includes(componentName)) {
    localStorage.setItem(`${componentName}SortValue`, sortKey);
    for (let i = 0; i < entitiesName.length; i++) {
      if (entitiesName[i] != componentName) {
        localStorage.removeItem(`${entitiesName[i]}SortValue`);
      }
    }
  }

  const user = JSON.parse(localStorage.getItem("user"));
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");

  const [selectAll, setSelectAll] = useState(false);

  React.useEffect(() => {
    if (dataColumn.length > 0) {
      grid.current = document.querySelector(".k-grid");
    }
  }, [dataColumn]);

  useEffect(() => {
    setDataState({ ...initialDataState });
    if (selectAll == true) {
      setSelectAll(!selectAll);
    }
    dispatch({ type: SET_SELECTED_ROW_DATA, payload: [] });
  }, [pageSize, totalElement]);


  const noOfPages = Math.ceil(totalElement / parseInt(pageSize));
  const start = (parseInt(pageIndex) - 1) * parseInt(pageSize);
  const end = start + parseInt(pageSize);
  const endCount = end > totalElement ? `- ${totalElement}` : `- ${end}`;

  useEffect(() => {
    setSortKey("");
  }, [refresh]);

  const getTitle = (val) => {
    if (replaceColumnLevels && replaceColumnLevels.length > 0) {
      const findIn =
        replaceColumnLevels &&
        replaceColumnLevels?.findIndex((v) => v?.replaceWith === val);
      if (
        findIn >= 0 &&
        Object.keys(replaceColumnLevels[findIn] || {})?.length > 0
      ) {
        return replaceColumnLevels[findIn]?.newLabel || val;
      }
    } else {
      const words = val.split("_");
      return words
        .map((word) => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(" ");
    }
    if(val === "channel") return "Channel Name";
    return val;
  };

  const handleHeaderCheckboxChange = () => {
    const updatedDataRows = dataRows.map((item) => ({
      ...item,
      selected: !selectAll,
    }));

    setSelectAll(!selectAll);
    if (selectAll === false) {
      dataRows.map((item) => {
        item.selected = false;
      });
    }
    setDataState({
      ...dataState,
      data: process(updatedDataRows, dataState),
    });

    const newSelectedRowIds = updatedDataRows
      .filter((item) => item.selected)
      .map((item) => ({
        id: item.id,
        tenantId: tenantId,
        buId: buId,
      }));

    // setSelectedRowIds(newSelectedRowIds);
    dispatch({ type: SET_SELECTED_ROW_DATA, payload: newSelectedRowIds });
  };
  const { selectedRowIds } = useSelector((store) => store.commonReducer);
  const dispatch = useDispatch();

  const handleRowCheckboxChange = (dataItem) => {
    console.log("newSelectedRowIds1",dataItem)
    console.log("budgetdatarows1",dataRows)
    
    const updatedDataRows = [...dataRows];
    // const clickedIndex = updatedDataRows.findIndex((item) => item === dataItem);
    const clickedIndex = updatedDataRows.findIndex((item) => item.id === dataItem.id);

    // const clickedIndex=dataRows.findIndex(obj => obj.id === dataItem.id);
    console.log("clickedIndex",clickedIndex);
    if (clickedIndex !== -1) {
      updatedDataRows[clickedIndex].selected =!dataItem.selected
        // !updatedDataRows[clickedIndex].selected;
    }
    console.log("budgetdatarows2",updatedDataRows)


    setDataState({
      ...dataState,
      data: process(updatedDataRows, dataState),
    });

    const newSelectedRowIds = updatedDataRows
      .filter((item) => item.selected)
      .map((item) => ({
        id: item.id,
        tenantId: tenantId,
        buId: buId,
      }));
      console.log("newSelectedRowIds2",newSelectedRowIds)
    dispatch({ type: SET_SELECTED_ROW_DATA, payload: newSelectedRowIds });
  };
  console.log("selectRowIds", selectedRowIds);

  const _renderSortIcons = (obj) => {
    return (
      <>
        {sortKey === obj ? (
          sortDir || obj !== sortKey ? (
            <i className="fa fa-arrow-down" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-arrow-up" aria-hidden="true"></i>
          )
        ) : (
          <>
            {" "}
            <i className="fa fa-sort" aria-hidden="true"></i>
          </>
        )}{" "}
      </>
    );
  };
  const promoMasterConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities.promotions
        ?.promoHierarchy
    : {};
  return (
    <div className="tablegrid">
      {dataColumn.length > 0 && !isEmpty(dataRows) ? (
        <>
          <Grid
            pageable={false}
            sortable={false}
            resizable={true}
            fixedScroll={true}
            data={!isEmpty(dataRows) ? process(dataRows, dataState) : null}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.dataState);
            }}
            className={"table-style"}
          >
            {/* {(componentName === "feature" || componentName ==="budget") && ( */}
            {(componentName === "feature" || componentName === "promo" || componentName ==="budget" || componentName ==="promomaster") && (

              <Column
                title="New Column"
                field="newColumn"
                width="45px"
                key="new-column"
                headerCell={({}) => {
                  return (
                    <div>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleHeaderCheckboxChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  );
                }}
                cell={({ dataItem }) => {
                  return (
                    <td>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={dataItem.selected}
                          onChange={() => handleRowCheckboxChange(dataItem)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                  );
                }}
              />
            )}
            {dataColumn &&
              dataColumn?.map((item, idx) => {
                if (dataColumn.length > 6) {
                  return (
                    <Column
                      // title={t(`${item}`)}
                      title={ item.includes("promoHierarchyLevel") &&
                      promoMasterConfiguration
                    ? promoMasterConfiguration[item]: t(`${getTitle(item)}`)}
                      field={item}
                      width={setTableWidth(120, grid, dataColumn)}
                      key={idx}
                      headerClassName={`column-sorting-icon ${
                        item ==
                        localStorage.getItem(`${componentName}SortValue`)
                          ? "sort_column"
                          : ""
                      }`}
                      headerCell={(props) => {
                        return (
                          <span
                            onClick={() => {
                              setSortDir(!sortDir || item !== sortKey);
                              setSortKey(item);
                              setSortValue(
                                `${item} ${
                                  !sortDir || item !== sortKey ? "ASC" : "DESC"
                                }`
                              );
                            }}
                            className="th-flex"
                          >
                            {props.title} {_renderSortIcons(item)}
                          </span>
                        );
                      }}
                    />
                  );
                } else {
                  return (
                    <Column
                      // title={t(`${item}`)}
                      title={ item.includes("promoHierarchyLevel") &&
                      promoMasterConfiguration
                    ? promoMasterConfiguration[item]: t(`${getTitle(item)}`)}
                      field={item}
                      width="auto"
                      key={idx}
                      headerClassName={`column-sorting-icon ${
                        item ==
                        localStorage.getItem(`${componentName}SortValue`)
                          ? "sort_column"
                          : ""
                      }`}
                      headerCell={(props) => {
                        return (
                          <span
                            onClick={() => {
                              setSortDir(!sortDir || item !== sortKey);
                              setSortKey(item);
                              setSortValue(
                                `${item} ${
                                  !sortDir || item !== sortKey ? "ASC" : "DESC"
                                }`
                              );
                            }}
                            className="th-flex"
                          >
                            {props.title} {_renderSortIcons(item)}
                          </span>
                        );
                      }}
                    />
                  );
                }
              })}
          </Grid>
          <Pagination
            endCount={endCount}
            page={parseInt(pageIndex)}
            onChange={(e, value) => setPageIndex(value)}
            count={noOfPages}
            showFirstButton={true}
            showLastButton={true}
            totalElement={totalElement}
            start={start}
          />
        </>
      ) : (
        <>
          <div className="text-center">No records found</div>
        </>
      )}
    </div>
  );
};

export default TableGrid;
